var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 d-flex justify-center",attrs:{"fluid":""}},[_c('v-container',{staticClass:"ma-0 pa-4"},[_c('p',[_vm._v(" Bis wann sollen Eltern die Möglichkeit haben eine Meldung zu senden? ")]),_c('p',{staticStyle:{"font-size":"80%"}},[_vm._v(" Bis zu dieser Uhrzeit sollten keine Kinder auf \"entlassen\" umgestellt werden. ")]),_c('v-row',[_c('v-col',[_c('TimePicker',{attrs:{"label":"Meldung spätestens bis","icon":"mdi-clock-time-four"},model:{value:(_vm.anwesenheitMeldungSpaetestensBis),callback:function ($$v) {_vm.anwesenheitMeldungSpaetestensBis=$$v},expression:"anwesenheitMeldungSpaetestensBis"}})],1)],1),_c('p',[_vm._v(" Für welche Entlasszeiten können Meldungen gemacht werden? ")]),_c('v-row',[_c('v-col',[_c('v-row',[_vm._l((_vm.moeglicheEntlasszeiten),function(zeit,idx){return _c('v-col',{key:idx,attrs:{"cols":"6"}},[_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('TimePicker',{attrs:{"label":"Entlasszeit","icon":""},model:{value:(
                    zeit.zeit
                  ),callback:function ($$v) {_vm.$set(zeit, "zeit", $$v)},expression:"\n                    zeit.zeit\n                  "}})],1),_c('v-col',{attrs:{"cols":"4"}},[_c('v-btn',{attrs:{"x-small":"","color":"primary"},on:{"click":function($event){return _vm.moeglicheEntlasszeiten.splice(idx, 1);}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-minus-box")])],1)],1)],1)],1)}),_c('v-col',{attrs:{"cols":"6","align":"center"}},[_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.moeglicheEntlasszeiten.push({
                zeit: '00:00',
              });}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_vm._v(" Entlasszeit ")],1)],1)],2)],1)],1),_c('v-row',[_c('v-col',[_c('v-textarea',{attrs:{"auto-grow":"","label":"Info","hint":""},model:{value:(_vm.text_alertVorMeldungsformular),callback:function ($$v) {_vm.text_alertVorMeldungsformular=$$v},expression:"text_alertVorMeldungsformular"}})],1)],1),_c('v-row',[_c('v-col',[_c('p',[_vm._v(" Sollen Eltern die Möglichkeit haben, Dokumente (z.B. ein Attest o.Ä.) zu einer Meldung hochzuladen? ")]),_c('v-checkbox',{attrs:{"label":"Erlaube Dateiupload"},model:{value:(_vm.meldung_erlaubeDateiupload),callback:function ($$v) {_vm.meldung_erlaubeDateiupload=$$v},expression:"meldung_erlaubeDateiupload"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"align":"center"}},[_c('v-btn',{staticClass:"ml-2",attrs:{"small":"","color":"success","disabled":!_vm.xChanged.moeglicheEntlasszeiten
            && !_vm.xChanged.anwesenheitMeldungSpaetestensBis
            && !_vm.xChanged.meldung_erlaubeDateiupload
            && !_vm.xChanged.text_alertVorMeldungsformular},on:{"click":function($event){return _vm.$emit('saveSettings', {
            func: 'saveSettings',
            anwesenheitMeldungSpaetestensBis: _vm.anwesenheitMeldungSpaetestensBis,
            moeglicheEntlasszeiten: _vm.moeglicheEntlasszeiten,
            meldung_erlaubeDateiupload: _vm.meldung_erlaubeDateiupload,
            text_alertVorMeldungsformular: _vm.text_alertVorMeldungsformular,
           })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-content-save")]),_vm._v(" Änderungen Speichern ")],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }