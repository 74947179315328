<template>
    <div>
        <v-select
        :dense="dense"
        v-model="gruppen"
        :items="useGruppen"
        attach chips multiple hide-selected
        :label="label"
        :disabled="disabled"
        >
            <template v-slot:selection="{ attrs, item, parent, selected }">
                <v-chip
                v-bind="attrs"
                :input-value="selected"
                label
                small
                :class="{ 'disabled': disabled }"
                >
                <v-icon small left>
                {{
                    $store.state.main.save.nutzergruppen
                    .filter((o) => o.id == item)[0].icon
                }}
                </v-icon>
                <span class="pr-2">
                    {{
                        $store.state.main.save.nutzergruppen
                        .filter((o) => o.id == item)[0].name
                    }}
                </span>
                <v-icon
                    small
                    @click.native.stop
                    @click="parent.selectItem(item)"
                >
                    $delete
                </v-icon>
                </v-chip>
            </template>
            <template v-slot:item="{ item }">
                <v-icon left>
                {{
                    $store.state.main.save.nutzergruppen
                    .filter((o) => o.id == item)[0].icon
                }}
                </v-icon>
                {{
                    $store.state.main.save.nutzergruppen
                    .filter((o) => o.id == item)[0].name
                }}
            </template>
            <template v-slot:no-data>
                <v-list-item>
                <v-list-item-content>
                    <v-list-item-title>
                    Keine weiteren Gruppen.
                    </v-list-item-title>
                </v-list-item-content>
                </v-list-item>
            </template>
        </v-select>
    </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    value: {
      type: Array,
      default() { return {}; },
    },
    useGruppen: {
      type: Array,
      default() { return this.$store.state.main.save.nutzergruppen.map((a) => a.id); },
    },
    label: {
      tape: String,
      default: 'Nutzergruppen',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    gruppen: [],
  }),
  watch: {
    value: {
      handler(neu) {
        this.gruppen = neu;
      },
      deep: true,
    },
    gruppen: {
      handler(neu) {
        this.$emit('input', neu);
        this.$emit('change');
      },
      deep: true,
    },
  },
  methods: {
  },
  created() {
    this.gruppen = this.value;
  },
};
</script>
