<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-4">
      <p>
        Bis wann sollen Eltern die Möglichkeit haben eine Meldung zu senden?
      </p>
      <p style="font-size:80%;">
        Bis zu dieser Uhrzeit sollten keine Kinder auf "entlassen" umgestellt werden.
      </p>
      <v-row>
        <v-col>
          <TimePicker
            label="Meldung spätestens bis"
            icon="mdi-clock-time-four"
            v-model="anwesenheitMeldungSpaetestensBis"
          />
        </v-col>
      </v-row>
      <p>
        Für welche Entlasszeiten können Meldungen gemacht werden?
      </p>
      <v-row>
        <v-col>
          <v-row>
            <v-col
              cols="6"
              v-for="(zeit, idx) in moeglicheEntlasszeiten"
              :key="idx"
            >
              <v-row align="center">
                <v-col>
                  <TimePicker
                    label="Entlasszeit"
                    icon=""
                    v-model="
                      zeit.zeit
                    "
                  />
                </v-col>
                <v-col cols="4">
                  <v-btn
                    x-small
                    color="primary"
                    @click="moeglicheEntlasszeiten.splice(idx, 1);"
                  >
                    <v-icon small>mdi-minus-box</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
            <v-col cols="6" align="center">
              <v-btn
                small
                color="primary"
                @click="moeglicheEntlasszeiten.push({
                  zeit: '00:00',
                });"
              >
                <v-icon left>mdi-plus-box</v-icon>
                Entlasszeit
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-textarea
            auto-grow
            label="Info"
            v-model="text_alertVorMeldungsformular"
            hint=""
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <p>
            Sollen Eltern die Möglichkeit haben,
            Dokumente (z.B. ein Attest o.Ä.)
            zu einer Meldung hochzuladen?
          </p>
          <v-checkbox
            v-model="meldung_erlaubeDateiupload"
            label="Erlaube Dateiupload"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            small
            color="success"
            class="ml-2"
            :disabled="
              !xChanged.moeglicheEntlasszeiten
              && !xChanged.anwesenheitMeldungSpaetestensBis
              && !xChanged.meldung_erlaubeDateiupload
              && !xChanged.text_alertVorMeldungsformular
            "
            @click="$emit('saveSettings', {
              func: 'saveSettings',
              anwesenheitMeldungSpaetestensBis,
              moeglicheEntlasszeiten,
              meldung_erlaubeDateiupload,
              text_alertVorMeldungsformular,
             })"
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import TimePicker from '@/components/Inputs/TimePicker.vue';

export default {
  components: {
    TimePicker,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    xLoaded: {
      meldung_erlaubeDateiupload: false,
      text_alertVorMeldungsformular: '',
    },
    xChanged: {
      moeglicheEntlasszeiten: false,
      anwesenheitMeldungSpaetestensBis: false,
      meldung_erlaubeDateiupload: false,
      text_alertVorMeldungsformular: false,
    },
    anwesenheitMeldungSpaetestensBis: null,
    moeglicheEntlasszeiten: [],
    meldung_erlaubeDateiupload: false,
    text_alertVorMeldungsformular: '',
  }),
  computed: {
  },
  watch: {
    prop_settings() {
      this.getData();
    },
    moeglicheEntlasszeiten: {
      handler(neu) {
        if (JSON.stringify(neu) !== this.xLoaded.moeglicheEntlasszeiten) {
          this.xChanged.moeglicheEntlasszeiten = true;
        } else {
          this.xChanged.moeglicheEntlasszeiten = false;
        }
      },
      deep: true,
    },
    anwesenheitMeldungSpaetestensBis(neu) {
      if (this.xLoaded.anwesenheitMeldungSpaetestensBis !== JSON.stringify(neu)) {
        this.xChanged.anwesenheitMeldungSpaetestensBis = true;
      } else {
        this.xChanged.anwesenheitMeldungSpaetestensBis = false;
      }
    },
    meldung_erlaubeDateiupload(neu) {
      if (neu !== this.xLoaded.meldung_erlaubeDateiupload) {
        this.xChanged.meldung_erlaubeDateiupload = true;
      } else {
        this.xChanged.meldung_erlaubeDateiupload = false;
      }
    },
    text_alertVorMeldungsformular(neu) {
      if (neu !== this.xLoaded.text_alertVorMeldungsformular) {
        this.xChanged.text_alertVorMeldungsformular = true;
      } else {
        this.xChanged.text_alertVorMeldungsformular = false;
      }
    },
  },
  methods: {
    getData() {
      if (this.prop_settings.anwesenheitMeldungSpaetestensBis) {
        this.anwesenheitMeldungSpaetestensBis = this.prop_settings.anwesenheitMeldungSpaetestensBis;
        this.xLoaded.anwesenheitMeldungSpaetestensBis = JSON.stringify(
          this.anwesenheitMeldungSpaetestensBis,
        );
      }
      if (this.prop_settings.moeglicheEntlasszeiten) {
        const help = [];
        this.prop_settings.moeglicheEntlasszeiten.forEach((z) => {
          help.push({
            zeit: z,
          });
        });
        if (JSON.stringify(help) !== this.xLoaded.moeglicheEntlasszeiten) {
          this.moeglicheEntlasszeiten = help;
          this.xLoaded.moeglicheEntlasszeiten = JSON.stringify(this.moeglicheEntlasszeiten);
          this.xChanged.moeglicheEntlasszeiten = false;
        }
      }
      // if (this.prop_settings.meldung_erlaubeDateiupload) {
      this.meldung_erlaubeDateiupload = this.prop_settings.meldung_erlaubeDateiupload;
      this.xLoaded.meldung_erlaubeDateiupload = this.meldung_erlaubeDateiupload;
      // }
      if (this.meldung_erlaubeDateiupload === this.xLoaded.meldung_erlaubeDateiupload) {
        this.xChanged.meldung_erlaubeDateiupload = false;
      }
      if (this.prop_settings.text_alertVorMeldungsformular) {
        this.text_alertVorMeldungsformular = this.prop_settings.text_alertVorMeldungsformular;
        this.xLoaded.text_alertVorMeldungsformular = this.text_alertVorMeldungsformular;
      }
      if (this.text_alertVorMeldungsformular === this.xLoaded.text_alertVorMeldungsformular) {
        this.xChanged.text_alertVorMeldungsformular = false;
      }
    },
  },
  created() {
    this.getData();
  },
};
</script>
