<template>
  <div>
    <v-dialog
      v-model="dialog"
      width="972"
      :fullscreen="$vuetify.breakpoint.xs"
      scrollable
    >
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          color="primary"
          small
          v-bind="attrs"
          v-on="on"
        >
          Rückmeldungen
        </v-btn>
      </template>

      <v-card>
        <v-toolbar
          dark
          color="primary"
        >
          <v-btn
            icon
            dark
            @click="dialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
          <v-toolbar-title>{{ meldung.titel }}</v-toolbar-title>
          <v-spacer></v-spacer>
          <v-toolbar-items>
          </v-toolbar-items>
        </v-toolbar>
        <v-card-text style="height:100%;">
          <p class="pt-2">
            Rückmedungen:
          </p>
          <v-data-table
            dense
            :headers="headers"
            :items="items"
            item-key="name"
            class="elevation-1"
            :items-per-page="20"
            :header-props="headerProps"
            :footer-props="footerProps"
            no-data-text="Noch keine Rückmeldungen empfangen"
            no-results-text="Keine Daten gefunden"
            loading-text="Lade Daten... Bitte warten"
          >
          <template v-slot:item.kannAendern="{ item }">
            <v-icon
                v-if="!item.kannAendern"
                color="success"
              >
                mdi-check-all
              </v-icon>
              <v-icon
                v-if="item.kannAendern"
                color="error"
              >
                mdi-refresh
              </v-icon>
              <v-menu offset-y>
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon small>mdi-menu</v-icon>
                  </v-btn>
                </template>
                <v-list>
                  <v-list-item link>
                    <v-list-item-title @click="refresh('delete', { userId: item.id });">
                      Rückmeldung Löschen
                    </v-list-item-title>
                  </v-list-item>
                  <v-list-item link>
                    <v-list-item-title @click="refresh('allowRefill', { userId: item.id });">
                      Erlaube ändern
                    </v-list-item-title>
                  </v-list-item>
                </v-list>
              </v-menu>
            </template>
            <template v-slot:item.date="{ item }">
              <span style="font-size:80%;">
                {{ item.date }}
              </span>
            </template>
            <template v-slot:item.name="{ item }">
              {{ item.name }}
            </template>
            <template v-slot:footer.prepend>
              <v-btn
                color="primary"
                dark small
                class="ma-2"
                @click="refresh('refresh', {});"
                :loading="loading.refresh"
              >
                <v-icon>mdi-refresh</v-icon>
              </v-btn>
              <v-dialog
                v-model="dialogDelAll"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    color="primary"
                    dark small
                    class="ma-2"
                    v-bind="attrs"
                    v-on="on"
                    :loading="loading.deleteAll || loading.allowRefillAll"
                  >
                    Alle zurücksetzen
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Sicher?
                  </v-card-title>

                  <v-card-text class="pt-8">
                    <p>
                      Alle empfangenen Rückmeldungen zurücksetzen
                      sodass die Antworten neu eingegeben werden können?<br/>
                      Oder sollen alle Rückmeldungen unwiederruflich gelöscht
                      werden?
                    </p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      text small
                      @click="dialogDelAll = false"
                    >
                      Nichts tun
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="warning"
                      text small
                      @click="dialogDelAll = false;refresh('allowRefillAll', {});"
                    >
                      Zurücksetzen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      text small
                      @click="dialogDelAll = false;refresh('deleteAll', {});"
                    >
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="primary"
            text
            @click="dialog = false"
          >
            OK
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>

export default {
  components: {
  },
  props: {
    prop_meldung: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    meldung: {},
    dialog: false,
    dialogDelAll: false,
    items: [],
    headers: [],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 40, -1],
      itemsPerPageAllText: 'Alle',
    },
    loading: {
      refresh: false,
      deleteAll: false,
      allowRefillAll: false,
    },
  }),
  computed: {
    rueckmeldungen() {
      return this.meldung.rueckmeldung.users.filter((o) => {
        if (
          Object.keys(o.nutzerparameterwerte).length > 1
          || Object.keys(o.nutzerparameterwerteSelectoptionIDs).length > 1
        ) {
          return o;
        }
        return null;
      });
    },
  },
  watch: {
    dialog() {
      this.refresh('refresh', {});
    },
  },
  methods: {
    buildTable() {
      this.items = [];
      this.headers = [
        { text: 'Status', value: 'kannAendern' },
        { text: 'Zeit', value: 'date' },
        { text: 'Name', value: 'name' },
      ];
      this.meldung.rueckmeldung.formularfelder.forEach((form) => {
        this.headers.push({
          id: form.id,
          text: form.name,
          value: `v${form.id}`,
          typ: form.typ,
          selectoptions: form.selectoptions,
        });
      });
      this.meldung.rueckmeldung.users.forEach((user) => {
        let show = false;
        const item = {
          id: user.id,
          name: user.ident,
          date: user.date,
          kannAendern: user.kannAendern,
        };
        Object.keys(user.nutzerparameterwerte).forEach((val) => {
          if (val !== 'x') {
            show = true;
            item[`v${val}`] = user.nutzerparameterwerte[val];
          }
        });
        Object.keys(user.nutzerparameterwerteSelectoptionIDs).forEach((val) => {
          if (
            val !== 'x'
            && this.headers.filter((o) => o.id === val).length > 0
          ) {
            item[val] = 'okods';
            if (
              this.headers.filter((o) => o.id === val)[0].typ === '11'
            ) {
              show = true;
              if (
                this.$store.state.main.save.user.f200.kinder
                  .filter((o) => o.id === user.nutzerparameterwerteSelectoptionIDs[val]).length > 0
              ) {
                item[`v${val}`] = this.$store.state.main.save.user.f200.kinder
                  .filter((o) => o.id === user.nutzerparameterwerteSelectoptionIDs[val])[0].ident;
              } else if (user.nutzerparameterwerte[val]) {
                item[`v${val}`] = user.nutzerparameterwerte[val];
              } else {
                item[`v${val}`] = '-';
              }
            } else if (
              this.headers.filter((o) => o.id === val)[0].typ === '12'
              && this.$store.state.main.save.user.f200.kinder.length > 0
            ) {
              const neuVal = [];
              user.nutzerparameterwerteSelectoptionIDs[val].forEach((id) => {
                if (
                  this.$store.state.main.save.user.f200.kinder
                    .filter((o) => o.id === id).length > 0
                ) {
                  neuVal.push(this.$store.state.main.save.user.f200.kinder
                    .filter((o) => o.id === id)[0].ident);
                } else if (user.nutzerparameterwerte[val]) {
                  item[`v${val}`] = user.nutzerparameterwerte[val];
                } else {
                  item[`v${val}`] = '-';
                }
              });
              show = true;
              item[`v${val}`] = neuVal.join(', ');
            } else if (
              this.headers.filter((o) => o.id === val)[0].typ === '9'
            ) {
              show = true;
              if (
                this.headers
                  .filter((o) => o.id === val)[0].selectoptions
                  .filter((o) => o.id === user.nutzerparameterwerteSelectoptionIDs[val])
                  .length > 0
              ) {
                item[`v${val}`] = this.headers
                  .filter((o) => o.id === val)[0].selectoptions
                  .filter((o) => o.id === user.nutzerparameterwerteSelectoptionIDs[val])[0].wert;
              } else {
                item[`v${val}`] = '';
              }
            } else if (
              this.headers.filter((o) => o.id === val)[0].typ === '10'
            ) {
              const neuVal = [];
              user.nutzerparameterwerteSelectoptionIDs[val].forEach((id) => {
                if (
                  this.headers
                    .filter((o) => o.id === val)[0].selectoptions
                    .filter((o) => o.id === id).length > 0
                ) {
                  neuVal.push(this.headers
                    .filter((o) => o.id === val)[0].selectoptions
                    .filter((o) => o.id === id)[0].wert);
                }
              });
              show = true;
              item[`v${val}`] = neuVal.join(', ');
            } else {
              show = true;
              item[`v${val}`] = user.nutzerparameterwerte[val];
            }
          }
        });
        if (show) {
          this.items.push(item);
        }
      });
    },
    async refresh(type, data) {
      this.loading[type] = true;
      // this.$store.commit('main/switchIsSaving', true);
      const senddata = {
        code: this.$store.state.login.session.code,
        dataToSave: {
          func: 'settingsRueckmeldungenuebersicht',
          data: {
            meldungId: this.meldung.id,
            type,
            data,
          },
        },
      };
      await fetch(`${process.env.VUE_APP_API_HOST}/000/000/?func=w1`, {
        method: 'POST',
        body: JSON.stringify(senddata),
      })
        .then((response) => response.json())
        .then((res) => {
          if (res.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.meldung.rueckmeldung.users = res.users;
            this.buildTable();
            this.$emit('refresh', res.users);
          }
        })
        .catch((error) => {
          // Wenn bei der Verbindung ein Fehler aufgetreten ist:
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
          console.log(error);
        })
        .then(() => {
          // this.$store.commit('main/switchIsSaving', false);
          this.loading[type] = false;
        });
    },
  },
  created() {
    this.meldung = JSON.parse(JSON.stringify(this.prop_meldung));
    this.refresh('refresh', {});
  },
};
</script>
