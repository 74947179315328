<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container
      v-if="prop_kinder.length <= 0"
      class="ma-0 pa-8"
    >
      <h2>Fehler</h2>
      <p>
        Ihnen sind keine Kinder zugeordnet. Bitte wenden Sie sich an Ihre
        Einrichtung damit ihr Konto einem Kind zugeordnet wird.
      </p>
    </v-container>
    <v-container
      v-if="prop_kinder.length > 0"
      class="ma-0 pa-0"
    >
      <v-tabs
        fixed-tabs
        v-model="tabs_meldenHistorie"
        class="d-md-none mb-6"
      >
        <v-tab>Melden</v-tab>
        <v-tab>Historie</v-tab>
      </v-tabs>
      <v-row>
        <v-col
          v-if="
            tabs_meldenHistorie === 0 ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Melden
          </v-container>
          <v-row>
            <v-col class="pa-8">
              <v-form v-model="form.valid">
                <p>
                  Bitte senden Sie uns die folgende Mitteilung für Heute
                  <b>bis spätestens {{ prop_settings.anwesenheitMeldungSpaetestensBis }}
                  Uhr</b> zu:
                </p>
                <v-alert
                  v-if="this.prop_settings.text_alertVorMeldungsformular !== ''"
                  border="bottom"
                  colored-border
                  type="warning"
                  elevation="2"
                  style="text-align:justify;"
                >
                  <p
                    v-html="this.prop_settings.text_alertVorMeldungsformular_nl2br"
                    class="pa-0 ma-0"
                  ></p>
                </v-alert>
                <v-divider class="my-8"></v-divider>
                <p>
                  Hiermit bestätige ich, dass mein Kind
                </p>
                <v-select
                  multiple
                  label="Kind wählen"
                  prepend-icon="mdi-human-child"
                  :items="prop_kinder"
                  item-text="nameVollst"
                  item-value="id"
                  v-model="form.kind"
                />
                <v-alert
                  v-if="errorTageZuWeitInZukunft"
                  border="bottom"
                  colored-border
                  type="error"
                  elevation="2"
                  style="text-align:justify;"
                >
                  <p
                    class="pa-0 ma-0"
                  >
                    Es können nur Meldungen gesendet werden,
                    die maximal 7 Tage in der Zukunft und
                    nicht in der Vergangenheit liegen.
                    Bitte überprüfen Sie Ihre Angaben.
                  </p>
                </v-alert>
                <DatePicker
                  class="mx-6"
                  label="vom"
                  v-model="tag"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                  ]"
                />
                <DatePicker
                  class="mx-6"
                  label="bis zum"
                  v-model="tag_bis"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                  ]"
                />
                <v-select
                  label="Anwesenheit wählen"
                  :items="anwesenheiten"
                  item-text="text"
                  item-value="id"
                  v-model="form.anwesenheit"
                />
                <v-text-field
                  label="Kommentar"
                  prepend-icon=""
                  v-model="form.kommentar"
                  :rules="[
                    $store.state.main.inputValidationRules.nameMore,
                  ]"
                  :hint="`${form.kommentar.length}/40 Zeichen`"
                  maxlength="40"
                  :placeholder="form.kommentarPlaceholder"
                ></v-text-field>
              </v-form>
            </v-col>
          </v-row>
          <v-row
            v-if="prop_settings.meldung_erlaubeDateiupload"
            class="my-0"
          >
            <v-col class="px-8 py-0">
              <div class="pb-2">
                Anhänge:
              </div>
              <p
                v-if="uploadedFiles.length <= 0"
                align="center"
              >
                <i>Keine Anhänge angefügt</i>
              </p>
              <ul style="list-style:none;">
                <li
                  v-for="(f, idx) in uploadedFiles"
                  :key="idx"
                >
                  <v-btn x-small color="error"
                    @click="uploadedFiles.splice(idx, 1);"
                    class="me-1"
                  ><v-icon small>mdi-delete</v-icon></v-btn>
                  {{ f.name }}
                </li>
              </ul>
              <div align="center">
                <v-dialog
                  v-model="dialog_addDokument"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      color="primary"
                      small
                      dark
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-plus-box</v-icon>
                    </v-btn>
                  </template>

                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Dokumente anhängen
                    </v-card-title>

                    <v-card-text>
                      <UploadBilderPDF
                        :apipath="
                          `200/210/upload.php?key=${this.$store.state.login.session.code}&fill=
                          &id_a=`
                        "
                        accept="application/pdf, image/*"
                        @neuDokumente="addDokumente($event);"
                      />
                    </v-card-text>

                    <v-divider></v-divider>

                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn
                        text
                        @click="dialog_addDokument = false"
                      >
                        schließen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </div>
            </v-col>
          </v-row>
          <v-row>
            <v-col align="center" class="mb-8">
              <v-btn
                :disabled="
                  !form.valid
                  || form.kind.length <= 0
                  || form.anwesenheit === ''
                  || errorTageZuWeitInZukunft
                "
                @click="meldungSenden();"
                :loading="loading_meldungSenden"
              >
                Mitteilung senden
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            tabs_meldenHistorie === 1 ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Historie
          </v-container>
          <v-select
            label="Kind wählen"
            prepend-icon="mdi-human-child"
            :items="prop_kinder"
            item-text="nameVollst"
            item-value="id"
            v-model="tabs_historieKind"
          />
          <v-row align="center">
            <v-col>
              <DatePicker
                class="mx-6"
                label="Filter nach Tag"
                v-model="historie_filterMeldungenTag"
              />
            </v-col>
            <v-col cols="3" align="center">
              <v-btn
                small
                @click="historie_filterMeldungenTag = ''"
              >
                x
              </v-btn>
            </v-col>
          </v-row>
          <v-row>
            <template>
              <v-col
                v-for="kind in prop_kinder.filter((o) => {
                  if (
                    o.id === tabs_historieKind
                  ) {
                    return o;
                  }
                })"
                :key="kind.id"
                cols="12"
              >
                <v-card
                  elevation="2"
                  class="ma-4"
                >
                  <v-card-title>
                    {{ kind.nameVollst }}
                  </v-card-title>
                  <v-card-text>
                    <p v-if="kind.ausnahmen.length <= 0">
                      Es wurden noch keine Meldung gesendet
                    </p>
                    <p v-else-if="
                      historie_filterMeldungenTagDE != ''
                      && !kind.ausnahmen[historie_filterMeldungenTagDE]
                    ">
                      Es wurde keine Meldung für den
                      {{ historie_filterMeldungenTagDE }} gesendet.
                    </p>
                    <div
                      v-for="(ausnTag, tag) in kind.ausnahmen"
                      :key="tag"
                    >
                      <template
                        v-if="
                          tag === historie_filterMeldungenTagDE
                          || (
                            historie_filterMeldungenTagDE === ''
                            && Math.ceil((new Date(ausnTag[0].tagValue).getTime()
                            - new Date().getTime()) / 1000 / 60 / 60 / 24) >= 0
                          )
                        "
                      >
                        <h2>{{ tag }}</h2>
                        <div
                          v-for="(ausn, idx) in ausnTag"
                          :key="idx"
                        >
                          <v-divider /><br>
                          <!-- <p
                            v-if="(idx + 1 < ausnTag.length)"
                            style="text-decoration:line-through;"
                          >
                            <span style="font-size:80%;">
                              {{ idx + 1 }}/{{ ausnTag.length }}
                              [Eingegangen am {{ ausn.elternErstellt_tag }} um
                              {{ ausn.elternErstellt_zeit }}:]
                            </span>
                            <br>
                            <span>
                              Hiermit bestätige ich, dass mein Kind {{ kind.nameVollst }} am
                              {{ tag }} <b>{{ ausn.anwesenheit_text }}</b>
                            </span>
                          </p> -->
                          <p
                            :class="{line_through: (idx + 1 < ausnTag.length)}"
                          >
                            <span style="font-size:80%;">
                              {{ idx + 1 }}/{{ ausnTag.length }}
                              [Eingegangen am {{ ausn.elternErstellt_tag }} um
                              {{ ausn.elternErstellt_zeit }}:]
                            </span>
                            <br>
                            <span>
                              Hiermit bestätige ich, dass mein Kind {{ kind.nameVollst }} am
                              {{ tag }} <b>{{ ausn.anwesenheit_text }}</b>
                            </span>
                          </p>
                          <p
                            v-if="ausn.kommentar.split(' ').join('') !== ''"
                            :class="{line_through: (idx + 1 < ausnTag.length)}"
                          >
                            "<i>{{ ausn.kommentar }}</i>"
                          </p>
                          <div
                            v-if="ausn.anhaenge.length > 0"
                          >
                            <span
                              v-if="ausn.anhaenge.length === 1"
                            >
                              Anhang:
                            </span>
                            <span
                              v-if="ausn.anhaenge.length > 1"
                            >
                              Anhänge:
                            </span>
                            <v-btn
                              x-small
                              v-for="(anh, idx) in ausn.anhaenge"
                              :key="idx"
                              @click="tryDownloadDokument(anh.pfad, anh.name);"
                              max-width="100%"
                              style="overflow:hidden;"
                            >
                              {{ anh.name }}
                            </v-btn>
                          </div>
                        </div>
                      </template>
                    </div>
                  </v-card-text>
                </v-card>
              </v-col>
            </template>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import UploadBilderPDF from '@/components/Inputs/UploadBilderPDF.vue';

export default {
  components: {
    DatePicker,
    UploadBilderPDF,
  },
  props: {
    prop_xtime: {
      type: String,
      default: '',
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_kinder: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    dialog_addDokument: false,
    uploadedFiles: [],
    tabs_meldenHistorie: null,
    tabs_historieKind: null,
    historie_filterMeldungenTag: '',
    tag: '',
    tag_bis: '',
    errorTageZuWeitInZukunft: false,
    anwesenheiten: [],
    form: {
      valid: true,
      kind: 0,
      anwesenheit: '',
      anwesenheitText: '',
      kommentar: '',
      kommentarPlaceholder: '',
    },
    loading_meldungSenden: false,
    puenktlichGesendet: true,
  }),
  computed: {
    historie_filterMeldungenTagDE() {
      if (this.historie_filterMeldungenTag === '') {
        return '';
      }
      const datum = this.historie_filterMeldungenTag.split('-');
      return `${datum[2]}.${datum[1]}.${datum[0]}`;
    },
    anzeigeTag() {
      const tag = new Date(this.tag);
      const dd = String(tag.getDate()).padStart(2, '0');
      const mm = String(tag.getMonth() + 1).padStart(2, '0');
      const yyyy = tag.getFullYear();
      return `${dd}.${mm}.${yyyy}`;
    },
  },
  watch: {
    tag(neu) {
      this.checkTageZuWeitInZukunft();
      if (neu > this.tag_bis) {
        this.tag_bis = neu;
      }
    },
    tag_bis(neu) {
      this.checkTageZuWeitInZukunft();
      if (neu < this.tag) {
        this.tag = neu;
      }
    },
    prop_xtime() {
      if (this.loading_meldungSenden) {
        // Speichern war erfolgreich
        if (this.puenktlichGesendet) {
          this.$store.commit('main/alert', {
            typ: 'success',
            text: 'Die Meldung ist eingegangen. Vielen Dank.',
          }, { root: true });
        } else {
          const text = this.prop_settings.text_meldungVerpaetet
            .split('[[ZEIT_SPAETESTENS]]')
            .join(this.prop_settings.anwesenheitMeldungSpaetestensBis);
          this.puenktlichGesendet = true;
          this.$store.commit('main/alert', {
            typ: 'error',
            text,
          }, { root: true });
        }
        this.loading_meldungSenden = false;
        this.resetForm();
      }
    },
    form: {
      handler(neu) {
        if (neu.anwesenheit === 'k') {
          neu.kommentarPlaceholder = 'z.B. Erkältet';
        } else if (neu.anwesenheit === 'nds') {
          neu.kommentarPlaceholder = 'z.B. Arzttermin';
        } else {
          neu.kommentarPlaceholder = 'z.B. wird vom Opa abgeholt';
        }
        if (neu.anwesenheit !== '') {
          neu.anwesenheitText = this.anwesenheiten
            .filter((o) => o.id === neu.anwesenheit)[0].text;
        }
      },
      deep: true,
    },
  },
  methods: {
    checkTageZuWeitInZukunft() {
      const heute = new Date();
      const gesetzt1 = new Date(this.tag);
      const gesetzt2 = new Date(this.tag_bis);
      const diff1 = Math.ceil(
        (gesetzt1.getTime() / 1000 / 60 / 60 / 24)
        - (heute.getTime() / 1000 / 60 / 60 / 24),
      );
      // console.log(diff1);
      const diff2 = Math.ceil(
        (gesetzt2.getTime() / 1000 / 60 / 60 / 24)
        - (heute.getTime() / 1000 / 60 / 60 / 24),
      );
      if (diff1 > 7 || diff1 < 0 || diff2 > 7 || diff2 < 0) {
        this.errorTageZuWeitInZukunft = true;
      } else {
        this.errorTageZuWeitInZukunft = false;
      }
    },
    addDokumente(data) {
      // console.log(data);
      data.forEach((d) => {
        this.uploadedFiles.push(d);
      });
      this.dialog_addDokument = false;
    },
    resetForm() {
      const tag = new Date();
      const dd = String(tag.getDate()).padStart(2, '0');
      const mm = String(tag.getMonth() + 1).padStart(2, '0');
      const yyyy = tag.getFullYear();
      this.tag = `${yyyy}-${mm}-${dd}`;
      this.tag_bis = `${yyyy}-${mm}-${dd}`;
      this.form.anwesenheit = '';
      this.form.anwesenheitText = '';
      this.form.kommentar = '';
      this.form.kind = [];
      if (this.prop_kinder.length === 1) {
        this.form.kind.push(this.prop_kinder[0].id);
      }
      if (this.prop_kinder.length > 0) {
        this.tabs_historieKind = this.prop_kinder[0].id;
      }
      this.anwesenheiten = [
        { id: 'k', text: 'krank ist.' },
        { id: 'nds', text: 'nach der Schule nach Hause geht.' },
      ];
      if (this.prop_settings.moeglicheEntlasszeiten) {
        this.prop_settings.moeglicheEntlasszeiten.forEach((t) => {
          this.anwesenheiten.push({
            id: t,
            text: `um ${t} Uhr entlassen werden soll.`,
          });
        });
      }
    },
    meldungSenden() {
      const tag = new Date();
      const dd = String(tag.getDate()).padStart(2, '0');
      const mm = String(tag.getMonth() + 1).padStart(2, '0');
      const yyyy = tag.getFullYear();
      let spaet = `${yyyy}-${mm}-${dd} ${this.prop_settings.anwesenheitMeldungSpaetestensBis}`;
      spaet = new Date(spaet).getTime();
      const jetzt = new Date().getTime();
      if (this.tag < `${yyyy}-${mm}-${dd}`) {
        this.$store.commit('main/alert', {
          typ: 'error',
          text: `
            Der gewählte Tag liegt in der Vergangenheit. Bitte wählen Sie einen
            gültigen Tag in der Zukunft aus.
          `,
        }, { root: true });
      } else {
        if (this.tag === `${yyyy}-${mm}-${dd}` && jetzt - spaet > 0) {
          this.puenktlichGesendet = false;
        }
        this.loading_meldungSenden = true;
        this.$emit('saveSettings', {
          func: 'saveAusnahme',
          form: this.form,
          tag: this.tag,
          tag_bis: this.tag_bis,
          anhaenge: this.uploadedFiles,
        });
      }
    },
    async tryDownloadDokument(path, name) {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    this.resetForm();
  },
};
</script>
<style scoped>
  .line_through {text-decoration:line-through;}
</style>
