<template>
    <v-snackbar
      v-model="show"
      multi-line
      :timeout="timeout"
      :color="color"
    >
      <v-row justify="space-between" align="center" class="pl-4">
        <v-col cols="10" v-html="text"></v-col>
        <v-col cols="2">
          <v-btn
            text small
            color="red"
            @click="show = false"
            style="transform:translateY(-50%);"
          >
            x
          </v-btn>
        </v-col>
      </v-row>
    </v-snackbar>
</template>

<script>

export default {
  props: {
    text: {
      type: String,
      default: '',
    },
    index: {
      type: Number,
      default: 0,
    },
    color: {
      type: String,
      default: '',
    },
    timeout: {
      type: Number,
      default: 4000,
    },
  },
  data: () => ({
    show: false,
  }),
  created() {
    this.show = true;
  },
  watch: {
    show() {
      if (this.show === false) {
        this.$store.commit('main/alert_delAlert', { index: this.index });
      }
    },
  },
};
</script>
