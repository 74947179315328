<template>
    <div>
      <v-container>
        <v-row justify="center" class="my-0">
          <v-form
            v-model="valid"
          >
            <v-expansion-panels v-model="expansionPanelStatus" accordion>
              <v-expansion-panel
              v-for="(param, idx) in $store.state.main.save.nutzerparameter"
              :key="idx"
              class="grey lighten-5"
              >
                <v-expansion-panel-header>
                  <v-icon left style="flex:none;">{{ param.icon }}</v-icon>
                  {{ param.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="white">
                  <Edit
                    :param="param"
                    :idx="idx"
                    @paramsChanged="paramsChanged = true"
                    @bedingungen="saveBedingungen($event);"
                  />
                  <v-row class="my-0" justify="center">
                    <v-dialog
                      v-model="dialog_delParameter"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          small
                          color="error"
                          v-bind="attrs"
                          v-on="on"
                          >
                            <v-icon left>mdi-delete</v-icon>
                            Parameter Löschen
                          </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Sicher?
                        </v-card-title>

                        <v-card-text class="pt-4">
                          Es werden sämtliche mit diesem Parameter zusammenhängende Daten
                          und Informationen gelöscht.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="primary"
                            text
                            @click="dialog_delParameter = false;"
                          >
                            Nichts tun
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="
                              $store.commit('main/nutzerparameter_delNutzerparam', { idx });
                              paramsChanged = true;
                              dialog_delParameter = false;
                              expansionPanelStatus = null;
                              "
                          >
                            Ja, löschen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-row>
        <v-row justify="center" class="mb-2">
          <v-btn
          small
          @click="
            $store.commit('main/nutzerparameter_addNutzerparam');
            paramsChanged = true;
            expansionPanelStatus = $store.state.main.save.nutzerparameter.length - 1;
          "
          >
            <v-icon
            left
            >mdi-plus-box</v-icon>
            Parameter
          </v-btn>
          <v-btn
          small
          color="success"
          class="ml-2"
          :loading="isSaving"
          v-if="paramsChanged && valid"
          @click="
            $store.dispatch('main/trySaveSettings', { query: 'Nutzerparameter' });
            expansionPanelStatus = null;
          "
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
          </v-btn>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import Edit from '@/components/Funktionen/Benutzerverwaltung/Nutzerparameter/Edit.vue';

export default {
  components: {
    Edit,
  },
  props: {
  },
  data: () => ({
    paramsChanged: false,
    valid: false,
    dialog_delParameter: false,
    expansionPanelStatus: null,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        this.paramsChanged = false;
      }
    },
    paramsChanged(neu) {
      this.$store.commit('main/saveIsChanged', neu);
    },
  },
  methods: {
    saveBedingungen(event) {
      this.$store.commit('main/nutzerparameter_updateBedingungen', event);
    },
  },
};
</script>
