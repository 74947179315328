<template>
  <div class="view view_Einstellungen" v-if="doRender">
    <h1>This is a Einstellungen page</h1>
  </div>
</template>

<script>

export default ({
  name: 'Einstellungen',
  components: {
  },
  data: () => ({
    doRender: false,
    currentTab: {
      code: '',
      query: {},
    },
  }),
  methods: {
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neue Tab innerhalb dieser Seite aufgerufen wird
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
