import Vue from 'vue';
import Vuex from 'vuex';
import main from './modules/main';
import login from './modules/login';
import f001 from './modules/000/f001';
import f110 from './modules/100/f110';
import f150 from './modules/100/f150';
import ftemplate from './modules/ftemplate';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    main,
    login,
    f001,
    f110,
    f150,
    ftemplate,
  },
});
