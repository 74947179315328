<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <p
        class="text-title text--secondary"
      >
        Nutzergruppe der Kunden
      </p>
      <p
        class="text-caption text--secondary mb-0"
      >
        Nutzer, die der folgenden Nutzergruppe zugeordnet sind, werden
        als Kunden behandelt:
      </p>
      <v-select
        :items="$store.state.main.save.nutzergruppen"
        v-model="save.settings.id_nutzergruppe_kunde"
        item-value="id"
        item-text="name"
        class="pt-0"
      />
      <p
        class="text-title text--secondary"
      >
        Offline Daten
      </p>
      <p
        class="text-caption text--secondary mb-0"
      >
        Sollen sämtliche Daten auf dem Gerät gespeichert werden,
        sodass die Einsicht auch offline möglich ist?<br>
        (Die Daten werden lokal auf dem Gerät des Benutzers gespeichert
        und werden nur beim Ausloggen gelöscht.)
      </p>
      <v-checkbox
        v-model="save.settings.dataOffline"
        label="Erlaube Offline Datensicherung"
      ></v-checkbox>
      <v-row v-if="xChanged.settings">
        <v-col align="center">
          <v-btn
          small
          color="success"
          class="ml-2"
          :loading="isSaving"
          @click="saveSettings({type: 'saveSettings', data: save.settings});"
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';

export default {
  components: {
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    xLoaded: {},
    xChanged: {},
    save: {
      settings: {},
    },
    isSaving: false,
  }),
  computed: {
  },
  watch: {
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    save: {
      handler() {
        /*
          Wenn ein Datenobjekt lokal (und nicht durch Laden) verändert wurde
        */
        if (JSON.stringify(this.save.settings) !== JSON.stringify(this.xLoaded.settings)) {
          this.xChanged.settings = true;
        } else {
          this.xChanged.settings = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    saveSettings(data) {
      this.isSaving = true;
      this.$emit('tryGetOrSave', data);
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.prop_settings));
        anythingUpdated = true;
      }
      if (anythingUpdated) {
        this.isSaving = false;
      }
    },
  },
  created() {
    this.datenLaden();
  },
};
</script>
