<template>
  <div
    class="view view_Kunden"
    v-if="doRender"
  >
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <v-container
      v-if="offline"
    >
      <v-alert
        dense transition="scale-transition"
        type="error"
        class="ma-2"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
        Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind.
      </v-alert>
    </v-container>
    <v-progress-linear
      indeterminate
      v-if="loading"
      absolute
    ></v-progress-linear>
    <transition
      v-if="
        currentTab.code === ''
        && tabs.filter((o) => o.erlaubt === 'x|410|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Kundenliste
        v-if="save.xtime"
        :prop_settings="save.settings"
        :prop_kunden="save.kunden"
        :prop_auftraege="save.auftraege"
        :prop_kosten="save.kosten"
        @tryGetOrSave="tryGetOrSave($event)"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === '100'
        && tabs.filter((o) => o.erlaubt === 'x|410|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Auftraege
        v-if="save.xtime"
        :prop_settings="save.settings"
        :prop_auftraege="save.auftraege"
        :prop_kunden="save.kunden"
        :prop_kosten="save.kosten"
        @tryGetOrSave="tryGetOrSave($event)"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === '200'
        && tabs.filter((o) => o.erlaubt === 'x|410|000').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Kostenaufstellung
        v-if="save.xtime"
        :prop_settings="save.settings"
        :prop_kosten="save.kosten"
        :prop_kunden="save.kunden"
        :prop_auftraege="save.auftraege"
        @tryGetOrSave="tryGetOrSave($event)"
      />
    </transition>
    <transition
      v-else-if="
        currentTab.code === 'set'
        && tabs.filter((o) => o.erlaubt === 'x|410|set').length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <Einstellungen
        v-if="save.xtime"
        :prop_settings="save.settings"
        @tryGetOrSave="tryGetOrSave($event)"
      />
    </transition>
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import $ from 'jquery';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';
import Einstellungen from '@/components/Funktionen/400/410-Kunden/Einstellungen.vue';
import Kundenliste from '@/components/Funktionen/400/410-Kunden/Kundenliste.vue';
import Auftraege from '@/components/Funktionen/400/410-Kunden/Auftraege.vue';
import Kostenaufstellung from '@/components/Funktionen/400/410-Kunden/Kostenaufstellung.vue';

export default ({
  name: 'Kinder',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Einstellungen,
    Kundenliste,
    Auftraege,
    Kostenaufstellung,
  },
  data: () => ({
    loading: false,
    offline: false,
    save: {},
    doRender: false,
    tabs: [],
    mytabs: [
      {
        icon: 'mdi-human-child',
        title: 'Kunden',
        route: '/410',
        erlaubt: 'x|410|000',
      },
      {
        icon: 'mdi-file-alert-outline',
        title: 'Aufträge',
        route: '/410-100',
        erlaubt: 'x|410|000',
      },
      {
        icon: 'mdi-cash-multiple',
        title: 'Kosten',
        route: '/410-200',
        erlaubt: 'x|410|000',
      },
      {
        icon: 'mdi-tune',
        title: 'Einstellungen',
        route: '/410-set',
        erlaubt: 'x|410|set',
      },
    ],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    async tryGetOrSave(dataToSave) {
      if (dataToSave) {
        // console.log(dataToSave);
        this.$store.commit('main/switchIsSaving', true);
      }
      this.loading = true;
      if (localStorage.getItem('f410')) {
        const help = JSON.parse(localStorage.getItem('f410'));
        if (help.save) {
          this.save = help.save;
          // this.loading = false;
        }
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/400/410/?getSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            // console.log(response);
            this.offline = false;
            this.save.xtime = response.save.xtime;
            this.save.settings = response.save.settings;
            this.save.kunden = response.save.kunden;
            this.save.auftraege = response.save.auftraege;
            this.save.kosten = response.save.kosten;
            let help = {
              save: {},
            };
            if (localStorage.getItem('f410')) {
              help = JSON.parse(localStorage.getItem('f410'));
            }
            help.save = this.save;
            if (response.save.settings.dataOffline === true) {
              localStorage.setItem('f410', JSON.stringify(help));
            } else {
              localStorage.removeItem('f410');
            }
            if (dataToSave) {
              switch (dataToSave.type) {
                case 'addKunde':
                  this.save.settings.lastAddedKunde = response.lastAddedKunde;
                  break;
                case 'delKunde':
                  this.save.settings.lastDeletedKunde = response.lastDeletedKunde;
                  break;
                case 'saveKunde':
                  this.save.settings.lastEditedKunde = response.lastEditedKunde;
                  break;
                case 'addAuftrag':
                  this.save.settings.lastAddedAuftrag = response.lastAddedAuftrag;
                  break;
                case 'delAuftrag':
                  this.save.settings.lastDeletedAuftrag = response.lastDeletedAuftrag;
                  break;
                case 'saveAuftrag':
                  this.save.settings.lastEditedAuftrag = response.lastEditedAuftrag;
                  this.save.settings
                    .lastEditedLeistungenNeuKosten = response.lastEditedLeistungenNeuKosten;
                  break;
                case 'addKosten':
                  this.save.settings.lastAddedKosten = response.lastAddedKosten;
                  break;
                case 'delKosten':
                  this.save.settings.lastDeletedKosten = response.lastDeletedKosten;
                  break;
                case 'saveKosten':
                  this.save.settings.lastEditedKosten = response.lastEditedKosten;
                  break;
                default:
                  break;
              }
            }
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          if (dataToSave) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: `Die Einstellungen konnten nicht gespeichert werden.
              Bitte die Netzwerkverbindung prüfen.`,
            }, { root: true });
          }
          this.offline = true;
        },
        complete: () => {
          this.loading = false;
          if (dataToSave) {
            this.$store.commit('main/switchIsSaving', false);
          }
        },
      });
    },
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    if (!JSON.parse(localStorage.getItem('f400-410-filterKst'))) {
      localStorage.setItem('f400-410-filterKst', JSON.stringify([]));
    }
    this.tryGetOrSave();
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      localStorage.removeItem('f410');
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neuer Tab innerhalb dieser Seite aufgerufen wird
    if (
      to.path.substring(1, 4) === '410'
    ) {
      // this.tryGetOrSave();
    }
    this.tryGetOrSave();
    this.setCurrentTab(to);
    next();
  },
});
</script>

<style>
</style>
