<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    hide-overlay
    transition="dialog-bottom-transition"
  >
    <v-card>
      <v-toolbar
        dark
        color="primary"
      >
        <v-btn
          icon
          dark
          @click="showDialog = false"
        >
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>
          <span
            v-if="
              $store.state.f150.store.slots.filter((o) => o.id === idSlot).length > 0
            "
          >
            {{ $store.state.f150.store.slots.filter((o) => o.id === idSlot)[0].name }}
          </span>
        </v-toolbar-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <!-- <v-btn
            dark
            text
          >
            Save
          </v-btn> -->
        </v-toolbar-items>
      </v-toolbar>
      <v-card-text class="pa-2">
        <F150SlotsAnwesenheitenEdit
          :prop_editanw="editanw"
          :prop_idslot="idSlot"
        />
        <v-container class="pa-1">
          <v-btn
            v-if="
              bedingungen.anwhinzufuegen.nutzer
              || bedingungen.bearbeiten.nutzer
            "
            small
            class="me-1"
            color="primary"
            @click="createAnw();"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <div>
            <div
              v-for="anw in anwesenheiten"
              :key="anw.id"
            >
              <div
                v-if="anwesenheiten.filter((o) => o.datum === anw.datum)[0].id === anw.id"
                class="mt-4 grey--text text--lighten-1"
              >
                {{
                  new Date(anw.datum).toLocaleString('de-DE', {
                    year: 'numeric', month: '2-digit', day: '2-digit'
                  })
                }}
              </div>
              <v-btn
                v-if="!anw.geschlossen"
                @click="editanw = JSON.parse(JSON.stringify(anw));"
                block
                class="ma-1 darken-3"
                color="primary"
              >
                <v-icon left>
                  mdi-lock-open-variant
                </v-icon>
                {{ anw.titel }}
              </v-btn>
              <v-btn
                v-else
                @click="editanw = JSON.parse(JSON.stringify(anw));"
                block
                class="ma-1"
              >
                <v-icon left>
                  mdi-lock
                </v-icon>
                {{ anw.titel }}
              </v-btn>
            </div>
          </div>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import F150SlotsAnwesenheitenEdit from '@/components/Funktionen/100/F150Anwesenheiten/F150SlotsAnwesenheitenEdit.vue';

export default {
  components: {
    F150SlotsAnwesenheitenEdit,
  },
  props: {
    s: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    showDialog: true,
    idSlot: null,
    editanw: { id: -1 },
    anwesenheiten: [],
  }),
  computed: {
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f150.saving;
      },
    },
    bedingungen: { // Databinding mit vuex state
      get() {
        if (
          this.$store.state.f150.store.slots
            .filter((o) => o.id === this.idSlot).length > 0
        ) {
          return this.$store.state.f150.store.slots
            .filter((o) => o.id === this.idSlot)[0].bedingungen.f150;
        }
        return [];
      },
    },
  },
  watch: {
    showDialog(neu) {
      if (neu === false) {
        setTimeout(() => {
          this.$router.push({ path: '/150' });
        }, 200);
      }
    },
    saving: {
      handler(neu) {
        if (neu.editanw === false) {
          this.loadAnwesenheiten();
        }
      },
      deep: true,
    },
  },
  methods: {
    loadAnwesenheiten() {
      if (this.$store.state.f150.store.slots
        .filter((o) => o.id === this.idSlot).length > 0) {
        this.anwesenheiten = this.$store.state.f150.store.slots
          .filter((o) => o.id === this.idSlot)[0].anwesenheiten;
      }
    },
    createAnw() {
      this.editanw = { id: 0 };
    },
  },
  created() {
    this.idSlot = this.s;
    this.loadAnwesenheiten();
  },
};
</script>
