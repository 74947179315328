<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0 mb-8">
      <div v-if="$store.state.f110.loading.getSettings">
        <v-progress-linear
          indeterminate
          color="primary"
        ></v-progress-linear>
        <v-skeleton-loader
          type="article"
        ></v-skeleton-loader>
      </div>
      <v-subheader v-if="!$store.state.f110.loading.getSettings">Standardkategorie</v-subheader>
      <v-container
        v-if="!$store.state.f110.loading.getSettings"
        class="px-4 pt-0"
      >
        <p class="text-subtitle-1 text--text text--lighten-3">
          Welche Kategorie soll für neue Termine Standardmäßig ausgewählt sein?
        </p>
        <v-select
          :items="settings.kategorien"
          item-text="name"
          item-value="id"
          v-model="settings.standard_id_kategorie"
          label="Kategorie wählen"
        />
      </v-container>
      <v-container
        v-if="!$store.state.f110.loading.getSettings"
        class="px-4 pt-6"
      >
        <v-row align="center">
          <v-col>
            <v-select
              :items="settings.kategorien"
              item-text="name"
              item-value="id"
              v-model="currentKategorie"
              label="Kategorie bearbeiten"
            />
          </v-col>
          <v-col align="center" cols="4" sm="2">
            <v-btn
              x-small
              @click="addKategorie();"
            >
              <v-icon small>mdi-plus-box</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <v-row
          v-for="
            kategorie
            in settings.kategorien
              .filter((o) => o.id === currentKategorie)
          "
          :key="kategorie.id"
          align="center"
        >
          <v-col cols="12" sm="5" class="pe-6">
            <ColorPicker
              v-model="kategorie.farbe"
              label="Farbe"
            />
          </v-col>
          <v-col cols="8" sm="5">
            <v-text-field
              v-model="kategorie.name"
              label="Name"
            />
          </v-col>
          <v-col class="d-flex justify-space-around" cols="4" sm="2" align="center">
            <v-btn
              x-small
              @click="delKategorie(kategorie.id);"
              :disabled="
                (settings.standard_id_kategorie === kategorie.id
                || settings.kategorien.length <= 1)
              "
            >
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <Bedingung
              v-model="kategorie.bedingungen.f110.sehen"
              text_titel="Termine sehen"
              :text_beschreibung="`Welche Nutzer sollen Termine
              sehen dürfen?`"
              text_alle="Alle Nutzer dürfen sehen"
              text_gruppen="Nur bestimmte Nutzer dürfen sehen"
            />
          </v-col>
          <v-col cols="12" sm="6">
            <Bedingung
              v-model="kategorie.bedingungen.f110.bearbeiten"
              text_titel="Termine bearbeiten"
              :text_beschreibung="`Welche Nutzer sollen Termine
              erstellen und bearbeiten dürfen?`"
              text_alle="Alle Nutzer dürfen bearbeiten"
              text_gruppen="Nur bestimmte Nutzer dürfen bearbeiten"
            />
          </v-col>
        </v-row>
      </v-container>
      <v-container class="d-flex justify-space-around flex-wrap"
        v-if="changedSettings"
      >
        <v-btn
          small
          :disabled="saved.settings === JSON.stringify(settings)"
          :loading="$store.state.f110.loading.saveSettings"
          color="success" class="ma-1"
          @click="saveSettings();"
        >
          <v-icon left>mdi-account</v-icon>
          Änderungen speichern
        </v-btn>
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import ColorPicker from '@/components/Inputs/ColorPicker.vue';
import Bedingung from '@/components/Inputs/Bedingung.vue';

export default {
  name: 'F110Einstellungen',
  components: {
    ColorPicker,
    Bedingung,
  },
  props: {
  },
  data: () => ({
    dialogDelKategorie: false,
    dialogUndo: false,
    currentKategorie: null,
    settings: {},
    saved: {
      settings: '',
    },
  }),
  computed: {
    changedSettings() {
      let result = false;
      if (
        this.saved.settings
        && !(this.saved.settings === JSON.stringify(this.settings))
      ) {
        result = true;
      }
      return result;
    },
  },
  watch: {
  },
  methods: {
    changesUndo() {
      this.settings = JSON.parse(this.saved.settings);
    },
    delKategorie(id) {
      let idx = this.settings.kategorien.indexOf(
        this.settings.kategorien.filter((o) => o.id === id)[0],
      );
      this.settings.kategorien
        .splice(idx, 1);
      if (idx > 0) {
        idx -= 1;
      }
      this.currentKategorie = this.settings.kategorien[idx].id;
      if (this.settings.standard_id_kategorie === id) {
        this.settings.standard_id_kategorie = this.settings.kategorien[0].id;
      }
    },
    addKategorie() {
      const sort = JSON.parse(JSON.stringify(this.settings.kategorien))
        .filter((o) => o.id <= 0).sort((a, b) => {
          if (a.id < b.id) {
            return -1;
          }
          if (a.id > b.id) {
            return 1;
          }
          return 0;
        });
      let neuId = 0;
      if (sort.length > 0) {
        neuId = sort[0].id - 1;
      }
      this.settings.kategorien.push({
        id: neuId,
        name: '',
        termine: [],
        bedingungen: {
          f110: {
            bearbeiten: {
              alle: true,
              gruppen: [],
              nutzer: true,
            },
            sehen: {
              alle: true,
              gruppen: [],
              nutzer: true,
            },
          },
        },
        farbe: '#FF0000',
      });
      this.currentKategorie = neuId;
    },
    loadSettings() {
      this.$store.commit('f110/setLoading', { name: 'getSettings', value: true });
      this.$store.dispatch('f110/tryGetOrSaveSettings', {}).then(() => {
        this.setSettings();
        this.$store.commit('f110/setLoading', { name: 'getSettings', value: false });
      });
    },
    setSettings() {
      this.settings = {
        standard_id_kategorie: null,
        kategorien: [],
      };
      this.currentKategorie = this.$store.state.f110.store
        .kalender.kategorien[0].id;
      this.settings.standard_id_kategorie = this.$store.state.f110.store
        .settings.standard_id_kategorie;
      this.settings.kategorien = JSON.parse(JSON.stringify(this.$store.state.f110.store
        .kalender.kategorien));
      this.saved.settings = JSON.stringify(this.settings);
    },
    saveSettings() {
      this.$store.dispatch('f110/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'saveSettings',
          data: this.settings,
        },
      }).then(() => {
        this.setSettings();
        // this.$router.go();
      });
    },
  },
  created() {
    this.loadSettings();
  },
};
</script>
