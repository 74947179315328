<template>
    <div style="position:absolute;left:0px;top:0px;pointer-events:none;">
        <div
            v-for="(chart, idx) in charts"
            :key = "idx"
            style="
                position:absolute;opacity:0;pointer-events:none;
                width:20px;height:20px;overflow:hidden;
            "
        >
            <div style="width:1000px;height:1000px;font-size:300%;background-color:#ffffff;">
            <canvas :id="`myChart${chart.id}`"></canvas>
            </div>
        </div>
    </div>
</template>

<script>
import Chart from 'chart.js';

export default {
  components: {
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    prop_createChart: {
      type: Boolean,
      default: false,
    },
    charts: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data: () => ({
    createChart: false,
  }),
  computed: {
  },
  watch: {
    prop_createChart(neu) {
      if (neu === true) {
        this.buildChart();
      }
    },
  },
  methods: {
    buildChart() {
      this.charts.forEach((c) => {
        Chart.defaults.global.defaultFontSize = c.fontSize;
        Chart.defaults.global.legend.display = false;
        const ctx = document.getElementById(`myChart${c.id}`);
        const myChart = new Chart(ctx, {
          type: 'bar',
          data: {
            labels: c.labels,
            datasets: [{
              label: '',
              data: c.data,
              backgroundColor: c.backgroundColor,
              borderColor: c.borderColor,
              borderWidth: c.borderWidth,
            }],
          },
          options: {
            indexAxis: 'y',
            responsive: true,
            aspectRatio: c.aspectRatio,
            animation: {
              duration: 0,
            },
            scales: {
              beginAtZero: true,
              x: {
                ticks: {
                  font: {
                    size: c.ticksSize,
                  },
                },
              },
              y: {
                ticks: {
                  font: {
                    size: c.ticksSize,
                  },
                },
              },
              yAxes: [{
                ticks: {
                  beginAtZero: true,
                },
              }],
            },
            plugins: {
              legend: {
                display: c.showLegend,
                position: 'bottom',
              },
              title: {
                display: true,
                text: c.title,
                font: {
                  size: c.titleSize,
                },
              },
            },
          },
        });
        const base64String = myChart.toBase64Image();
        c.base64 = base64String;
      });
      setTimeout(() => {
        this.$emit('getChart', { charts: this.charts });
      }, 10);
    },
  },
  created() {
  },
};
</script>
