<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-0">
      <v-tabs
        v-if="this.$store.state.main.save.user.rechte.includes('x|005|010')"
        fixed-tabs
        v-model="currentTab"
      >
        <v-tab>
          Allgemein
        </v-tab>
        <v-tab>
          Nutzerparameter
        </v-tab>
      </v-tabs>
      <v-container
        v-if="currentTab === 0"
      >
        <p align="left" class="text-title text--secondary">
          Welche Nutzer sollen in der Mitgliederübersicht gezeigt werden?
        </p>
        <v-skeleton-loader
          v-if="save.loading"
          type="text"
          class="pa-4"
        ></v-skeleton-loader>
        <SelectNutzergruppen
          v-else
          v-model="save.anzeigeGruppen"
        />
        <div align="center">
          <v-btn
          small
          color="success"
          class="ml-2"
          :loading="isSaving"
          @click="trySaveSettings();"
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Einstellungen Speichern
          </v-btn>
        </div>
      </v-container>
      <v-container
        v-if="
          currentTab === 1
          && this.$store.state.main.save.user.rechte.includes('x|005|010')
        "
        fluid
      >
        <Nutzerparameter />
      </v-container>
    </v-container>
  </v-container>
</template>

<script>
import $ from 'jquery';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';
import Nutzerparameter from '@/components/Funktionen/Benutzerverwaltung/Nutzerparameter/Nutzerparameter.vue';

export default {
  components: {
    SelectNutzergruppen,
    Nutzerparameter,
  },
  props: {
    save: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    currentTab: 0,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
  },
  methods: {
    async trySaveSettings() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/100/?func=saveSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          save: JSON.stringify(this.save),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Die Einstellungen wurden erfolgreich gespeichert',
            });
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
  },
};
</script>
