<template>
    <div>
      <v-container class="pb-0">
        <v-text-field
          clearable
          label="Suchen"
          type="search"
          prepend-icon="mdi-filter"
          v-model="search"
        ></v-text-field>
        <SelectNutzergruppen
          v-model="filterNachGruppen"
          label="Nutzergruppen"
        />
      </v-container>
      <v-container class="pt-0">
        <v-row>
          <v-col>
            <v-btn
            small
            color="primary"
            @click="addUser();"
            v-if="
              benutzer.length > 0
              && this.$store.state.main.save.user.rechte.includes('x|005|000|010')
            "
            >
              <v-icon
              left
              >mdi-plus-box</v-icon>
              Benutzer
            </v-btn>
          </v-col>
        </v-row>
        <v-row justify="center" class="my-0">
          <v-col>
            <v-dialog
              v-if="editUser.user"
              v-model="editUser.showDialog"
              width="1200"
              scrollable
            >
              <v-card style="min-height:70vh;">

                <v-card-text class="pt-4">
                  <Edit
                    :user="editUser.user"
                    :disabled="!$store.state.main.save.user.rechte.includes('x|005|000|020')"
                    :save="editUser.doSave"
                    @updatedUser="saveEditedUser(true)"
                  />
                </v-card-text>

                <v-divider></v-divider>

                <v-card-actions>
                  <v-btn
                    small
                    text
                    @click="editUser.showDialog = false;"
                  >
                  schliessen
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  v-if="editUser.showSaveButton"
                  small
                  text
                  color="primary"
                  :disabled="
                    !editUser.user.nn.split(' ').join('')
                    || editUser.user.nn.split(' ').join('') !== editUser.user.nn
                    || editUser.user.gruppen.length <= 0
                  "
                  @click="
                    saveEditedUser(false);
                    editUser.showDialog = false;
                  "
                >
                  speichern
                </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-data-table
              :headers="headers"
              :items="benutzer.filter((o) => {
                let show = false;
                if (o.gruppen.length > 0) {
                  this.filterNachGruppen.forEach((g) => {
                    if (o.gruppen.includes(g)) {
                      show = true;
                    }
                  });
                  if (o.gruppen.length <= 0) {
                    show = true;
                  } else if (o.gruppen.length === 1) {
                    if (o.gruppen[0] === '') {
                      show = true;
                    }
                  }
                } else {
                  show = true;
                }
                if (show) {
                  return o;
                }
              })"
              :search="search"
              :loading="benutzer.length <= 0"
              :items-per-page="20"
              :header-props="headerProps"
              :footer-props="footerProps"
              no-data-text="Keine Kinder gefunden"
              no-results-text="Keine Kinder gefunden"
              loading-text="Lade Daten... Bitte warten"
              mobile-breakpoint="0"
            >
              <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
              </template>
              <template v-slot:item.aktion="{ item }">
                <v-btn
                  text
                  color="primary"
                  @click="showEditUser(item);"
                  :loading="editUser.loading === item.id"
                >
                  <v-icon>mdi-account-edit</v-icon>
                </v-btn>
                <v-dialog
                  v-if="
                    $store.state.main.save.user.rechte.includes('x|005|000|030')
                  "
                  v-model="dialog_delUser[item.id]"
                  width="500"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                    text
                    color="error"
                    :loading="isDeleting[item.id]"
                    v-bind="attrs"
                    v-on="on"
                    :disabled="item.id === $store.state.main.save.user.id"
                    >
                      <v-icon small>mdi-delete</v-icon>
                    </v-btn>
                  </template>
                  <v-card>
                    <v-card-title class="text-h5 grey lighten-2">
                      Sicher?
                    </v-card-title>
                    <v-card-text class="pt-4">
                      Es werden sämtliche mit diesem Benutzer zusammenhängende Daten
                      und Informationen gelöscht.
                    </v-card-text>
                    <v-divider></v-divider>
                    <v-card-actions>
                      <v-btn
                        text
                        @click="dialog_delUser[item.id] = false;"
                      >
                        Nichts tun
                      </v-btn>
                      <v-spacer></v-spacer>
                      <v-btn
                        color="primary"
                        text
                        @click="
                          dialog_delUser[item.id] = false;
                          tryDelUser(item);
                        "
                      >
                        Ja, löschen
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import $ from 'jquery';
import Edit from '@/components/Funktionen/Benutzerverwaltung/Benutzer/Edit.vue';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    Edit,
    SelectNutzergruppen,
  },
  props: {
  },
  data: () => ({
    headers: [
      { text: 'Nutzername', value: 'nn' },
      { text: 'Name', value: 'ident' },
      { text: '', value: 'aktion', sortable: false },
    ],
    search: '',
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageAllText: 'Alle',
    },
    benutzer: [],
    dialog_delUser: {},
    editUser: {
      showDialog: false,
      formValid: true,
      user: null,
      strSaveUser: '',
      showSaveButton: false,
      doSave: false,
      loading: 0,
    },
    isDeleting: {},
    filter: '',
    filterNachGruppen: [],
  }),
  computed: {
  },
  watch: {
    filter(neu) {
      const filter = neu.toLowerCase();
      this.benutzer.forEach((o) => {
        if (filter === '' || o.nn.toLowerCase().includes(filter) || o.ident.toLowerCase().includes(filter)) {
          o.show = true;
        } else {
          o.show = false;
        }
        o.nutzerparameter.forEach((p) => {
          if (o.nutzerparameterwerte[p]) {
            if (o.nutzerparameterwerte[p].toLowerCase().includes(filter)) {
              o.show = true;
            }
          }
        });
      });
    },
    editUser: {
      handler(neu) {
        if (neu.showDialog) {
          if (neu.strSaveUser !== '' && neu.strSaveUser !== JSON.stringify(neu.user)) {
            this.editUser.showSaveButton = true;
          } else if (neu.strSaveUser !== JSON.stringify(neu.user)) {
            // console.log('wieder gleich');
          }
        } else {
          this.editUser.strSaveUser = '';
          this.editUser.showSaveButton = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    showEditUser(user) {
      this.editUser.user = JSON.parse(JSON.stringify(user));
      this.editUser.strSaveUser = JSON.stringify(user);
      this.editUser.showSaveButton = false;
      this.editUser.showDialog = true;
    },
    saveEditedUser(success) {
      if (!success) {
        this.editUser.doSave = true;
        this.editUser.loading = this.editUser.user.id;
      } else {
        this.editUser.doSave = false;
        this.tryGetUsers();
      }
    },
    addUser() {
      let standardgruppen = this.$store.state.main.save.nutzergruppen
        .filter((o) => o.standard === true);
      const standardparameter = standardgruppen.map((o) => o.nutzerparameter);
      const neuStandardparameter = [];
      standardparameter.forEach((p) => {
        neuStandardparameter.push(...p);
      });
      // standardparameter = [].concat.apply(...standardparameter);
      standardgruppen = standardgruppen.map((o) => o.id);
      const neuBenutzer = {
        show: true,
        id: '',
        ident: '',
        nn: '',
        gruppen: standardgruppen,
        nutzerparameter: neuStandardparameter,
        nutzerparameterwerte: [],
        nutzerparameterwerteSelectoptionIDs: [],
        rechteSolo: [],
      };
      this.showEditUser(neuBenutzer);
      /* this.benutzer.push({
        show: true,
        id: '',
        ident: '',
        nn: '',
        gruppen: standardgruppen,
        nutzerparameter: neuStandardparameter,
        nutzerparameterwerte: [],
        nutzerparameterwerteSelectoptionIDs: [],
        rechteSolo: [],
      });
      this.currentUser = this.benutzer.length - 1;
      this.showEditUser(this.benutzer[this.currentUser]); */
      /* setTimeout(() => {
        this.scrollTo(`user_${this.currentUser}`);
      }, 200); */
    },
    async tryDelUser(user) {
      this.isDeleting[user.id] = true;
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=delNutzer`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: user.id,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.benutzer.splice(this.benutzer.indexOf(user), 1);
            this.isDeleting[user.id] = false;
            this.$store.commit('main/switchIsSaving', false);
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.isDeleting[user.id] = false;
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
    async tryGetUsers(event) {
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=getNutzer`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.benutzer = response.nutzer;
          }
          if (event) {
            setTimeout(() => {
              const newIdx = this.benutzer
                .indexOf(this.benutzer.filter((o) => o.id === event.addedUserID)[0]);
              this.currentUser = newIdx;
              this.scrollTo(`user_${this.currentUser}`);
            }, 100);
          }
        },
        timeout: 10000,
        error: () => {
          // Benutzer konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Benutzer konnten nicht geladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.editUser.loading = 0;
        },
      });
    },
  },
  created() {
    this.tryGetUsers();
    this.filterNachGruppen = JSON.parse(JSON.stringify(this.$store.state.main.save.nutzergruppen))
      .map((a) => a.id);
  },
};
</script>
