import '@mdi/font/css/materialdesignicons.css'; // Ensure you are using css-loader
import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#1976D2', // 1976D2 // KJA-LRO: #71b036
        secondary: '#ededed', // 424242
        accent: '#82B1FF', // 82B1FF
        error: '#FF5252', // FF5252
        info: '#2196F3', // 2196F3
        success: '#4CAF50', // 4CAF50
        warning: '#ffa600', // ffa600
        background: '#ffffff',
        text: '#333333',
      },
      dark: {
        primary: '#0d4985', // 1976D2
        secondary: '#424242', // 424242
        accent: '#82B1FF', // 82B1FF
        error: '#FF5252', // FF5252
        info: '#2196F3', // 2196F3
        success: '#4CAF50', // 4CAF50
        warning: '#ffa600', // ffa600
        background: '#333333',
        text: '#ffffff',
      },
    },
    dark: false,
  },
  icons: {
    iconfont: 'mdi', // 'mdi' || 'mdiSvg' || 'md' || 'fa' || 'fa4' || 'faSvg'
  },
});
