var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"ma-0 pa-0 d-flex justify-center",attrs:{"fluid":""}},[(_vm.prop_kinder.length <= 0)?_c('v-container',{staticClass:"ma-0 pa-8"},[_c('h2',[_vm._v("Fehler")]),_c('p',[_vm._v(" Ihnen sind keine Kinder zugeordnet. Bitte wenden Sie sich an Ihre Einrichtung damit ihr Konto einem Kind zugeordnet wird. ")])]):_vm._e(),(_vm.prop_kinder.length > 0)?_c('v-container',{staticClass:"ma-0 pa-0"},[_c('v-tabs',{staticClass:"d-md-none mb-6",attrs:{"fixed-tabs":""},model:{value:(_vm.tabs_meldenHistorie),callback:function ($$v) {_vm.tabs_meldenHistorie=$$v},expression:"tabs_meldenHistorie"}},[_c('v-tab',[_vm._v("Melden")]),_c('v-tab',[_vm._v("Historie")])],1),_c('v-row',[(
          _vm.tabs_meldenHistorie === 0 ||
          _vm.$vuetify.breakpoint.mdAndUp
        )?_c('v-col',[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Melden ")]),_c('v-row',[_c('v-col',{staticClass:"pa-8"},[_c('v-form',{model:{value:(_vm.form.valid),callback:function ($$v) {_vm.$set(_vm.form, "valid", $$v)},expression:"form.valid"}},[_c('p',[_vm._v(" Bitte senden Sie uns die folgende Mitteilung für Heute "),_c('b',[_vm._v("bis spätestens "+_vm._s(_vm.prop_settings.anwesenheitMeldungSpaetestensBis)+" Uhr")]),_vm._v(" zu: ")]),(this.prop_settings.text_alertVorMeldungsformular !== '')?_c('v-alert',{staticStyle:{"text-align":"justify"},attrs:{"border":"bottom","colored-border":"","type":"warning","elevation":"2"}},[_c('p',{staticClass:"pa-0 ma-0",domProps:{"innerHTML":_vm._s(this.prop_settings.text_alertVorMeldungsformular_nl2br)}})]):_vm._e(),_c('v-divider',{staticClass:"my-8"}),_c('p',[_vm._v(" Hiermit bestätige ich, dass mein Kind ")]),_c('v-select',{attrs:{"multiple":"","label":"Kind wählen","prepend-icon":"mdi-human-child","items":_vm.prop_kinder,"item-text":"nameVollst","item-value":"id"},model:{value:(_vm.form.kind),callback:function ($$v) {_vm.$set(_vm.form, "kind", $$v)},expression:"form.kind"}}),(_vm.errorTageZuWeitInZukunft)?_c('v-alert',{staticStyle:{"text-align":"justify"},attrs:{"border":"bottom","colored-border":"","type":"error","elevation":"2"}},[_c('p',{staticClass:"pa-0 ma-0"},[_vm._v(" Es können nur Meldungen gesendet werden, die maximal 7 Tage in der Zukunft und nicht in der Vergangenheit liegen. Bitte überprüfen Sie Ihre Angaben. ")])]):_vm._e(),_c('DatePicker',{staticClass:"mx-6",attrs:{"label":"vom","rules":[
                  _vm.$store.state.main.inputValidationRules.required ]},model:{value:(_vm.tag),callback:function ($$v) {_vm.tag=$$v},expression:"tag"}}),_c('DatePicker',{staticClass:"mx-6",attrs:{"label":"bis zum","rules":[
                  _vm.$store.state.main.inputValidationRules.required ]},model:{value:(_vm.tag_bis),callback:function ($$v) {_vm.tag_bis=$$v},expression:"tag_bis"}}),_c('v-select',{attrs:{"label":"Anwesenheit wählen","items":_vm.anwesenheiten,"item-text":"text","item-value":"id"},model:{value:(_vm.form.anwesenheit),callback:function ($$v) {_vm.$set(_vm.form, "anwesenheit", $$v)},expression:"form.anwesenheit"}}),_c('v-text-field',{attrs:{"label":"Kommentar","prepend-icon":"","rules":[
                  _vm.$store.state.main.inputValidationRules.nameMore ],"hint":((_vm.form.kommentar.length) + "/40 Zeichen"),"maxlength":"40","placeholder":_vm.form.kommentarPlaceholder},model:{value:(_vm.form.kommentar),callback:function ($$v) {_vm.$set(_vm.form, "kommentar", $$v)},expression:"form.kommentar"}})],1)],1)],1),(_vm.prop_settings.meldung_erlaubeDateiupload)?_c('v-row',{staticClass:"my-0"},[_c('v-col',{staticClass:"px-8 py-0"},[_c('div',{staticClass:"pb-2"},[_vm._v(" Anhänge: ")]),(_vm.uploadedFiles.length <= 0)?_c('p',{attrs:{"align":"center"}},[_c('i',[_vm._v("Keine Anhänge angefügt")])]):_vm._e(),_c('ul',{staticStyle:{"list-style":"none"}},_vm._l((_vm.uploadedFiles),function(f,idx){return _c('li',{key:idx},[_c('v-btn',{staticClass:"me-1",attrs:{"x-small":"","color":"error"},on:{"click":function($event){return _vm.uploadedFiles.splice(idx, 1);}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1),_vm._v(" "+_vm._s(f.name)+" ")],1)}),0),_c('div',{attrs:{"align":"center"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","small":"","dark":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-plus-box")])],1)]}}],null,false,3779100350),model:{value:(_vm.dialog_addDokument),callback:function ($$v) {_vm.dialog_addDokument=$$v},expression:"dialog_addDokument"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Dokumente anhängen ")]),_c('v-card-text',[_c('UploadBilderPDF',{attrs:{"apipath":("200/210/upload.php?key=" + (this.$store.state.login.session.code) + "&fill=\n                        &id_a="),"accept":"application/pdf, image/*"},on:{"neuDokumente":function($event){return _vm.addDokumente($event);}}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_addDokument = false}}},[_vm._v(" schließen ")])],1)],1)],1)],1)])],1):_vm._e(),_c('v-row',[_c('v-col',{staticClass:"mb-8",attrs:{"align":"center"}},[_c('v-btn',{attrs:{"disabled":!_vm.form.valid
                || _vm.form.kind.length <= 0
                || _vm.form.anwesenheit === ''
                || _vm.errorTageZuWeitInZukunft,"loading":_vm.loading_meldungSenden},on:{"click":function($event){return _vm.meldungSenden();}}},[_vm._v(" Mitteilung senden ")])],1)],1)],1):_vm._e(),(
          _vm.tabs_meldenHistorie === 1 ||
          _vm.$vuetify.breakpoint.mdAndUp
        )?_c('v-col',[_c('v-container',{staticClass:"text-title text--secondary text-uppercase text-center d-none d-md-block"},[_vm._v(" Historie ")]),_c('v-select',{attrs:{"label":"Kind wählen","prepend-icon":"mdi-human-child","items":_vm.prop_kinder,"item-text":"nameVollst","item-value":"id"},model:{value:(_vm.tabs_historieKind),callback:function ($$v) {_vm.tabs_historieKind=$$v},expression:"tabs_historieKind"}}),_c('v-row',{attrs:{"align":"center"}},[_c('v-col',[_c('DatePicker',{staticClass:"mx-6",attrs:{"label":"Filter nach Tag"},model:{value:(_vm.historie_filterMeldungenTag),callback:function ($$v) {_vm.historie_filterMeldungenTag=$$v},expression:"historie_filterMeldungenTag"}})],1),_c('v-col',{attrs:{"cols":"3","align":"center"}},[_c('v-btn',{attrs:{"small":""},on:{"click":function($event){_vm.historie_filterMeldungenTag = ''}}},[_vm._v(" x ")])],1)],1),_c('v-row',[_vm._l((_vm.prop_kinder.filter(function (o) {
                if (
                  o.id === _vm.tabs_historieKind
                ) {
                  return o;
                }
              })),function(kind){return _c('v-col',{key:kind.id,attrs:{"cols":"12"}},[_c('v-card',{staticClass:"ma-4",attrs:{"elevation":"2"}},[_c('v-card-title',[_vm._v(" "+_vm._s(kind.nameVollst)+" ")]),_c('v-card-text',[(kind.ausnahmen.length <= 0)?_c('p',[_vm._v(" Es wurden noch keine Meldung gesendet ")]):(
                    _vm.historie_filterMeldungenTagDE != ''
                    && !kind.ausnahmen[_vm.historie_filterMeldungenTagDE]
                  )?_c('p',[_vm._v(" Es wurde keine Meldung für den "+_vm._s(_vm.historie_filterMeldungenTagDE)+" gesendet. ")]):_vm._e(),_vm._l((kind.ausnahmen),function(ausnTag,tag){return _c('div',{key:tag},[(
                        tag === _vm.historie_filterMeldungenTagDE
                        || (
                          _vm.historie_filterMeldungenTagDE === ''
                          && Math.ceil((new Date(ausnTag[0].tagValue).getTime()
                          - new Date().getTime()) / 1000 / 60 / 60 / 24) >= 0
                        )
                      )?[_c('h2',[_vm._v(_vm._s(tag))]),_vm._l((ausnTag),function(ausn,idx){return _c('div',{key:idx},[_c('v-divider'),_c('br'),_c('p',{class:{line_through: (idx + 1 < ausnTag.length)}},[_c('span',{staticStyle:{"font-size":"80%"}},[_vm._v(" "+_vm._s(idx + 1)+"/"+_vm._s(ausnTag.length)+" [Eingegangen am "+_vm._s(ausn.elternErstellt_tag)+" um "+_vm._s(ausn.elternErstellt_zeit)+":] ")]),_c('br'),_c('span',[_vm._v(" Hiermit bestätige ich, dass mein Kind "+_vm._s(kind.nameVollst)+" am "+_vm._s(tag)+" "),_c('b',[_vm._v(_vm._s(ausn.anwesenheit_text))])])]),(ausn.kommentar.split(' ').join('') !== '')?_c('p',{class:{line_through: (idx + 1 < ausnTag.length)}},[_vm._v(" \""),_c('i',[_vm._v(_vm._s(ausn.kommentar))]),_vm._v("\" ")]):_vm._e(),(ausn.anhaenge.length > 0)?_c('div',[(ausn.anhaenge.length === 1)?_c('span',[_vm._v(" Anhang: ")]):_vm._e(),(ausn.anhaenge.length > 1)?_c('span',[_vm._v(" Anhänge: ")]):_vm._e(),_vm._l((ausn.anhaenge),function(anh,idx){return _c('v-btn',{key:idx,staticStyle:{"overflow":"hidden"},attrs:{"x-small":"","max-width":"100%"},on:{"click":function($event){return _vm.tryDownloadDokument(anh.pfad, anh.name);}}},[_vm._v(" "+_vm._s(anh.name)+" ")])})],2):_vm._e()],1)})]:_vm._e()],2)})],2)],1)],1)})],2)],1):_vm._e()],1)],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }