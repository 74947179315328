<template>
    <v-container fluid>
      <v-row align="end">
        <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Suchen..."
            clearable dense
          ></v-text-field>
        </v-col>
        <v-col>
          <SelectNutzergruppen
            v-model="filterGruppen"
            label="Filter Nutzergruppen"
            @change="filterNachGruppen();"
            :useGruppen="this.save.anzeigeGruppen"
            dense
          />
        </v-col>
      </v-row>
      <v-alert
        v-if="offline"
        dense transition="scale-transition"
        type="error"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
      </v-alert>
      <v-data-table
        :headers="headers"
        :header-props="headerProps"
        :footer-props="footerProps"
        :items="nutzer.filter((o) => o.show === true)"
        :search="search"
        :loading="loading"
        :items-per-page="-1"
        no-data-text="Keine übereinstimmenden Ergebnisse gefunden"
        no-results-text="Keine übereinstimmenden Ergebnisse gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="600"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <!-- <template v-slot:item.ident="{ item }">
          <v-btn
            text color="primary"
            @click="click(item);"
          >
            {{ item.ident }}
          </v-btn>
        </template> -->
        <template v-slot:item="{ item }">
          <tr v-if="$vuetify.breakpoint.smAndUp">
            <td v-for="head in headers" :key="head.value">
              <v-btn
                v-if="head.value === 'ident'"
                text color="primary"
                @click="click(item);"
              >
                {{ item.ident }}
              </v-btn>
              <div
                v-else-if="nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id]"
              >
                <div v-if="head.typ === '7'"> <!-- Datum -->
                  {{
                    new Date(
                      nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id]
                    ).toLocaleString('de-DE', {
                      year: 'numeric', month: '2-digit', day: '2-digit',
                    }).split('Invalid Date').join('')
                  }}
                </div>
                <div v-else>
                  {{ nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id] }}
                </div>
              </div>
              <div v-else></div>
            </td>
          </tr>
          <div v-else>
            <div v-for="head in headers" :key="head.value">
              <v-btn
                v-if="head.value === 'ident'"
                text color="primary"
                @click="click(item);"
              >
                {{ item.ident }}
              </v-btn>
              <v-row
                v-else-if="nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id]"
                class="px-4"
                style="font-size:90%;"
              >
                <v-col>
                  <b>{{ head.text }}:</b>
                </v-col>
                <v-col align="right">
                  <div v-if="head.typ === '7'"> <!-- Datum -->
                    {{
                      new Date(
                        nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id]
                      ).toLocaleString('de-DE', {
                        year: 'numeric', month: '2-digit', day: '2-digit',
                      }).split('Invalid Date').join('')
                    }}
                  </div>
                  <div v-else>
                    {{ nutzer.filter((o) => o.id === item.id)[0].nutzerparameterwerte[head.id] }}
                  </div>
                </v-col>
              </v-row>
              <div v-else></div>
            </div>
          </div>
        </template>
      </v-data-table>
    </v-container>
</template>

<script>
import $ from 'jquery';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    SelectNutzergruppen,
  },
  props: {
    save: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, -1],
      itemsPerPageAllText: 'Alle',
    },
    loading: true,
    offline: false,
    search: '',
    filterGruppen: [],
    headers: [
      { text: 'Nutzer', value: 'ident' },
    ],
    nutzer: [],
  }),
  computed: {
  },
  watch: {
    search(neu) {
      if (neu) {
        localStorage.setItem('f100_filter', neu);
      } else {
        localStorage.removeItem('f100_filter');
      }
    },
  },
  methods: {
    filterNachGruppen() {
      this.nutzer.forEach((n) => {
        n.show = false;
        n.gruppen.forEach((g) => {
          if (this.filterGruppen.includes(g) || this.filterGruppen.length <= 0) {
            n.show = true;
          }
        });
      });
      localStorage.setItem('f100_filterGroups', JSON.stringify(this.filterGruppen));
    },
    click(value) {
      this.$router.push({ path: `?u=${value.id}` });
    },
    async tryGetUsers() {
      if (localStorage.getItem('f100')) {
        this.nutzer = JSON.parse(localStorage.getItem('f100')).nutzer;
        this.filterNachGruppen();
      } else {
        localStorage.setItem('f100', JSON.stringify({ nutzer: [] }));
      }
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/100/?func=getMitgliederliste`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.nutzer = response.nutzer;
            this.loading = false;
            this.offline = false;
            const localsaveNutzer = [];
            this.nutzer.forEach((o) => {
              const localsaveNutzerX = {
                id: o.id,
                ident: o.ident,
                gruppen: o.gruppen,
                nutzerparameter: [],
                nutzerparameterwerte: [],
                nutzerparameterwerteSelectoptionIDs: [],
              };
              this.headers.forEach((h) => {
                if (h.id && o.nutzerparameterwerte) {
                  o[h.value] = o.nutzerparameterwerte[h.id];
                  /* if (h.typ === '7' && o[h.value]) {
                    const help = o[h.value].split('-');
                    o[h.value] = `${help[2]}.${help[1]}.${help[0]}`;
                  } */
                  localsaveNutzerX[h.value] = o[h.value];
                }
              });
              localsaveNutzer.push(localsaveNutzerX);
            });
            const help = JSON.parse(localStorage.getItem('f100'));
            help.nutzer = localsaveNutzer;
            localStorage.setItem('f100', JSON.stringify(help));
            this.filterNachGruppen();
          }
        },
        timeout: 10000,
        error: () => {
          // Benutzer konnten nicht geladen werden
          this.loading = false;
          this.offline = true;
          this.filterNachGruppen();
          /* this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Benutzer konnten nicht geladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true }); */
        },
      });
    },
  },
  created() {
    this.$store.state.main.save.nutzerparameter
      .filter((o) => o.f100.seeOnTable === true).forEach((o) => {
        this.headers.push({
          id: o.id,
          text: o.name,
          value: `paramWert_${o.id}`,
          typ: o.typ,
        });
      });
    if (localStorage.getItem('f100_filterGroups')) {
      this.filterGruppen = JSON.parse(localStorage.getItem('f100_filterGroups'));
    }
    this.tryGetUsers();
    this.search = localStorage.getItem('f100_filter');
  },
};
</script>
