var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-container',{staticClass:"pb-0"},[_c('v-text-field',{attrs:{"clearable":"","label":"Suchen","type":"search","prepend-icon":"mdi-filter"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}}),_c('SelectNutzergruppen',{attrs:{"label":"Nutzergruppen"},model:{value:(_vm.filterNachGruppen),callback:function ($$v) {_vm.filterNachGruppen=$$v},expression:"filterNachGruppen"}})],1),_c('v-container',{staticClass:"pt-0"},[_c('v-row',[_c('v-col',[(
          _vm.benutzer.length > 0
          && this.$store.state.main.save.user.rechte.includes('x|005|000|010')
        )?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.addUser();}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_vm._v(" Benutzer ")],1):_vm._e()],1)],1),_c('v-row',{staticClass:"my-0",attrs:{"justify":"center"}},[_c('v-col',[(_vm.editUser.user)?_c('v-dialog',{attrs:{"width":"1200","scrollable":""},model:{value:(_vm.editUser.showDialog),callback:function ($$v) {_vm.$set(_vm.editUser, "showDialog", $$v)},expression:"editUser.showDialog"}},[_c('v-card',{staticStyle:{"min-height":"70vh"}},[_c('v-card-text',{staticClass:"pt-4"},[_c('Edit',{attrs:{"user":_vm.editUser.user,"disabled":!_vm.$store.state.main.save.user.rechte.includes('x|005|000|020'),"save":_vm.editUser.doSave},on:{"updatedUser":function($event){return _vm.saveEditedUser(true)}}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"small":"","text":""},on:{"click":function($event){_vm.editUser.showDialog = false;}}},[_vm._v(" schliessen ")]),_c('v-spacer'),(_vm.editUser.showSaveButton)?_c('v-btn',{attrs:{"small":"","text":"","color":"primary","disabled":!_vm.editUser.user.nn.split(' ').join('')
                || _vm.editUser.user.nn.split(' ').join('') !== _vm.editUser.user.nn
                || _vm.editUser.user.gruppen.length <= 0},on:{"click":function($event){_vm.saveEditedUser(false);
                _vm.editUser.showDialog = false;}}},[_vm._v(" speichern ")]):_vm._e()],1)],1)],1):_vm._e(),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.benutzer.filter(function (o) {
            var show = false;
            if (o.gruppen.length > 0) {
              this$1.filterNachGruppen.forEach(function (g) {
                if (o.gruppen.includes(g)) {
                  show = true;
                }
              });
              if (o.gruppen.length <= 0) {
                show = true;
              } else if (o.gruppen.length === 1) {
                if (o.gruppen[0] === '') {
                  show = true;
                }
              }
            } else {
              show = true;
            }
            if (show) {
              return o;
            }
          }),"search":_vm.search,"loading":_vm.benutzer.length <= 0,"items-per-page":20,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Keine Kinder gefunden","no-results-text":"Keine Kinder gefunden","loading-text":"Lade Daten... Bitte warten","mobile-breakpoint":"0"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
          var pageStart = ref.pageStart;
          var pageStop = ref.pageStop;
          var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.aktion",fn:function(ref){
          var item = ref.item;
return [_c('v-btn',{attrs:{"text":"","color":"primary","loading":_vm.editUser.loading === item.id},on:{"click":function($event){return _vm.showEditUser(item);}}},[_c('v-icon',[_vm._v("mdi-account-edit")])],1),(
                _vm.$store.state.main.save.user.rechte.includes('x|005|000|030')
              )?_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
              var on = ref.on;
              var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"error","loading":_vm.isDeleting[item.id],"disabled":item.id === _vm.$store.state.main.save.user.id}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.dialog_delUser[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog_delUser, item.id, $$v)},expression:"dialog_delUser[item.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Sicher? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche mit diesem Benutzer zusammenhängende Daten und Informationen gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_delUser[item.id] = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_delUser[item.id] = false;
                      _vm.tryDelUser(item);}}},[_vm._v(" Ja, löschen ")])],1)],1)],1):_vm._e()]}}])})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }