<template>
  <div class="mt-4 pa-4">
    <p class="text-h4">App</p>
    <v-row>
      <v-col>
        <p class="text-h5">Allgemein</p>
        <v-text-field
          v-model="store.settings.app_titel"
          label="Name der App"
        ></v-text-field>
        <v-text-field
          v-model="store.settings.app_untertitel"
          label="Untertitel"
        ></v-text-field>
        <v-text-field
          v-model="store.settings.app_url"
          label="URL"
        ></v-text-field>
        <ColorPicker
          v-model="store.settings.app_theme_color_primary"
          label="Farbe: Primary"
        />
      </v-col>
    </v-row>
    <p class="text-h4">Login</p>
    <v-row>
      <v-col>
        <p class="text-h5">Allgemein</p>
        <v-textarea
          outlined auto-grow
          label="Willkommenstext"
          v-model="store.settings.loginscreen_welcomeText"
          hint=""
        ></v-textarea>
        <!-- <div style="position:relative;">
          <v-text-field
            v-model="store.settings.loginscreen_bg_color"
            label="Hintergrund Farbe"
          ></v-text-field>
          <v-color-picker
            dot-size="25"
            hide-mode-switch
            hide-inputs
            elevation="6"
            style="
              position:absolute;z-index:10;right:10px;top:50px;
              border:solid #ffffff 10px;
            "
            mode="hexa"
            swatches-max-height="200"
            v-model="store.settings.loginscreen_bg_color"
          ></v-color-picker>
        </div> -->
        <ColorPicker
          v-model="store.settings.loginscreen_bg_color"
          label="Hintergrund Farbe"
        />
        <v-text-field
          v-model="store.settings.loginscreen_bg_img"
          label="Hintergrund Bild"
        ></v-text-field>
        <p class="text-h5">Infos</p>
        <div
          v-for="(info,idx) in store.settings.loginscreen_infos"
          :key="idx"
          class="mb-8"
        >
          <v-text-field
            v-model="info.titel"
            label="Titel"
          ></v-text-field>
          <v-textarea
            outlined auto-grow
            label="Text"
            v-model="info.text"
            hint=""
          ></v-textarea>
          <div align="right">
            <v-btn x-small class="ma-2"
              @click="delInfo(idx);"
            ><v-icon>mdi-minus-box</v-icon></v-btn>
            <v-btn x-small class="ma-2"
              @click="addInfo(idx);"
            ><v-icon>mdi-plus-box</v-icon></v-btn>
          </div>
        </div>
        <v-btn x-small class="ma-2"
          @click="addInfo(0);"
          v-if="store.settings.loginscreen_infos.length <= 0"
        ><v-icon>mdi-plus-box</v-icon></v-btn>
      </v-col>
    </v-row>
    <div align="center">
      <v-btn
        small
        :disabled="!changedStore.settings"
        :loading="$store.state.f001.saving"
        color="success"
        @click="$store.dispatch(`f${sitecode}/tryGetOrSaveSettings`, { dataToSave: {
          type: 'settings',
          data: store,
        }});"
      >
        <v-icon left>mdi-account</v-icon>
        Änderungen speichern
      </v-btn>
    </div>
  </div>
</template>

<script>
// import $ from 'jquery';
import ColorPicker from '@/components/Inputs/ColorPicker.vue';

export default {
  components: {
    ColorPicker,
  },
  props: {
    sitecode: {
      type: String,
      default: '001',
    },
  },
  data: () => ({
    store: {},
    lastLoadedStore: {},
    changedStore: {},
  }),
  computed: {
    loadedStore: { // Databinding mit vuex state
      get() {
        return this.$store.state[`f${this.sitecode}`].store;
      },
    },
  },
  watch: {
    loadedStore: {
      handler(neu) {
        /*
          Wenn settings in der Datenbank geändert wurde,
          lade diese neu ein.
        */
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.store.settings = neu.settings;
          this.lastLoadedStore.settings = JSON.stringify(neu.settings);
          this.changedStore.settings = false;
        }
      },
      deep: true,
    },
    store: {
      handler(neu) {
        /*
          Prüfe ob settings lokal geändert wurde
        */
        // console.log(neu.settings.app_theme_color_primary);
        this.$vuetify.theme.themes.light.primary = neu.settings.app_theme_color_primary;
        if (JSON.stringify(neu.settings) !== this.lastLoadedStore.settings) {
          this.changedStore.settings = true;
        } else {
          this.changedStore.settings = false;
        }
      },
      deep: true,
    },
  },
  methods: {
    addInfo(idx) {
      /* this.store.settings.loginscreen_infos.push({
        titel: '',
        text: '',
      }); */
      this.store.settings.loginscreen_infos.splice(idx + 1, 0, {
        titel: '',
        text: '',
      });
    },
    delInfo(idx) {
      this.store.settings.loginscreen_infos.splice(idx, 1);
    },
  },
  created() {
    this.store = JSON.parse(JSON.stringify(this.$store.state[`f${this.sitecode}`].store));
    this.lastLoadedStore.settings = JSON.stringify(this.$store.state[`f${this.sitecode}`].store.settings);
    this.changedStore.settings = false;
  },
};
</script>
