<template>
  <div
    class="view view_Appeinstellungen"
    v-if="doRender"
  >
    <viewTabsTop v-if="tabs.length > 1" :tabs="tabs" />
    <!-- CONTENT -->
    <v-progress-linear
      v-if="
        $store.state[`f${sitecode}`].loading
        && !$store.state[`f${sitecode}`].offline
      "
      indeterminate
      style="transform:translateY(-2px);"
      absolute
      color="primary"
    ></v-progress-linear>
    <v-alert
      dense
      v-if="
        $store.state[`f${sitecode}`].offline
        && $store.state[`f${sitecode}`].offlineHideAlert
      "
      type="error"
      style="transform:translateY(-2px);border-radius:0px;"
    >
    </v-alert>
    <v-container
      v-if="
        $store.state[`f${sitecode}`].offline
        && !$store.state[`f${sitecode}`].offlineHideAlert
      "
    >
      <v-alert
        dense transition="scale-transition"
        type="error"
        class="ma-2"
        style="font-size:80%;"
      >
        <b>KEINE NETZWERKVERBINDUNG</b>
        <br>
        Die angezeigten Daten können veraltet sein.
        Bevor Änderungen vorgenommen werden, vergewissern Sie sich, dass die Daten aktuell sind.
        <div align="right">
          <v-btn small text
            @click="$store.commit(`${sitecode}/offlineHideAlert`);"
          >verstanden</v-btn>
        </div>
      </v-alert>
    </v-container>
    <transition
      v-if="
        currentTab.code === ''
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          <Einstellungen
            v-if="$store.state.f001.store.settings"
            :sitecode="sitecode"
          />
        </v-container>
      </v-container>
    </transition>
    <!-- <transition
      v-else-if="
        currentTab.code === `010`
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          Tab 2
        </v-container>
      </v-container>
    </transition>
    <transition
      v-else-if="
        currentTab.code === `100`
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          Tab 3
        </v-container>
      </v-container>
    </transition>
    <transition
      v-else-if="
        currentTab.code === 'set'
        && tabs.filter((o) => o.erlaubt === `x|${sitecode}`).length > 0
      "
      name="custom-classes-transition"
      :enter-active-class="transitionIn"
      :leave-active-class="transitionOut"
    >
      <v-container fluid class="ma-0 pa-0 d-flex justify-center">
        <v-container class="ma-0 pa-0">
          Tab Einstellungen
        </v-container>
      </v-container>
    </transition> -->
    <p v-else>
      Zugriff verweigert
    </p>
    <!-- CONTENT -->
    <viewTabsBottom v-if="tabs.length > 1" :tabs="tabs" />
  </div>
</template>

<script>
import 'animate.css';
import ViewTabsTop from '@/components/ViewTabsTop.vue';
import ViewTabsBottom from '@/components/ViewTabsBottom.vue';
import Einstellungen from '@/components/Funktionen/000/001-Appeinstellungen/Einstellungen.vue';

export default ({
  name: 'Appeinstellungen',
  components: {
    ViewTabsTop,
    ViewTabsBottom,
    Einstellungen,
  },
  data: () => ({
    sitecode: '001',
    doRender: false,
    tabs: [],
    mytabs: [],
    currentTab: {
      code: '',
      query: {},
    },
  }),
  computed: {
    transitionIn() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeInLeft';
      }
      return result;
    },
    transitionOut() {
      let result = '';
      if (this.$vuetify.breakpoint.mdAndDown) {
        result = 'animate__animated animate__fadeOutLeft';
      }
      return result;
    },
  },
  methods: {
    setCurrentTab(route) {
      this.currentTab.code = route.path.replace(this.$router.history.current.path.substring(0, 4), '').replace('-', '');
      this.currentTab.query = route.query;
      this.doRender = true;
    },
  },
  created() {
    this.mytabs = [
      {
        icon: 'mdi-human-child',
        title: 'Anwesenheit',
        route: `/${this.sitecode}`,
        erlaubt: `x|${this.sitecode}`,
      },
      /* {
        icon: 'mdi-human-male-female-child',
        title: 'Eltern',
        route: `/${this.sitecode}-010`,
        erlaubt: `x|${this.sitecode}`,
      },
      {
        icon: 'mdi-chart-bar',
        title: 'Statistik',
        route: `/${this.sitecode}-100`,
        erlaubt: `x|${this.sitecode}`,
      },
      {
        icon: 'mdi-tune',
        title: 'Einstellungen',
        route: `/${this.sitecode}-set`,
        erlaubt: `x|${this.sitecode}`,
      }, */
    ];
    if (this.$store.state.main.save.mainmenu_links
      .some((e) => e.route === this.$router.history.current.path.substring(0, 4))) {
      // Wenn Zugriff auf diese View erlaubt
      // erlaube das Rendern
      this.setCurrentTab(this.$router.history.current);
    } else {
      // Wenn Zugriff auf diese View NICHT erlaubt
      // Leite zur ersten erlaubten Seite um
      this.$router.push({ path: this.$store.state.main.save.mainmenu_links[0].route });
      window.location.reload();
    }
    // Tabs erstellen:
    this.mytabs.forEach((tab) => {
      if (
        this.$store.state.main.save.user.rechte.find((element) => {
          let bool = false;
          if (element.includes(tab.erlaubt)) {
            bool = true;
          }
          return bool;
        })
      ) {
        this.tabs.push(tab);
      }
    });
    // Wenn der aktuelle Tabs nicht erlaubt ist, zum ersten erlaubten Tab navigieren:
    if (
      this.tabs.filter((o) => o.route === this.$router.history.current.path).length <= 0
      && this.tabs.length > 0
    ) {
      this.$router.push({ path: this.tabs[0].route });
    }
    // Sonstiges direkt nach dem Aufruf der Seite:
    this.$store.dispatch(`f${this.sitecode}/tryGetOrSaveSettings`, {});
  },
  beforeRouteUpdate(to, from, next) {
    // Wenn ein neuer Tab innerhalb dieser Seite aufgerufen wird
    if (
      to.path.substring(1, 4) === this.sitecode
    ) {
      // Wenn ein Tab der Seite aufgerufen wird.
      // this.$store.dispatch(`f${this.sitecode}/tryGetOrSaveSettings`, {});
    }
    this.setCurrentTab(to);
    next();
  },
  beforeRouteLeave(to, from, next) {
    console.log('--');
    console.log('Aktiv:', this.$vuetify.theme.themes.light.primary);
    console.log('main', this.$store.state.main.save.settings.app_theme_color_primary);
    console.log('f001', this.$store.state.f001.store.settings.app_theme_color_primary);
    if (
      this.$store.state.main.save.settings.app_theme_color_primary !== this.$store
        .state.f001.store.settings.app_theme_color_primary
    ) {
      // Andere Farbe gespeichert
      this.$vuetify.theme.themes.light.primary = this.$store
        .state.f001.store.settings.app_theme_color_primary;
    } else if (
      this.$store.state.main.save.settings.app_theme_color_primary !== this.$vuetify
        .theme.themes.light.primary
    ) {
      // Andere Farbe nicht gespeichert
      this.$vuetify.theme.themes.light.primary = this.$store
        .state.main.save.settings.app_theme_color_primary;
    }
    next();
  },
});
</script>

<style>
</style>
