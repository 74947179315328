<template>
    <div class="ma-2">
      <v-tabs
        fixed-tabs
        v-model="tabs_eigenschaftenBedingungen"
        class="d-md-none mb-6"
      >
        <v-tab>Eigenschaften</v-tab>
        <v-tab>Bedingungen</v-tab>
      </v-tabs>
      <v-row>
        <v-col
          v-if="
            tabs_eigenschaftenBedingungen == '0' ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <!-- EIGENSCHAFTEN -->
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Eigenschaften
          </v-container>
          <v-row class="my-0">
            <v-col cols="2" class="pb-0">
              <v-text-field
              type="number"
              label="#"
              v-model="param.sort"
              :rules="[
                $store.state.main.inputValidationRules.required,
              ]"
              ></v-text-field>
            </v-col>
            <v-col cols="10" class="pb-0">
              <v-text-field
              type="text"
              label="Name"
              v-model="param.name"
              :rules="[
                $store.state.main.inputValidationRules.required,
                $store.state.main.inputValidationRules.tinytext,
                $store.state.main.inputValidationRules.name,
              ]"
              ></v-text-field>
            </v-col>
          </v-row><v-row class="my-0" align="center">
            <v-col cols="1" class="pb-0">
              <v-icon>{{ param.icon }}</v-icon>
            </v-col>
            <v-col cols="11" class="pb-0">
              <v-combobox
                v-model="param.icon"
                :items="$store.state.main.save.settings.icons.parameter"
                label="Icon">
                <template slot="item" slot-scope="data">
                  <v-icon left>{{ data.item }}</v-icon>
                  {{data.item}}
                </template>
              </v-combobox>
            </v-col>
          </v-row><v-row class="my-0">
            <v-col cols="12" class="py-0">
              <v-select
                :items="$store.state.main.save.nutzerparameterTypen"
                item-text="name"
                item-value="id"
                label="Parametertyp"
                v-model="param.typ"
              ></v-select>
            </v-col>
          </v-row>
          <!--
            SELECTOPTIONS
          -->
          <v-row class="my-0" v-if="param.typ === '9' || param.typ === '10'">
            <v-col cols="12" class="py-0">
              <v-row
              v-for="(option, idx) in param.selectoptions"
              :key="option.id"
              align="center"
              class="px-4"
              >
                <v-col cols="2" class="py-0">
                  <v-text-field
                  type="number"
                  label="#"
                  v-model="option.sort"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                  ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="8" class="py-0">
                  <v-text-field
                  type="text"
                  label="Wert"
                  v-model="option.wert"
                  :rules="[
                    $store.state.main.inputValidationRules.required,
                    $store.state.main.inputValidationRules.tinytext,
                    $store.state.main.inputValidationRules.name,
                  ]"
                  ></v-text-field>
                </v-col>
                <v-col cols="2" class="py-0">
                  <v-btn x-small block
                  @click="param.selectoptions.splice(idx, 1)"
                  >
                    <v-icon small>mdi-delete</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
              <v-row class="px-4">
                <v-col cols="12" class="pt-0" align="center">
                  <v-btn
                  small
                  @click="param.selectoptions
                  .push({
                    id_nutzerparameter: param.id,
                    sort: param.selectoptions.length + 1,
                    wert: `Option ${(param.selectoptions.length + 1)}`,
                  })"
                  >
                    <v-icon
                    left
                    >mdi-plus-box</v-icon>
                    Option hinzufügen
                  </v-btn>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <!--
            SELECTOPTIONS
          -->
          <v-row class="my-0">
            <v-col cols="12" class="py-0">
              <v-checkbox
              v-model="param.ident"
              label="Identifikationsparameter"
              ></v-checkbox>
            </v-col>
          </v-row>
          <v-row
            v-if="$store.state.main.save.settings.useF.includes('100')"
            class="my-0"
          >
            <v-col cols="12" class="py-0">
              <v-checkbox
              v-model="param.f100.seeOnTable"
              label="In Mitgliederliste anzeigen"
              ></v-checkbox>
            </v-col>
          </v-row>
        </v-col>
        <v-col
          v-if="
            tabs_eigenschaftenBedingungen == '1' ||
            $vuetify.breakpoint.mdAndUp
          "
        >
          <!-- BEDINGUNGEN -->
          <v-container
            class="text-title text--secondary text-uppercase text-center d-none d-md-block"
          >
            Bedingungen
          </v-container>
          <v-row>
            <v-col
              cols="12"
            >
              <template v-if="$store.state.main.save.settings.useF.includes('010')">
                <p class="text-title text--secondary">
                  Im eigenen Profil
                </p>
                <p class="text-caption text--secondary">
                  Wer darf diesen Paramer in seinem eigenen Profil sehen und den Wert bearbeiten?
                  Achtung: Wer bearbeiten kann, kann automatisch auch sehen.
                </p>
                <v-checkbox
                  v-model="param.bedingungen.f010.sehen.alle"
                  label="Jeder kann sehen"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f010.sehen.alle"
                  v-model="param.bedingungen.f010.sehen.gruppen"
                  label="Nur bestimmte können sehen:"
                />
                <v-checkbox
                  v-model="param.bedingungen.f010.bearbeiten.alle"
                  label="Jeder kann bearbeiten"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f010.bearbeiten.alle"
                  v-model="param.bedingungen.f010.bearbeiten.gruppen"
                  label="Nur bestimmte können bearbeiten:"
                />
                <v-divider class="mb-4"/>
              </template>
              <template v-if="$store.state.main.save.settings.useF.includes('100')">
                <p class="text-title text--secondary">
                  In der Mitgliederübersicht
                </p>
                <p class="text-caption text--secondary">
                  Wer darf diesen Paramer bei anderen Mitgliedern sehen und den Wert bearbeiten?
                  Achtung: Wer bearbeiten kann, kann automatisch auch sehen.
                </p>
                <v-checkbox
                  v-model="param.bedingungen.f100.sehen.alle"
                  label="Alle können sehen"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f100.sehen.alle"
                  v-model="param.bedingungen.f100.sehen.gruppen"
                  label="Nur bestimmte können sehen:"
                />
                <v-checkbox
                  v-model="param.bedingungen.f100.bearbeiten.alle"
                  label="Alle können bearbeiten"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f100.bearbeiten.alle"
                  v-model="param.bedingungen.f100.bearbeiten.gruppen"
                  label="Nur bestimmte können bearbeiten:"
                />
              </template>
              <template v-if="$store.state.main.save.settings.useF.includes('200')">
                <p class="text-title text--secondary">
                  In der Elternverwaltung
                </p>
                <p class="text-caption text--secondary">
                  Wer darf diesen Paramer in der Elternverwaltung sehen und den Wert bearbeiten?
                  Achtung: Wer bearbeiten kann, kann automatisch auch sehen.
                </p>
                <v-checkbox
                  v-model="param.bedingungen.f200.sehen.alle"
                  label="Alle können sehen"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f200.sehen.alle"
                  v-model="param.bedingungen.f200.sehen.gruppen"
                  label="Nur bestimmte können sehen:"
                />
                <v-checkbox
                  v-model="param.bedingungen.f200.bearbeiten.alle"
                  label="Alle können bearbeiten"
                ></v-checkbox>
                <SelectNutzergruppen
                  v-if="!param.bedingungen.f200.bearbeiten.alle"
                  v-model="param.bedingungen.f200.bearbeiten.gruppen"
                  label="Nur bestimmte können bearbeiten:"
                />
              </template>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </div>
</template>

<script>
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';

export default {
  components: {
    SelectNutzergruppen,
  },
  props: {
    param: {
      type: Object,
      default() { return {}; },
    },
    idx: {
      type: Number,
      default: 0,
    },
  },
  data: () => ({
    tabs_eigenschaftenBedingungen: null,
  }),
  watch: {
    param: {
      handler() {
        this.$emit('paramsChanged');
        this.$emit('bedingungen', { param: this.idx, bedingungen: this.param.bedingungen });
      },
      deep: true,
    },
  },
};
</script>
