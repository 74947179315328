<template>
  <v-dialog
    v-if="editanw.id >= 0"
    scrollable
    v-model="showDialog"
    width="500"
  >
    <v-card>
      <v-card-title>
        <span v-if="editanw.id === 0">Anwesenheit hinzufügen</span>
        <span v-else>Anwesenheit bearbeiten</span>
      </v-card-title>

      <v-card-text>
        <v-form v-model="formvalid">
          <v-row align="center"
            v-if="
              bedingungen.anwbearbeiten.nutzer
              || bedingungen.bearbeiten.nutzer
            "
          >
            <v-col>
              <v-checkbox
                v-model="editanw.geschlossen"
                label="Abfrage geschlossen"
              />
            </v-col>
            <v-col cols="2">
              <v-dialog
                v-model="dialogInfoOffeneAbfragen"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    text x-small
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-information</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title>
                    Offene Abfragen
                  </v-card-title>

                  <v-card-text>
                    Redakteure können geschlossene Abfragen nicht mehr sehen und bearbeiten.
                    <br/><br/>
                    Außerdem wird durch das Scannen eines QR-Codes des jeweiligen
                    Slots die letzte nicht geschlossene Abfrage dieses Slots aktualisiert.
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialogInfoOffeneAbfragen = false"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <DatePicker
            v-if="
              (
                bedingungen.anwbearbeiten.nutzer
                || bedingungen.bearbeiten.nutzer
                || (
                  bedingungen.anwhinzufuegen.nutzer
                  && !editanw.geschlossen
                )
              )
              && !editanw.geschlossen
            "
            v-model="editanw.datum"
          />
          <div v-else>
            <b>Datum: </b><br/>
            {{
              new Date(editanw.datum).toLocaleString('de-DE', {
                year: 'numeric', month: '2-digit', day: '2-digit'
              })
            }}
          </div>
          <v-text-field
            v-if="
              (
                bedingungen.anwbearbeiten.nutzer
                || bedingungen.bearbeiten.nutzer
                || (
                  bedingungen.anwhinzufuegen.nutzer
                  && !editanw.geschlossen
                )
              )
              && !editanw.geschlossen
            "
            label="Titel"
            hint="Anzuzeigender Name der Veranstaltung"
            v-model="editanw.titel"
            :rules="[
              $store.state.main.inputValidationRules.required,
              $store.state.main.inputValidationRules.name,
            ]"
          ></v-text-field>
          <div v-else
            class="mt-2"
          >
            <b>Titel: </b><br/>
            {{
              editanw.titel
            }}
          </div>
          <v-textarea
            v-if="
              (
                bedingungen.anwbearbeiten.nutzer
                || bedingungen.bearbeiten.nutzer
                || (
                  bedingungen.anwhinzufuegen.nutzer
                  && !editanw.geschlossen
                )
              )
              && !editanw.geschlossen
            "
            outlined auto-grow
            label="Beschreibung (optional)"
            v-model="editanw.beschreibung"
            hint="Informationen zur Veranstaltung"
            class="mt-2"
          ></v-textarea>
          <div v-else-if="editanw.beschreibung !== ''"
            class="mt-2"
          >
            <b>Beschreibung: </b><br/>
            <div v-html="editanw.beschreibung_nl2br"></div>
          </div>
          <div class="mt-2">
            <b>Anwesenheiten:</b><br/>
            <span
              v-for="p in editanw.prueflinge" :key="p.id"
            >
              <v-btn
                v-if="
                  p.status != '0'
                  || !editanw.geschlossen
                "
                class="ma-1"
                small
                :color="moeglicheStatus.filter((o) => o.nummer === p.status)[0].farbe"
                :style="moeglicheStatus.filter((o) => o.nummer === p.status)[0].style"
                @click="switchPrueflingStatus(p);"
              >
                {{ p.name }}
              </v-btn>
            </span>
          </div>
          <v-textarea
            v-if="
              (
                bedingungen.anwbearbeiten.nutzer
                || bedingungen.bearbeiten.nutzer
                || (
                  bedingungen.anwhinzufuegen.nutzer
                  && !editanw.geschlossen
                )
              )
              && !editanw.geschlossen
            "
            outlined auto-grow
            label="Gäste (optional)"
            v-model="editanw.gaeste"
            hint="Sonstige teilnehmende Personen"
            class="mt-6"
          ></v-textarea>
          <div v-else-if="editanw.gaeste !== ''"
            class="mt-2"
          >
            <b>Gäste: </b><br/>
            <div v-html="editanw.gaeste_nl2br"></div>
          </div>
        </v-form>
        <v-btn
          v-if="
            (
              bedingungen.anwbearbeiten.nutzer
              || bedingungen.bearbeiten.nutzer
            ) && !editanw.geschlossen
          "
          small
          @click="downloadQRCode();"
        >
          <v-icon left>mdi-qrcode</v-icon>
          Download QR-Code
        </v-btn>
      </v-card-text>

      <v-divider></v-divider>

      <v-card-actions>
        <v-btn
          small text
          @click="showDialog = false"
        >
          schließen
        </v-btn>
        <v-spacer></v-spacer>
        <v-dialog
          v-if="
            (
              bedingungen.anwbearbeiten.nutzer
              || bedingungen.bearbeiten.nutzer
            )
            && editanw.id !== 0
          "
          v-model="dialog_delAnw[editanw.id]"
          width="500"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              small text color="error"
              v-bind="attrs"
              v-on="on"
            >
              löschen
            </v-btn>
          </template>

          <v-card>
            <v-card-title>
              Sicher?
            </v-card-title>

            <v-card-text>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                small text
                @click="dialog_delAnw[editanw.id] = false"
              >
                Nichts tun
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="error"
                small text
                @click="delAnw(editanw);"
                :loading="saving.delanw[editanw.id]"
              >
                Anwesenheiten löschen
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-btn
          v-if="
            bedingungen.anwbearbeiten.nutzer
            || bedingungen.bearbeiten.nutzer
            || (
              bedingungen.anwhinzufuegen.nutzer
              && (
                editanw.id === 0
                || !editanw.geschlossen
              )
            )
          "
          small text color="primary"
          :disabled="!formvalid"
          :loading="$store.state.f150.saving.editanw"
          @click="$store.dispatch('f150/tryGetOrSaveSettings', { dataToSave: {
            type: 'editanw',
            data: { editanw },
          }, router: $router });"
        >
          speichern
        </v-btn>
        <v-btn
          v-else
          small text color="primary"
          @click="showDialog = false"
        >
          OK
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import $ from 'jquery';
import DatePicker from '@/components/Inputs/DatePicker.vue';

export default {
  components: {
    DatePicker,
  },
  props: {
    prop_editanw: {
      type: Object,
      default() { return { id: 0 }; },
    },
    prop_idslot: {
      type: String,
      default: '0',
    },
  },
  data: () => ({
    dialog_delAnw: {},
    dialogInfoOffeneAbfragen: false,
    showDialog: false,
    editanw: {
      id: -1,
    },
    formvalid: null,
    moeglicheStatus: [
      {
        nummer: 0,
        name: 'Ohne Status',
        text: 'ist ohne Status',
        farbe: 'grey lighten-3',
        style: {},
        // style: { textDecoration: 'line-through' },
      },
      {
        nummer: 1,
        name: 'Anwesend',
        text: 'ist anwesend',
        farbe: 'success',
        style: {},
      },
      {
        nummer: 2,
        name: 'Entschuldigt',
        text: 'fehlt entschuldigt',
        farbe: 'warning',
        style: {},
      },
      {
        nummer: 3,
        name: 'Abwesend',
        text: 'fehlt unentschuldigt',
        farbe: 'error',
        style: {},
      },
    ],
  }),
  computed: {
    saving: { // Databinding mit vuex state
      get() {
        return this.$store.state.f150.saving;
      },
    },
    prueflinge() {
      const slot = this.$store.state.f150.store.slots
        .filter((o) => o.id === this.prop_idslot)[0].prueflinge;
      return slot;
    },
    bedingungen: { // Databinding mit vuex state
      get() {
        return this.$store.state.f150.store.slots
          .filter((o) => o.id === this.prop_idslot)[0].bedingungen.f150;
      },
    },
  },
  watch: {
    prop_editanw: {
      handler(neu) {
        // console.log('editanw geändert:', neu);
        const neuEditanw = JSON.parse(JSON.stringify(neu));
        // const neuStatus = parseInt(this.$store.state.f150.store.settings.standard_status, 10);
        const neuStatus = 0;
        if (neuEditanw.prueflinge) {
          this.$store.state.f150.store.slots
            .find((o) => o.id === this.prop_idslot).prueflinge.forEach((p) => {
              if (!neuEditanw.prueflinge.find((o) => o.id === p.id)) {
                // console.log('NICHT gefunden:', p);
                neuEditanw.prueflinge.push({
                  name: p.ident,
                  id: p.id,
                  status: neuStatus,
                });
                // console.log('eingefügt:', neuEditanw.prueflinge.find((o) => o.id === p.id));
              } else {
                // console.log('gefunden:', neuEditanw.prueflinge.find((o) => o.id === p.id));
              }
            });
          neuEditanw.prueflinge.sort((a, b) => {
            if (a.name.toLowerCase() > b.name.toLowerCase()) {
              return 1;
            }
            if (a.name.toLowerCase() < b.name.toLowerCase()) {
              return -1;
            }
            return 0;
          });
        }
        this.editanw = neuEditanw;
        this.showDialog = true;
      },
      deep: true,
    },
    saving: {
      handler(alt, neu) {
        if (
          (neu.editanw === false)
        ) {
          this.showDialog = false;
        }
      },
      deep: true,
    },
    showDialog(neu) {
      if (neu === false) {
        this.editanw = {
          id: -1,
        };
      } else {
        this.neuAnw();
      }
    },
  },
  methods: {
    neuAnw() {
      if (this.editanw.id === 0) {
        const datum = new Date();
        const yyyy = datum.getFullYear();
        const mm = (datum.getMonth() + 1).toString().padStart(2, '0');
        const dd = datum.getDate().toString().padStart(2, '0');
        const prueflinge = [];
        this.prueflinge.forEach((p) => {
          let neuStatus = parseInt(this.$store.state.f150.store.settings.standard_status, 10);
          if (p.beurlaubt) {
            neuStatus = 0;
          }
          prueflinge.push({
            id: p.id,
            name: p.ident,
            status: neuStatus,
          });
        });
        // console.log(prueflinge);
        this.editanw = {
          id: 0,
          id_slot: this.prop_idslot,
          datum: `${yyyy}-${mm}-${dd}`,
          titel: '',
          beschreibung: '',
          gaeste: '',
          status: 'offen',
          geschlossen: false,
          prueflinge,
        };
      }
    },
    delAnw(editanw) {
      this.$store.dispatch('f150/tryGetOrSaveSettings', {
        dataToSave: {
          type: 'delanw',
          data: { delanw: editanw },
          texte_success: 'Anwesenheit erfolgreich gelöscht.',
        },
      });
    },
    switchPrueflingStatus(p) {
      if (
        (
          this.bedingungen.anwbearbeiten.nutzer
          || this.bedingungen.bearbeiten.nutzer
          || (
            this.bedingungen.anwhinzufuegen.nutzer
            && !this.editanw.geschlossen
          )
        )
        && !this.editanw.geschlossen
      ) {
        // Wenn die ABfrage offen ist, kann sie noch bearbeitet werden
        let { status } = p;
        status += 1;
        if (status > this.moeglicheStatus.length - 1) {
          status = 0;
        }
        p.status = status;
        /* this.$store.commit('main/alert', {
          typ: this.moeglicheStatus.filter((o) => o.nummer === status)[0].farbe,
          text: `${p.name} ${this.moeglicheStatus.filter((o) => o.nummer === status)[0].text}`,
        }, { root: true }); */
      } else {
        // Wenn die Abfrage geschlossen ist, können nur Infos abgerufen werden.
        this.$store.commit('main/alert', {
          typ: this.moeglicheStatus.filter((o) => o.nummer === p.status)[0].farbe,
          text: `${p.name} ${this.moeglicheStatus.filter((o) => o.nummer === p.status)[0].text}`,
        }, { root: true });
      }
    },
    downloadQRCode() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/100/150/?getQR-Code`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          id: this.editanw.id,
          type: 'slots_anwesenheiten', // slots_anwesenheiten
        },
        success: () => {
          // Erstelltes PDF downloaden:
          $.ajax({
            url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
            method: 'POST',
            data: {
              code: this.$store.state.login.session.code,
              file: `f100-150/qr_f150_addAnwToSlot_saa_${this.editanw.id}.pdf`,
            },
            xhrFields: {
              responseType: 'blob',
            },
            success: (data) => {
              const a = document.createElement('a');
              const url = window.URL.createObjectURL(data);
              a.href = url;
              a.download = `QR-Code-${this.editanw.titel}.pdf`;
              document.body.append(a);
              a.click();
              a.remove();
              window.URL.revokeObjectURL(url);
            },
            error: () => {
              this.$store.commit('main/alert', {
                typ: 'error',
                text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
              }, { root: true });
            },
            complete: () => {
              this.$store.commit('main/switchIsSaving', false);
            },
          });
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht erzeugt werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
        complete: () => {
        },
      });
    },
  },
  created() {
  },
};
</script>
