<template>
  <v-container class="ma-0 pa-4 pt-0">
    <v-dialog
      scrollable
      :fullscreen="$vuetify.breakpoint.xs"
      v-model="dialog_edit"
      max-width="1000"
    >
      <template v-slot:default="">
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            <span
              v-if="newsEdit.titel == ''"
            >
              Neuen Beitrag erstellen
            </span>
            <span
              v-if="newsEdit.titel != ''"
            >
              {{ newsEdit.titel }}
            </span>
          </v-toolbar>
          <v-card-text
            class="pa-2 ma-0"
            style="height:100%;overflow-x:hidden;"
          >
            <v-form v-model="formValid_editNews">
              <v-tabs
                fixed-tabs
                v-model="tabs_inhalteEinstellungen"
                class="d-md-none mb-6"
              >
                <v-tab>Inhalt</v-tab>
                <v-tab>Einstellungen</v-tab>
              </v-tabs>
              <v-row>
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    tabs_inhalteEinstellungen === 0 ||
                    $vuetify.breakpoint.mdAndUp
                  "
                >
                  <v-container
                    class="text-title text--secondary text-uppercase text-center d-none d-md-block"
                  >
                    Meldungsinhalt
                  </v-container>
                  <v-row>
                    <v-col cols="12">
                      <v-select
                        label="Meldungstyp"
                        :items="typen"
                        item-text="name"
                        item-value="id"
                        v-model="newsEdit.typ"
                      />
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        v-if="dialog_edit"
                        label="Betreff"
                        v-model="newsEdit.titel"
                        :rules="[
                          $store.state.main.inputValidationRules.required,
                        ]"
                      ></v-text-field>
                    </v-col>
                  </v-row>
                  <v-textarea
                    v-if="dialog_edit"
                    auto-grow
                    label="Text"
                    v-model="newsEdit.text"
                    hint=""
                    :rules="[
                      $store.state.main.inputValidationRules.required,
                    ]"
                  ></v-textarea>
                  <v-checkbox
                    v-model="newsEdit.rueckmeldung.nutzen"
                    label="Rückmeldung aktivieren"
                  />
                  <v-container
                    v-if="newsEdit.rueckmeldung.nutzen"
                  >
                    <v-row
                      v-for="(form, idx) in newsEdit.rueckmeldung.formularfelder"
                      :key="idx"
                    >
                      <v-col>
                        <v-card class="pa-2">
                          <v-row align="center" class="ma-0 pa-0">
                            <v-col cols="12" class="ma-0 pa-0" align="right">
                              <v-btn x-small
                              @click="newsEdit.rueckmeldung.formularfelder.splice(idx, 1)"
                              >
                                <v-icon small left>mdi-delete</v-icon>
                                löschen
                              </v-btn>
                            </v-col>
                            <v-col class="ma-0 pa-0">
                              <v-select
                                :items="$store.state.main.save.nutzerparameterTypen"
                                item-text="name"
                                item-value="id"
                                v-model="form.typ"
                                :disabled="
                                  newsEdit.rueckmeldung.users.filter((o) => {
                                    if (
                                      o.nutzerparameterwerte[form.id]
                                      || o.nutzerparameterwerteSelectoptionIDs[form.id]
                                    ) {
                                      return o;
                                    }
                                    return null;
                                  }).length > 0
                                "
                              />
                              <div
                                v-if="
                                  newsEdit.rueckmeldung.users.filter((o) => {
                                    if (
                                      o.nutzerparameterwerte[form.id]
                                      || o.nutzerparameterwerteSelectoptionIDs[form.id]
                                    ) {
                                      return o;
                                    }
                                    return null;
                                  }).length > 0
                                "
                                style="font-size:80%;" class="pb-4 error--text"
                              >
                                Der Typ kann nicht geändert werden, wenn bereits Werte
                                durch Nutzer zurückgemeldet wurden.
                                </div>
                            </v-col>
                          </v-row>
                          <div
                            align="center"
                            v-if="
                              form.typ === '9'
                              || form.typ === '10'
                            "
                          >
                          <v-row
                            v-for="(option, idx) in form.selectoptions"
                            :key="idx"
                            align="center"
                            class="px-4"
                            >
                              <v-col cols="2" class="py-0">
                                <v-text-field
                                type="number"
                                label="#"
                                v-model="option.sort"
                                :rules="[
                                  $store.state.main.inputValidationRules.required,
                                ]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="8" class="py-0">
                                <v-text-field
                                type="text"
                                label="Wert"
                                v-model="option.wert"
                                :rules="[
                                  $store.state.main.inputValidationRules.required,
                                  $store.state.main.inputValidationRules.tinytext,
                                  $store.state.main.inputValidationRules.name,
                                ]"
                                ></v-text-field>
                              </v-col>
                              <v-col cols="2" class="py-0">
                                <v-btn x-small block
                                @click="form.selectoptions.splice(idx, 1)"
                                >
                                  <v-icon small>mdi-delete</v-icon>
                                </v-btn>
                              </v-col>
                            </v-row>
                            <v-btn
                            x-small
                            @click="form.selectoptions
                            .push({
                              id: 0,
                              id_formularfeld: form.id,
                              sort: form.selectoptions.length + 1,
                              wert: `Option ${(form.selectoptions.length + 1)}`,
                            })"
                            >
                              <v-icon
                              left small
                              >mdi-plus-box</v-icon>
                              Option hinzufügen
                            </v-btn>
                          </div>
                          <v-text-field
                            v-model="form.name"
                            label="Name"
                          />
                          <v-textarea
                            auto-grow
                            v-model="form.beschreibung"
                            label="Beschreibung"
                          />
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-container class="d-flex justify-center">
                      <v-btn
                        x-small
                        @click="addFormularfeld();"
                      >
                        <v-icon small left>mdi-plus-box</v-icon>
                        Neues Formularfeld
                      </v-btn>
                    </v-container>
                  </v-container>
                </v-col>
                <v-col
                  cols="12"
                  md="6"
                  v-if="
                    tabs_inhalteEinstellungen === 1 ||
                    $vuetify.breakpoint.mdAndUp
                  "
                >
                  <v-container
                    class="text-title text--secondary text-uppercase text-center d-none d-md-block"
                  >
                    Einstellungen
                  </v-container>
                  <v-row>
                    <v-col class="py-2">
                      <v-checkbox
                        v-model="newsEdit.anzeigePlanen"
                        label="Anzeige planen"
                      ></v-checkbox>
                    </v-col>
                  </v-row>
                  <v-row v-if="newsEdit.anzeigePlanen">
                    <p>
                      Geplante Meldungen können nach dem Speicher nicht
                      mehr verändert und bei Bedarf nur ganz gelöscht
                      werden.
                    </p>
                    <v-col class="pr-14">
                      <DatePicker
                        label="Anzeigen von"
                        icon=""
                        v-model="newsEdit.von_date"
                      />
                    </v-col>
                    <v-col cols="4">
                      <TimePicker
                        label=""
                        icon=""
                        v-model="newsEdit.von_time"
                      />
                    </v-col>
                  </v-row>
                  <v-row v-if="newsEdit.anzeigePlanen">
                    <v-col class="pr-14">
                      <DatePicker
                        label="Anzeigen bis"
                        icon=""
                        v-model="newsEdit.bis_date"
                      />
                    </v-col>
                    <v-col cols="4">
                      <TimePicker
                        label=""
                        icon=""
                        v-model="newsEdit.bis_time"
                      />
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col>
                      <p
                        class="text-title text--secondary"
                      >
                        Für wen gilt diese Meldung?
                      </p>
                      <p
                        class="text-caption text--secondary"
                      >
                        Wem soll diese Meldung angezeigt werden?
                      </p>
                      <v-checkbox
                        v-model="newsEdit.bedingung_f000w1_sehen.alle"
                        label="Jeder kann sehen"
                        @change="getParameterToSendMail();"
                      ></v-checkbox>
                      <SelectNutzergruppen
                        v-if="!newsEdit.bedingung_f000w1_sehen.alle"
                        v-model="newsEdit.bedingung_f000w1_sehen.gruppen"
                        label="Nur bestimmte können sehen:"
                        @change="getParameterToSendMail();"
                      />
                      <div
                        v-if="
                          prop_settings.w1_200_Elterngruppe !== ''
                          && newsEdit.bedingung_f000w1_sehen.gruppen
                            .includes(prop_settings.w1_200_Elterngruppe)
                          && prop_settings.w1_200_Klassen.length > 0
                        "
                      >
                        <p>
                          Als Empfänger der Meldung wurden
                          die Eltern der Kinder ausgewählt.
                          Sollen diese nach den jeweiligen
                          Klassen der Kinder gefiltert werden?
                        </p>
                        <v-select
                          multiple
                          label="Nur Eltern bestimmter Klassen wählen:"
                          :items="prop_settings.w1_200_Klassen"
                          v-model="newsEdit.f200_filterElternKlassen"
                          item-text="name"
                          item-value="id"
                        >
                          <template v-slot:selection="data">
                            <span
                              v-if="data.index === 0"
                            >
                              <span
                                class="grey--text text-caption"
                                v-for="(k, idx) in f200_filterElternKlassen_sort"
                                :key="idx"
                              >
                                <v-chip x-small
                                  close
                                  :input-value="k"
                                  @click:close="
                                    removeAngezeigteKlasse(
                                      prop_settings.w1_200_Klassen
                                        .filter((o) => o.id === k)[0].id
                                    );
                                  "
                                >{{ prop_settings.w1_200_Klassen
                                  .filter((o) => o.id === k)[0].name }}</v-chip>
                              </span>
                            </span>
                          </template>
                        </v-select>
                      </div>
                      <div>
                        <v-checkbox
                          v-model="newsEdit.sendMail"
                          label="Per E-Mail senden"
                          @change="getParameterToSendMail();"
                        ></v-checkbox>
                        <div
                          v-if="newsEdit.sendMailParameter"
                        >
                          <div
                            v-if="
                              newsEdit.sendMail
                              && newsEdit.sendMailParameter.length < 1
                            "
                          >
                            <v-alert
                              border="bottom"
                              colored-border
                              type="error"
                              elevation="2"
                            >
                              <b>Achtung!</b> Die Information kann nicht per E-Mail gesendet werden,
                              da die ausgewählten Gruppen keine Parameter vom typ
                              "E-Mail" besitzen.
                            </v-alert>
                          </div>
                          <div
                            v-if="
                              newsEdit.sendMail
                              && newsEdit.sendMailParameter.length === 1
                            "
                          >
                            <p>
                              Die Information wird an alle Adressen der Benutzer
                              der ausgewählten Gruppen gesendet,
                              die für den Parameter
                              "<b>{{ newsEdit.sendMailParameter[0].name }}</b>"
                              einen gültigen Wert definiert haben.
                            </p>
                          </div>
                          <div
                            v-if="
                              newsEdit.sendMail
                              && newsEdit.sendMailParameter.length > 1
                            "
                          >
                            <p>
                              Die Information wird an alle Adressen der Benutzer
                              der ausgewählten Gruppen gesendet,
                              die in den folgenden Parametern definiert wurden:
                            </p>
                            <div
                              v-for="parameter in newsEdit.sendMailParameter"
                              :key="parameter.id"
                            >
                              <v-checkbox
                                v-model="parameter.checked"
                                :label="parameter.name"
                                class="ma-0 ms-4"
                              ></v-checkbox>
                            </div>
                          </div>
                        </div>
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-form>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="dialog_edit = false"
            >Schließen</v-btn>
            <v-spacer />
            <v-btn
              text
              color="primary"
              @click="
                saveNews('saveMeldung', newsEdit);
              "
              :disabled="
                !formValid_editNews
                || (
                  `${newsEdit.von_date} ${newsEdit.von_time}`
                  > `${newsEdit.bis_date} ${newsEdit.bis_time}`
                  && newsEdit.anzeigePlanen
                )
                || newsEdit.titel === ''
                || newsEdit.text === ''
              "
              :loading="loading.saveMeldung"
            >Speichern</v-btn>
          </v-card-actions>
        </v-card>
      </template>
    </v-dialog>
    <v-row class="mb-2"
      v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
    >
      <v-col align="left">
        <v-btn
          x-small
          color="primary"
          @click="editNews(neuNews);"
        >
          <v-icon small left>mdi-plus-box</v-icon>
          Meldung
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="my-0"
      v-if="prop_widget.meldungen.length <= 0"
    >
      <v-col align="center" class="py-0">
        <p
          class="text-title text--secondary my-0"
        >
          Keine aktuellen Meldungen
        </p>
      </v-col>
    </v-row>
    <v-alert
      border="bottom"
      colored-border
      elevation="2"
      v-for="meldung in meldungen"
      :key="meldung.id"
      :class="{'hide': (
        meldung.anzeigePlanen
        &&
        (
          timestampNow - meldung.von_timestamp < 0
          || (
            meldung.bis_timestamp - timestampNow < 0
            && meldung.bis_timestamp > 0
          )
        )
      )}"
      :icon="meldung.icon"
      :color="meldung.color"
    >
      <v-row align="center">
        <v-col
          v-if="!prop_settings.w1_bedingung_meldungErstellen_nutzer"
        >
          <h3>{{ meldung.titel }} </h3>
          <p v-html="meldung.textNl2br"></p>
        </v-col>
        <v-col
          v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
          cols="10" sm="11"
        >
          <h3>{{ meldung.titel }}</h3>
          <p v-html="meldung.textNl2br"></p>
        </v-col>
        <v-col
          v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
          cols="2"
          sm="1"
          align="center"
        >
          <v-row>
            <v-col>
              <v-dialog
                v-model="dialogs_delNews[meldung.id]"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    icon
                    x-small
                    class="my-2"
                    v-bind="attrs"
                    v-on="on"
                  >
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Sicher?
                  </v-card-title>

                  <v-card-text class="pt-8">
                    <p>Die Meldung <b>{{ meldung.titel }}</b> unwiderruflich löschen?</p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      text
                      @click="dialogs_delNews[meldung.id] = false"
                    >
                      Nichts tun
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="error"
                      text
                      @click="
                        saveNews('delMeldung', meldung.id);
                      "
                      :loading="loading.delMeldung"
                    >
                      Löschen
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-btn
                icon
                x-small
                class="my-2"
                @click="editNews(meldung);"
              >
                <v-icon>mdi-pencil-outline</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
      <div
        v-if="meldung.rueckmeldung.nutzen"
      >
        <div align="right">
          <Widget1NewsfeedRueckmeldungsergebnisse
            v-if="prop_settings.w1_bedingung_meldungErstellen_nutzer"
            :prop_meldung="meldung"
            @refresh="refreshUsers(meldung.id, $event);"
          />
        </div>
        <v-card class="my-4 pt-2 pa-6"
          v-if="
            meldung.rueckmeldung.user.kannAendern
            || !prop_settings.w1_bedingung_meldungErstellen_nutzer
          "
        >
          <EditUserParameter
            :user="meldung.rueckmeldung.user"
            :parameter="meldung.rueckmeldung.formularfelder"
            :disabled="!meldung.rueckmeldung.user.kannAendern"
          />
        </v-card>
        <div class="d-flex justify-center"
          v-if="meldung.rueckmeldung.user.kannAendern"
        >
          <v-btn x-small
            @click="
              saveNews(
                'saveRueckmeldung',
                { meldung, kinder: $store.state.main.save.user.f200.kinder },
              );
            "
            :loading="loading.saveRueckmeldung"
          >
            Angaben abschicken
          </v-btn>
        </div>
      </div>
      <v-row class="ma-0">
        <v-col class="pa-0">
          <span v-if="
            meldung.anzeigePlanen
            && prop_settings.w1_bedingung_meldungErstellen_nutzer
          "
            style="font-size:70%;"
          >
            {{ new Date(meldung.von_date).toLocaleString('de-DE', {
              year: 'numeric', month: '2-digit', day: '2-digit'
            }) }}
            {{ meldung.von_time }}
            -
            <span v-if="
              meldung.von_date !== meldung.bis_date
            ">
              {{ new Date(meldung.bis_date).toLocaleString('de-DE', {
                year: 'numeric', month: '2-digit', day: '2-digit'
              }) }}
            </span>
            {{ meldung.bis_time }}
            <span v-if="
              meldung.sendmailsettings
            ">
              [ Sende per E-Mail ]
            </span>
          </span>
        </v-col>
      </v-row>
    </v-alert>
  </v-container>
</template>

<script>
import $ from 'jquery';
import TimePicker from '@/components/Inputs/TimePicker.vue';
import DatePicker from '@/components/Inputs/DatePicker.vue';
import SelectNutzergruppen from '@/components/Inputs/SelectNutzergruppen.vue';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';
import Widget1NewsfeedRueckmeldungsergebnisse from '@/components/Funktionen/000/000-Dashboard/Widgets/Widget1NewsfeedRueckmeldungsergebnisse.vue';

export default {
  components: {
    TimePicker,
    DatePicker,
    SelectNutzergruppen,
    EditUserParameter,
    Widget1NewsfeedRueckmeldungsergebnisse,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_widget: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    timestampNow: 0,
    tabsRuckmeldungFormularErgebnisse: {},
    tabs_inhalteEinstellungen: null,
    dialog_edit: false,
    formValid_editNews: true,
    dialogs_delNews: {},
    loading: {
      delMeldung: false,
      saveMeldung: false,
      saveRueckmeldung: false,
    },
    typen: [
      { id: 'info', name: 'Information' },
      { id: 'warning', name: 'Warnung mittlere Priorität' },
      { id: 'error', name: 'Warnung hohe Priorität' },
      { id: 'success', name: 'Gute Nachricht' },
      { id: 'ansage', name: 'Ansage' },
      { id: 'party', name: 'Party' },
    ],
    newsEdit: {},
    neuNews: {
      id: 0,
      typ: 'info',
      titel: '',
      text: '',
      anzeigePlanen: false,
      von_timestamp: 0,
      bis_timestamp: -1,
      bedingung_f000w1_sehen: {
        alle: true,
        gruppen: [],
        nutzer: true,
      },
      sendMail: false,
      sendMailParameter: [],
      f200_filterElternKlassen: [],
      rueckmeldung: {
        nutzen: false,
        formularfelder: [],
        users: [],
      },
    },
  }),
  computed: {
    f200_filterElternKlassen_sort() {
      const help = [];
      const alleKlassen = JSON.parse(JSON.stringify(this.prop_settings.w1_200_Klassen));
      alleKlassen.sort((a, b) => {
        if (a.name > b.name) {
          return 1;
        }
        if (b.name > a.name) {
          return -1;
        }
        return 0;
      });
      alleKlassen.forEach((k) => {
        if (this.newsEdit.f200_filterElternKlassen.includes(k.id)) {
          help.push(k.id);
        }
      });
      return help;
    },
    meldungen() {
      const help = JSON.parse(JSON.stringify(this.prop_widget.meldungen));
      help.sort((a, b) => {
        if (parseInt(a.id, 10) > parseInt(b.id, 10)) {
          return -1;
        }
        if (parseInt(b.id, 10) > parseInt(a.id, 10)) {
          return 1;
        }
        return 0;
      });
      return help;
    },
  },
  watch: {
    /* dialog_edit(neu) {
      if (
        neu
        && this.newsEdit.sendmailsettings
      ) {
        console.log(this.newsEdit.sendmailsettings);
        this.newsEdit.sendMail = true;
        this.newsEdit.sendMailParameter = this.newsEdit.sendmailsettings.parameter;
      }
    }, */
    prop_meldung(neu) {
      console.log(neu);
    },
    newsEdit: {
      handler(neu) {
        if (`${neu.von_date} ${neu.von_time}` > `${neu.bis_date} ${neu.bis_time}`) {
          neu.bis_date = neu.von_date;
          neu.bis_time = neu.von_time;
        }
      },
      deep: true,
    },
  },
  methods: {
    refreshUsers(meldungId, users) {
      if (this.prop_widget.meldungen.filter((o) => o.id === meldungId).length > 0) {
        this.prop_widget.meldungen
          .filter((o) => o.id === meldungId)[0]
          .rueckmeldung.users = users;
        if (users.filter((o) => o.id === this.$store.state.main.save.user.id)[0]) {
          [this.prop_widget.meldungen
            .filter((o) => o.id === meldungId)[0]
            .rueckmeldung.user] = users.filter((o) => o.id === this.$store.state.main.save.user.id);
        }
      }
    },
    addFormularfeld() {
      this.newsEdit.rueckmeldung.formularfelder.push({
        id: 0,
        sort: 0,
        name: '',
        beschreibung: '',
        typ: this.$store.state.main.save.nutzerparameterTypen[0].id,
        selectoptions: [],
      });
      // console.log(this.newsEdit.rueckmeldung.formularfelder);
    },
    removeAngezeigteKlasse(id) {
      this.newsEdit.f200_filterElternKlassen
        .splice(this.newsEdit.f200_filterElternKlassen
          .indexOf(this.newsEdit.f200_filterElternKlassen
            .filter((o) => o === id)[0]), 1);
    },
    getParameterToSendMail() {
      // console.log(this.newsEdit.bedingung_f000w1_sehen);
      const gruppen = this.$store.state.main.save.nutzergruppen
        .filter((o) => {
          if (
            this.newsEdit.bedingung_f000w1_sehen.gruppen.includes(o.id)
            || this.newsEdit.bedingung_f000w1_sehen.alle === true
          ) {
            return o;
          }
          return null;
        });
      const mailparameterGesamt = this.$store.state.main.save.nutzerparameter
        .filter((o) => o.typ === '4');
      const mailparameter = [];
      gruppen.forEach((g) => {
        g.nutzerparameter.forEach((p) => {
          if (
            mailparameterGesamt.filter((o) => o.id === p).length > 0
            && mailparameter.filter((o) => o.id === p).length <= 0
          ) {
            mailparameter.push({
              id: mailparameterGesamt.filter((o) => o.id === p)[0].id,
              name: mailparameterGesamt.filter((o) => o.id === p)[0].name,
              checked: true,
            });
          }
        });
      });
      this.newsEdit.sendMailParameter = mailparameter;
    },
    standardEmpfaenger() {
      if (this.prop_settings) {
        if (this.prop_settings.w1_standardEmpfaenger !== '') {
          this.neuNews.bedingung_f000w1_sehen.alle = false;
          this.neuNews.bedingung_f000w1_sehen.gruppen = this.prop_settings.w1_standardEmpfaenger.split('-');
        }
      }
    },
    editNews(getNews) {
      const news = JSON.parse(JSON.stringify(getNews));
      news.anzeigePlanen = true;
      if (
        news.bis_timestamp < 0
        || news.id === 0
      ) {
        news.anzeigePlanen = false;
      }
      if (!news.anzeigePlanen) {
        const heute = new Date();
        const timestamp = heute.getTime();
        const dd = String(heute.getDate()).padStart(2, '0');
        const mm = String(heute.getMonth() + 1).padStart(2, '0'); // January is 0!
        const yyyy = heute.getFullYear();
        const H = String(heute.getHours()).padStart(2, '0');
        const i = String(heute.getMinutes()).padStart(2, '0');
        news.von_timestamp = timestamp;
        news.von = `${yyyy}-${mm}-${dd} ${H}:${i}`;
        news.von_date = `${yyyy}-${mm}-${dd}`;
        news.von_time = `${H}:${i}`;
        news.von_german = `${dd}.${mm}.${yyyy} ${H}:${i}`;
        news.bis_timestamp = timestamp;
        news.bis = `${yyyy}-${mm}-${dd} ${H}:${i}`;
        news.bis_date = `${yyyy}-${mm}-${dd}`;
        news.bis_time = `${H}:${i}`;
        news.bis_german = `${dd}.${mm}.${yyyy} ${H}:${i}`;
      }
      this.newsEdit = JSON.parse(JSON.stringify(news));
      this.dialog_edit = true;
    },
    saveNews(func, news) {
      let neuNews = JSON.parse(JSON.stringify(news));
      let kinder = [];
      if (func === 'saveRueckmeldung') {
        kinder = news.kinder;
        neuNews = news.meldung;
      }
      this.trySaveOrEditNews({
        func,
        data: {
          meldung: neuNews,
          kinder,
        },
      });
    },
    async trySaveOrEditNews(dataToSave) {
      if (dataToSave) {
        this.$store.commit('main/switchIsSaving', true);
        this.loading[dataToSave.func] = true;
        // console.log(dataToSave);
      }
      await $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/000/000/?func=w1`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dataToSave: JSON.stringify(dataToSave),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            const antwort = JSON.parse(JSON.stringify(response));
            if (dataToSave.func === 'saveMeldung' || dataToSave.func === 'saveRueckmeldung') {
              if (dataToSave.func === 'saveRueckmeldung') {
                antwort.savedMeldung.rueckmeldung.user.kannAendern = false;
                dataToSave.data.meldung.rueckmeldung.user.kannAendern = false;
                this.prop_widget.meldungen
                  .filter((o) => o.id === antwort.savedMeldung.id)[0]
                  .rueckmeldung.user.kannAendern = false;
              }
              if (dataToSave.data.meldung.id > 0) {
                this.prop_widget.meldungen.splice(
                  this.prop_widget.meldungen.indexOf(
                    this.prop_widget.meldungen
                      .filter((o) => o.id === dataToSave.data.meldung.id)[0],
                  ), 1,
                );
              }
              this.prop_widget.meldungen.push(antwort.savedMeldung);
              this.dialog_edit = false;
            }
            if (dataToSave.func === 'delMeldung') {
              this.prop_widget.meldungen
                .splice(this.prop_widget.meldungen.indexOf(dataToSave.data.meldung), 1);
            }
            const help = JSON.parse(localStorage.getItem('f000'));
            help.save.widgets[1].meldungen = JSON.parse(JSON.stringify(this.prop_widget.meldungen));
            localStorage.setItem('f000', JSON.stringify(help));
          }
        },
        timeout: 10000,
        error: () => {
          // Einstellungen konnten nicht geladen werden
          if (dataToSave) {
            this.$store.commit('main/alert', {
              typ: 'error',
              text: `Die Einstellungen konnten nicht gespeichert werden.
              Bitte die Netzwerkverbindung prüfen.`,
            }, { root: true });
          }
        },
        complete: () => {
          if (dataToSave) {
            this.$store.commit('main/switchIsSaving', false);
            this.loading[dataToSave.func] = false;
            this.dialogs_delNews = {};
          }
        },
      });
    },
  },
  created() {
    this.standardEmpfaenger();
    const heute = new Date();
    this.timestampNow = heute.getTime() / 1000;
  },
};
</script>
<style scoped>
  .hide {opacity:0.5;}
</style>
