var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-dialog',{attrs:{"fullscreen":"","scrollable":"","transition":"dialog-bottom-transition","width":"1200"},model:{value:(_vm.dialog_editKosten),callback:function ($$v) {_vm.dialog_editKosten=$$v},expression:"dialog_editKosten"}},[_c('v-card',[_c('v-card-title',{staticClass:"primary white--text",staticStyle:{"font-size":"100%"}},[_c('v-container',{staticClass:"pa-0 d-flex justify-end"},[_vm._v(" "+_vm._s(_vm.editKosten.titel)+" "),_c('v-spacer'),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","dark":"","loading":_vm.prop_isSaving_delKosten}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}]),model:{value:(_vm.dialog_delKosten),callback:function ($$v) {_vm.dialog_delKosten=$$v},expression:"dialog_delKosten"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Kosten löschen? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Es werden sämtliche Daten und Dokumente gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){_vm.dialog_delKosten = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog_delKosten = false;
                  _vm.$emit('del', _vm.editKosten.id);}}},[_vm._v(" löschen ")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","dark":""},on:{"click":function($event){_vm.dialog_editKosten = false;}}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1)],1),_c('v-card-text',{staticClass:"pt-4"},[(_vm.$vuetify.breakpoint.smAndDown)?_c('v-tabs',{staticClass:"mb-2",attrs:{"fixed-tabs":"","show-arrows":"","color":"primary","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"},model:{value:(_vm.tabs_kostendaten_mobile),callback:function ($$v) {_vm.tabs_kostendaten_mobile=$$v},expression:"tabs_kostendaten_mobile"}},[_c('v-tabs-slider'),_c('v-tab',[_vm._v("Info")]),_c('v-tab',[_vm._v("Dokumente")])],1):_vm._e(),_c('v-row',[(
            _vm.tabs_kostendaten_mobile === 0 ||
            _vm.$vuetify.breakpoint.mdAndUp
          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-container',{staticClass:"pa-0"},[_c('DatePicker',{model:{value:(_vm.editKosten.datum),callback:function ($$v) {_vm.$set(_vm.editKosten, "datum", $$v)},expression:"editKosten.datum"}}),_c('v-select',{attrs:{"items":_vm.prop_settings.kostenstellen,"item-text":"name","item-value":"nummer","label":"Kostenstelle"},model:{value:(_vm.editKosten.kostenstelle),callback:function ($$v) {_vm.$set(_vm.editKosten, "kostenstelle", $$v)},expression:"editKosten.kostenstelle"}}),_c('v-row',[_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"Wert [€]","rules":[
                    _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.editKosten.betrag),callback:function ($$v) {_vm.$set(_vm.editKosten, "betrag", $$v)},expression:"editKosten.betrag"}})],1),_c('v-col',[_c('v-text-field',{attrs:{"type":"number","label":"enthaltene MwSt [%]","rules":[
                    _vm.$store.state.main.inputValidationRules.nummer ]},model:{value:(_vm.editKosten.mwst),callback:function ($$v) {_vm.$set(_vm.editKosten, "mwst", $$v)},expression:"editKosten.mwst"}})],1)],1),_c('v-text-field',{attrs:{"type":"text","label":"Titel","rules":[
                _vm.$store.state.main.inputValidationRules.name ]},model:{value:(_vm.editKosten.titel),callback:function ($$v) {_vm.$set(_vm.editKosten, "titel", $$v)},expression:"editKosten.titel"}}),_c('v-textarea',{attrs:{"auto-grow":"","label":"Beschreibung","hint":""},model:{value:(_vm.editKosten.beschreibung),callback:function ($$v) {_vm.$set(_vm.editKosten, "beschreibung", $$v)},expression:"editKosten.beschreibung"}})],1)],1):_vm._e(),(
            _vm.tabs_kostendaten_mobile === 1 ||
            _vm.$vuetify.breakpoint.mdAndUp
          )?_c('v-col',{attrs:{"cols":"12","md":"6"}},[_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
          var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus-box")]),_vm._v(" hochladen ")],1)]}}],null,false,2619441739),model:{value:(_vm.dialog_neuDokument),callback:function ($$v) {_vm.dialog_neuDokument=$$v},expression:"dialog_neuDokument"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Dokument hochladen ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('UploadBilderPDF',{attrs:{"apipath":("400/410/kosten_upload.php?key=" + (this.$store.state.login.session.code) + "&fill=\n                    &id_k=" + (_vm.editKosten.id)),"accept":"application/pdf, image/*"},on:{"neuDokumente":function($event){return _vm.addDokumente($event);}}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_neuDokument = false;}}},[_vm._v(" Schließen ")]),_c('v-spacer')],1)],1)],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.editKosten.dokumente,"search":_vm.search,"loading":_vm.loading,"items-per-page":10,"header-props":_vm.headerProps,"footer-props":_vm.footerProps,"no-data-text":"Keine Dokumente gefunden","no-results-text":"Keine Dokumente gefunden","loading-text":"Lade Daten... Bitte warten","mobile-breakpoint":"600"},scopedSlots:_vm._u([{key:"footer.page-text",fn:function(ref){
                    var pageStart = ref.pageStart;
                    var pageStop = ref.pageStop;
                    var itemsLength = ref.itemsLength;
return [_vm._v(" "+_vm._s(pageStart)+" - "+_vm._s(pageStop)+" von "+_vm._s(itemsLength)+" ")]}},{key:"item.dokumentart",fn:function(ref){
                    var item = ref.item;
return [(
                  item.dokumentart === '1'
                  || item.dokumentart === '2'
                )?_c('v-icon',{attrs:{"left":"","color":"red"}},[_vm._v("mdi-file-pdf-box")]):_vm._e(),(item.dokumentart === '1')?_c('span',[_vm._v("Rechnung")]):_vm._e(),(item.dokumentart === '2')?_c('span',[_vm._v("Angebot")]):_vm._e(),(
                  item.dokumentart === '20'
                )?_c('v-icon',{attrs:{"left":"","color":"success"}},[_vm._v("mdi-receipt-text-check-outline")]):_vm._e(),(item.dokumentart === '20')?_c('span',[_vm._v("Buchungsbeleg")]):_vm._e(),(
                  item.dokumentart === '100'
                )?_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-upload")]):_vm._e(),(item.dokumentart === '100')?_c('span',[_vm._v("Hochgeladen")]):_vm._e()]}},{key:"item.aktion",fn:function(ref){
                var item = ref.item;
return [_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"primary"}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true),model:{value:(_vm.dialog_editDokument[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog_editDokument, item.id, $$v)},expression:"dialog_editDokument[item.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Dokument bearbeiten ")]),_c('v-card-text',{staticClass:"pt-4"},[_c('v-select',{attrs:{"label":"Art des Dokuments","items":_vm.prop_settings.auftraegeDokumentarten
                        .filter(function (da) { return da.fuerKosten === '1'; }),"item-text":"name","item-value":"nummer"},model:{value:(item.dokumentart),callback:function ($$v) {_vm.$set(item, "dokumentart", $$v)},expression:"item.dokumentart"}}),_c('DatePicker',{on:{"change":function($event){return _vm.changeDokumentDatum(item);}},model:{value:(item.dateUpload2),callback:function ($$v) {_vm.$set(item, "dateUpload2", $$v)},expression:"item.dateUpload2"}}),_c('v-text-field',{attrs:{"type":"text","label":"Titel","rules":[
                        _vm.$store.state.main.inputValidationRules.name ]},model:{value:(item.name),callback:function ($$v) {_vm.$set(item, "name", $$v)},expression:"item.name"}})],1),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_editDokument[item.id] = false;}}},[_vm._v(" Schließen ")]),_c('v-spacer'),_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.dialog_editDokument[item.id] = false;}}},[_vm._v(" OK ")])],1)],1)],1),_c('v-btn',{attrs:{"text":"","color":"primary","loading":item.loadingDownload},on:{"click":function($event){return _vm.tryDownloadDokument(item.pfad, item.name);}}},[_c('v-icon',[_vm._v("mdi-download")])],1),_c('v-dialog',{attrs:{"width":"500"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                      var on = ref.on;
                      var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"text":"","color":"error","loading":item.loadingDelete}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-delete")])],1)]}}],null,true),model:{value:(_vm.dialog_delDokument[item.id]),callback:function ($$v) {_vm.$set(_vm.dialog_delDokument, item.id, $$v)},expression:"dialog_delDokument[item.id]"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5 grey lighten-2"},[_vm._v(" Dokument löschen? ")]),_c('v-card-text',{staticClass:"pt-4"},[_vm._v(" Das Dokument wird unwiderruflich gelöscht. ")]),_c('v-divider'),_c('v-card-actions',[_c('v-btn',{attrs:{"text":""},on:{"click":function($event){_vm.dialog_delDokument[item.id] = false;}}},[_vm._v(" Nichts tun ")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"error","text":""},on:{"click":function($event){_vm.dialog_delDokument[item.id] = false;
                        _vm.tryDelDokument(item.id, item.pfad);}}},[_vm._v(" löschen ")])],1)],1)],1)]}}],null,false,1799968033)})],1):_vm._e()],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-container',{staticClass:"pa-0 d-flex justify-end"},[_c('v-btn',{attrs:{"text":"","small":""},on:{"click":function($event){_vm.dialog_editKosten = false;}}},[_vm._v(" abbrechen ")]),_c('v-btn',{attrs:{"color":"primary","text":"","small":"","disabled":_vm.xLoaded === JSON.stringify(_vm.editKosten),"loading":_vm.prop_isSaving_editKosten},on:{"click":function($event){return _vm.$emit('save', _vm.editKosten);}}},[_vm._v(" Speichern ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }