<template>
    <div>
      <v-row no-gutters justify="center" align="center">
        <v-col class="py-0">
          <v-file-input
            multiple
            show-size
            label="Datei wählen"
            :counter-size-string="
              `
                ${currentFiles.filter((o) => o.type.includes('image/')).length}x Bild,
                ${currentFiles.filter((o) => o.type.includes('/pdf')).length}x PDF
              `
            "
            :accept="accept"
            @change="selectFile"
            :key="componentKey"
          ></v-file-input>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="py-0" align="center">
          <v-btn color="success" small @click="upload"
            :disabled="currentFiles.length <= 0"
            :loading="uploading"
          >
            <v-icon left>mdi-cloud-upload</v-icon>
            Hochladen
          </v-btn>
        </v-col>
      </v-row>
      <v-alert v-if="message" border="left" color="blue-grey" dark>
        {{ message }}
      </v-alert>
      <!-- <v-card v-if="fileInfos.length > 0" class="mx-auto">
        <v-list>
          <v-subheader>List of Files</v-subheader>
          <v-list-item-group color="primary">
            <v-list-item v-for="(file, index) in fileInfos" :key="index">
              <a :href="file.url">{{ file.name }}</a>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-card> -->
    </div>
</template>
<script>
import $ from 'jquery';

export default {
  props: {
    multiple: {
      type: Boolean,
      default: true,
    },
    apipath: {
      type: String,
      default: 'f400-410/UPLOAD',
    },
    accept: {
      type: String,
      default: 'image/png, image/jpeg, image/bmp',
    },
  },
  data() {
    return {
      currentFiles: [],
      progress: 0,
      message: '',
      fileInfos: [],
      uploading: false,
      componentKey: 1,
    };
  },
  computed: {
  },
  watch: {
  },
  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFiles = file;
      // console.log(this.currentFiles);
    },
    upload() {
      if (this.currentFiles.length <= 0) {
        this.message = 'Bitte eine Datei auswählen';
        return;
      }
      this.message = '';
      this.tryUpload(this.currentFiles);
    },
    async tryUpload(files) {
      this.uploading = true;
      const formData = new FormData();
      let i = 1;
      files.forEach((f) => {
        formData.append(`file${i}`, f);
        i += 1;
      });
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/${this.apipath}`,
        type: 'POST',
        data: formData,
        processData: false,
        contentType: false,
        success: (response) => {
          // console.log(response);
          this.$emit('neuDokumente', response.neuDokument);
          this.uploading = false;
          this.componentKey += 1;
          this.currentFiles = [];
        },
        complete() {
          this.uploading = false;
        },
      });
    },
  },
};
</script>
