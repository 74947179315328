<template>
  <v-container fluid class="ma-0 pa-0 d-flex justify-center">
    <v-container class="ma-0 pa-2">
      <EditKosten
        v-if="editKosten.id"
        :prop_kunden="JSON.parse(JSON.stringify(prop_kunden))"
        :prop_kosten="editKosten"
        :prop_settings="prop_settings"
        :prop_isSaving_editKosten="isSaving_editKosten"
        :prop_isSaving_delKosten="isSaving_delKosten"
        @close="editKosten = {}"
        @save="saveKosten($event);"
        @del="delKosten($event);"
      />
      <v-row>
        <v-col>
          <v-btn
            small color="primary"
            @click="addKosten();"
            :loading="isSaving_addKosten"
            class="mx-1"
          >
            <v-icon>mdi-plus-box</v-icon>
          </v-btn>
          <v-dialog
            v-model="dialog_settings"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                small color="primary"
                v-bind="attrs"
                v-on="on"
                class="mx-1"
              >
                <v-icon>mdi-cogs</v-icon>
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Einstellungen
              </v-card-title>

              <v-card-text>
              <v-select
                multiple
                label="Nur bestimme Kostenstellen anzeigen:"
                v-model="filterKostenstellen"
                :items="prop_settings.kostenstellen"
                item-text="name"
                item-value="nummer"
                class="my-4"
              >
                <template v-slot:selection="data">
                  <span
                    v-if="data.index === 0"
                  >
                    <span
                      class="grey--text text-caption"
                      v-for="(k, idx) in filterKostenstellen"
                      :key="idx"
                    >
                      <v-chip small
                        v-if="prop_settings.kostenstellen.filter((o) => o.nummer === k).length > 0"
                        close
                        :input-value="k"
                        @click:close="
                          removeAngezeigteKostenstelle(k);
                        "
                      >{{ prop_settings.kostenstellen
                        .filter((o) => o.nummer === k)[0].name }}</v-chip>
                    </span>
                  </span>
                </template>
              </v-select>
              <div class="mb-4 d-flex justify-space-around"
                style="transform:translateY(-30px);"
              >
                <v-btn x-small
                  @click="filterKlassenAlle();"
                  :disabled="filterKostenstellen.length === prop_settings.kostenstellen.length"
                >Alle auswählen</v-btn>
                <v-btn x-small
                  @click="filterKlassenKeine();"
                  :disabled="filterKostenstellen.length <= 0"
                >Alle abwählen</v-btn>
              </div>
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_settings = false"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" sm="12" class="py-0 pt-2">
          <v-text-field
            clearable dense
            label="Suchen..."
            append-icon="mdi-magnify"
            v-model="search"
            :rules="[
              $store.state.main.inputValidationRules.name,
            ]"
            class="ma-0"
          ></v-text-field>
        </v-col>
      </v-row>
      Summe aller angezeigten Kosten:
      <b>{{ String(summeAllerAngezeigtenKosten).split('.').join(',') }} €</b>
      <v-data-table
        :headers="headers"
        :items="
          save.kosten.filter((o) => {
            if (
              filterKostenstellen.includes(o.kostenstelle)
              || filterKostenstellen.length <= 0
            ) {
              return o;
            }
          })
        "
        :search="search"
        :loading="loading"
        :items-per-page="50"
        :header-props="headerProps"
        :footer-props="footerProps"
        no-data-text="Keine Aufträge gefunden"
        no-results-text="Keine Aufträge gefunden"
        loading-text="Lade Daten... Bitte warten"
        mobile-breakpoint="0"
      >
        <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
          {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
        </template>
        <template v-slot:item.titel="{ item }">
          <div
            class="primary--text"
            @click="editKosten = item"
            style="cursor:pointer;font-weight:bold;"
          >
            <span
              v-if="item.titel !== ''"
            >
              {{ item.titel }}
            </span>
            <span v-else>
              --
            </span>
            <v-btn
              v-if="
                prop_auftraege
                  .filter((a) => a.leistungen
                    .filter((l) => l.id_kosten === item.id).length > 0).length > 0
              "
              @click.prevent="$router.push(`/410-100?a=${prop_auftraege
              .filter((a) => a.leistungen
                .filter((l) => l.id_kosten === item.id).length > 0)[0].id}`);"
              small text
              class="ml-2"
            >
            ({{ prop_auftraege
              .filter((a) => a.leistungen
                .filter((l) => l.id_kosten === item.id).length > 0)[0].kundennummer }}
            /
            {{ prop_auftraege
              .filter((a) => a.leistungen
                .filter((l) => l.id_kosten === item.id).length > 0)[0].titel }})
            </v-btn>
            <span
              v-if="item.dokumente.filter((o) => o.dokumentart === '1').length <= 0"
              class="error--text"
              title="Keine Rechnung angehängt!"
              style="position:relative;"
            >
              <v-icon right color="error">
                mdi-paperclip
              </v-icon>
              <span
                style="
                  display:block;
                  position:absolute;
                  width:20px;height:20px;
                  left:18px;bottom:-8px;
                  background-color:#ffffff;
                  border-radius:50%;padding:2px;padding-left:4px;
                  transform:scale(0.7);
                "
              >&#10008;</span>
            </span>
            <span
              v-if="item.dokumente.filter((o) => o.dokumentart === '20').length <= 0"
              class="error--text"
              title="Kein Buchungsbeleg hochgeladen!"
              style="position:relative;"
            >
              <v-icon right color="error">
                mdi-receipt-text-outline
              </v-icon>
              <span
                style="
                  display:block;
                  position:absolute;
                  width:20px;height:20px;
                  left:18px;bottom:-8px;
                  background-color:#ffffff;
                  border-radius:50%;padding:2px;padding-left:4px;
                  transform:scale(0.7);
                "
              >&#10008;</span>
            </span>
          </div>
        </template>
        <template v-slot:item.datum="{ item }">
          {{ `${item.datum.split('-')[2]}.
          ${item.datum.split('-')[1]}.
          ${item.datum.split('-')[0]}`.split(' ').join('') }}
        </template>
        <template v-slot:item.betrag="{ item }">
          <div align="right">
            {{ item.betrag.split('.').join(',') }} €
          </div>
        </template>
      </v-data-table>
    </v-container>
  </v-container>
</template>

<script>
// import $ from 'jquery';
import EditKosten from '@/components/Funktionen/400/410-Kunden/EditKosten.vue';

export default {
  components: {
    EditKosten,
  },
  props: {
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
    prop_kunden: {
      type: Array,
      default() { return []; },
    },
    prop_auftraege: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    dialog_settings: false,
    filterKostenstellen: [],
    editKosten: {},
    xLoaded: {
      kosten: {},
    },
    xChanged: {},
    save: {
      settings: {},
      kosten: [],
    },
    isSaving: false,
    isSaving_editKosten: false,
    isSaving_delKosten: false,
    isSaving_addKosten: false,
    loading: false,
    search: '',
    headers: [
      // { text: 'Kosten', value: 'kostennummer', width: '150px' },
      { text: 'Datum', value: 'datum', width: '120px' },
      { text: 'Titel', value: 'titel' },
      { text: 'Wert', value: 'betrag', width: '120px' },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
    summeAllerAngezeigtenKosten() {
      let summe = 0;
      const liste = this.save.kosten
        .filter(
          (o) => this.filterKostenstellen.includes(o.kostenstelle)
          || this.filterKostenstellen.length <= 0,
        );
      liste.forEach((o) => {
        summe += parseFloat(o.betrag);
      });
      summe = parseFloat(summe).toFixed(2);
      return summe;
    },
  },
  watch: {
    prop_settings: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    prop_kosten: {
      handler() {
        this.datenLaden();
      },
      deep: true,
    },
    filterKostenstellen(neu) {
      neu.forEach((k, idx) => {
        if (this.prop_settings.kostenstellen.filter((o) => o.nummer === k).length <= 0) {
          neu.splice(idx, 1);
        }
      });
      localStorage.setItem('f400-410-filterKst', JSON.stringify(neu));
    },
  },
  methods: {
    filterKlassenAlle() {
      this.filterKostenstellen = [];
      this.prop_settings.kostenstellen.forEach((k) => {
        this.filterKostenstellen.push(k.nummer);
      });
    },
    filterKlassenKeine() {
      this.filterKostenstellen = [];
    },
    removeAngezeigteKostenstelle(nummer) {
      this.filterKostenstellen
        .splice(
          this.filterKostenstellen.indexOf(nummer),
          1,
        );
    },
    addKosten() {
      this.isSaving_addKosten = true;
      this.$emit('tryGetOrSave', { type: 'addKosten', data: {} });
    },
    saveKosten(data) {
      this.isSaving_editKosten = true;
      this.$emit('tryGetOrSave', { type: 'saveKosten', data });
    },
    delKosten(data) {
      this.isSaving_delKosten = true;
      this.$emit('tryGetOrSave', { type: 'delKosten', data });
    },
    datenLaden() {
      /*
        Wenn neue Daten übergeben wurden,
        prüfe ob diese sich von den zuletzt geladenen unterscheiden.
        Wenn ja, überschreibe die aktuellen Daten mit den neu übergebenen Daten.
      */
      let anythingUpdated = false;
      if (JSON.stringify(this.prop_settings) !== JSON.stringify(this.xLoaded.settings)) {
        this.save.settings = JSON.parse(JSON.stringify(this.prop_settings));
        this.xLoaded.settings = JSON.parse(JSON.stringify(this.prop_settings));
        anythingUpdated = true;
      }
      this.prop_kosten.forEach((k) => {
        if (!this.save.kosten.filter((o) => o.id === k.id).length > 0) {
          this.save.kosten.push(k);
          this.xLoaded.kosten[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        } else if (
          JSON.stringify(k) !== this.xLoaded.kosten[k.id]
        ) {
          this.save.kosten.splice(
            this.save.kosten.indexOf(this.save.kosten.filter((o) => o.id === k.id)[0]),
            1,
          );
          this.save.kosten.push(k);
          this.xLoaded.kosten[k.id] = JSON.stringify(k);
          anythingUpdated = true;
        }
      });
      this.save.kosten.forEach((k) => {
        if (!this.prop_kosten.filter((o) => o.id === k.id).length > 0) {
          this.save.kosten.splice(
            this.save.kosten.indexOf(this.save.kosten.filter((o) => o.id === k.id)[0]),
            1,
          );
          anythingUpdated = true;
        }
      });
      if (anythingUpdated) {
        this.isSaving = false;
        this.isSaving_addKosten = false;
        if (this.save.settings.lastAddedKosten) {
          [this.editKosten] = this.save.kosten
            .filter((o) => o.id === this.save.settings.lastAddedKosten);
        }
        this.isSaving_editKosten = false;
        if (this.save.settings.lastEditedKosten) {
          this.editKosten = {};
        }
        this.isSaving_delKosten = false;
        if (this.save.settings.lastDeletedKosten) {
          this.editKosten = {};
        }
        this.save.kosten.sort((a, b) => {
          if (a.datum > b.datum) {
            return -1;
          }
          if (a.datum < b.datum) {
            return 1;
          }
          return 0;
        });
      }
    },
  },
  created() {
    this.datenLaden();
    this.filterKostenstellen = JSON.parse(localStorage.getItem('f400-410-filterKst'));
  },
};
</script>
