<template>
    <v-container>
      <p class="text-title text--secondary mt-2">
        Startposition
      </p>
      <v-row v-if="1 === 0">
        <v-col>
          <v-text-field
          type="text"
          label="Straße"
          prepend-icon=""
          v-model="settings.strasse"
          :rules="[
              $store.state.main.inputValidationRules.name,
          ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
          type="number"
          label="Hausnummer"
          prepend-icon=""
          v-model="settings.hausnummer"
          :rules="[
              $store.state.main.inputValidationRules.nummer,
          ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="1 === 0">
        <v-col>
          <v-text-field
          type="number"
          label="PLZ"
          prepend-icon=""
          v-model="settings.plz"
          :rules="[
              $store.state.main.inputValidationRules.nummer,
          ]"
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
          type="text"
          label="Ort"
          prepend-icon=""
          v-model="settings.ort"
          :rules="[
              $store.state.main.inputValidationRules.name,
          ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row align="center">
        <v-col cols="5">
          <v-text-field
            type="number"
            label="Breitengrad"
            prepend-icon=""
            v-model="settings.coords_lat"
            :rules="[
                $store.state.main.inputValidationRules.koordinaten,
            ]"
          ></v-text-field>
        </v-col>
        <v-col cols="5">
          <v-text-field
          type="number"
          label="Längengrad"
          prepend-icon=""
          v-model="settings.coords_lon"
          :rules="[
              $store.state.main.inputValidationRules.koordinaten,
          ]"
          ></v-text-field>
        </v-col>
        <v-col cols="2">
          <v-text-field
          type="number"
          label="Zoom"
          prepend-icon=""
          v-model="settings.zoom"
          :rules="[
              $store.state.main.inputValidationRules.koordinaten,
          ]"
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row v-if="1 === 0">
        <v-col align="center">
          <v-btn small>
            <v-icon small left>mdi-content-save</v-icon>
            Speichern
          </v-btn>
        </v-col>
      </v-row>
      <p class="text-title text--secondary mt-2">
        Aktionen
      </p>
      <v-row>
        <v-col align="center">
          <v-dialog
            v-model="dialog_setAllHydrantsUngeprueft"
            width="500"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
              >
                <v-icon left color="error">mdi-fire-hydrant-alert</v-icon>
                Alle Hydranten<br>ungeprüft schalten
              </v-btn>
            </template>

            <v-card>
              <v-card-title class="text-h5 grey lighten-2">
                Sicher?
              </v-card-title>

              <v-card-text class="pt-4">
                Es werden sämtliche Hydranten als ungeprüft markiert.
              </v-card-text>

              <v-divider></v-divider>

              <v-card-actions>
                <v-btn
                  color="primary"
                  text
                  @click="dialog_setAllHydrantsUngeprueft = false;"
                >
                  Nichts tun
                </v-btn>
                <v-spacer></v-spacer>
                <v-btn
                  color="primary"
                  text
                  @click="
                    dialog_setAllHydrantsUngeprueft = false;
                    tryChangeStatusAll('2');
                  "
                >
                  Bestätigen
                </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
      <v-row>
        <v-col align="center">
          <v-btn
            @click="tryCreateAuswertung();"
          >
            <v-icon left color="error">mdi-file-pdf-box</v-icon>
            Auswertung erstellen
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
</template>

<script>
import $ from 'jquery';

export default {
  components: {
  },
  props: {
    data: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    stillLoaded: false,
    dialog_setAllHydrantsUngeprueft: false,
    settings: {
      coords_lat: '',
      coords_lon: '',
      zoom: 0,
      strasse: '',
      hausnummer: '',
      plz: '',
      ort: '',
    },
  }),
  computed: {
  },
  watch: {
    data: {
      handler(neu) {
        if (!this.stillLoaded && neu.settings) {
          this.stillLoaded = true;
          this.getSettings();
        }
      },
      deep: true,
    },
    settings: {
      handler() {
        this.trySpeichern();
      },
      deep: true,
    },
  },
  methods: {
    getSettings() {
      if (this.data.settings) {
        this.settings.coords_lat = this.data.settings.startpos_lat;
        this.settings.coords_lon = this.data.settings.startpos_lon;
        this.settings.zoom = this.data.settings.startpos_zoom;
      }
    },
    async trySpeichern() {
      // this.$store.commit('main/switchIsSaving', true);
      // this.isSaving = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=saveSettings`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          settings: JSON.stringify(this.settings),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            // this.$store.commit('main/switchIsSaving', false);
            // this.isSaving = false;
            /* this.$store.commit('main/alert', {
              typ: 'success',
              text: 'Einstellungen erfolgreich gespeichert!',
            }, { root: true }); */
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // this.$store.commit('main/switchIsSaving', false);
          // this.isSaving = false;
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    async tryChangeStatusAll(neuStatus) {
      this.$store.commit('main/switchIsSaving', true);
      this.isSavingChangeStatusAll = true;
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/300/310/karte.php?func=saveHydrantChangeStatusALL`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          neuStatus,
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$store.commit('main/switchIsSaving', false);
            this.isSavingChangeStatusAll = false;
            this.$store.commit('main/alert', {
              typ: 'success',
              text: `Alle Hydranten sind nun "${response.statuswert}".`,
            }, { root: true });
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.isSavingChangeStatusAll = false;
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
      });
    },
    tryCreateAuswertung() {
      /* window.open(
        `${process.env.VUE_APP_API_HOST}/data/download.php`,
        '_blank',
        'width=400,height=200,resizable=yes,status=no,
        menubar=no,location=no,scrollbars=yes,toolbar=no',
      ); */
      window.open(`${process.env.VUE_APP_API_HOST}/data/download.php`);
    },
  },
  created() {
    this.$emit('reload');
  },
};
</script>
