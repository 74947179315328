<template>
    <v-container
        style="text-align:center;"
        class="ma-2 mt-0 pr-8"
    >
        <v-form
            v-model="valid"
        >
          <p align="left" class="text-title text--secondary">
            Meine Daten
          </p>
          <EditUserParameter
              v-if="user"
              :user="user"
              :bedingungen="['f010']"
          />
        </v-form>
        <v-btn
            v-if="valid && userChanged"
            small
            color="success"
            :loading="isSaving"
            @click="trySaveUser();"
        >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
        </v-btn>
    </v-container>
</template>

<script>
import $ from 'jquery';
import EditUserParameter from '@/components/Inputs/EditUserParameter.vue';

export default {
  components: {
    EditUserParameter,
  },
  props: {
  },
  data: () => ({
    user: null,
    valid: true,
    userChanged: false,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        // this.paramsChanged = false;
      }
    },
    user: {
      handler(neu, alt) {
        if (alt) {
          this.userChanged = true;
        }
      },
      deep: true,
    },
  },
  methods: {
    trySaveUser() {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/settings/?func=saveNutzer`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          data: JSON.stringify(this.user),
          neuPW: '',
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            this.$store.dispatch('main/tryGetSettings', { code: this.$store.state.login.session.code }).then(() => {
              this.userChanged = false;
            });
          }
        },
        timeout: this.$store.state.main.ajaxMaxTimeout,
        error: () => {
          // Nutzer konnte nicht gespeichert werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die Einstellungen konnten nicht gespeichert werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
  },
  created() {
    const user = JSON.stringify(this.$store.state.main.save.user);
    this.user = JSON.parse(user);
  },
};
</script>
