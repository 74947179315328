var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"comp_Mainmenu"},[_c('v-app-bar',{staticClass:"pr-4",attrs:{"absolute":"","dense":"","dark":"","clipped-left":"","color":"primary","elevation":"5"}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.showNavigation= !_vm.showNavigation}}}),_c('v-tabs',{attrs:{"dark":"","show-arrows":"","hide-slider":"","next-icon":"mdi-menu-right","prev-icon":"mdi-menu-left"}},[_c('v-tabs-slider',{attrs:{"color":"yellow"}}),_vm._l((_vm.quicknavLinks),function(link){return _c('v-tab',{key:link.title,class:{
              'v-tab--active': link.route === ("/" + (_vm.$store.state.main.routerCurrentFeature))
            },attrs:{"to":link.route}},[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1)})],2)],1),_c('v-navigation-drawer',{attrs:{"absolute":"","temporary":"","clipped":""},model:{value:(_vm.showNavigation),callback:function ($$v) {_vm.showNavigation=$$v},expression:"showNavigation"}},[_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-h6"},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.settings.app_titel)+" ")]),_c('v-list-item-subtitle',[_vm._v(" "+_vm._s(_vm.$store.state.main.save.user.ident)+" ")])],1)],1),_c('v-divider'),_c('v-list',{attrs:{"dense":"","nav":""}},[_vm._l((_vm.sidenavLinks),function(link){return _c('v-list-item',{key:link.title,class:{
                  'v-tab--active v-list-item--active': link.route ===
                  ("/" + (_vm.$store.state.main.routerCurrentFeature))
                },attrs:{"link":"","to":link.route}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v(_vm._s(link.icon))])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(_vm._s(link.title))])],1)],1)}),_c('v-list-item',{attrs:{"link":""},on:{"click":function($event){return _vm.$store.dispatch('login/tryLogout', {
                router: _vm.$router,
              })}}},[_c('v-list-item-icon',[_c('v-icon',[_vm._v("mdi-exit-to-app")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Ausloggen")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }