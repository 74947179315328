var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-select',{attrs:{"dense":_vm.dense,"items":_vm.useGruppen,"attach":"","chips":"","multiple":"","hide-selected":"","label":_vm.label,"disabled":_vm.disabled},scopedSlots:_vm._u([{key:"selection",fn:function(ref){
var attrs = ref.attrs;
var item = ref.item;
var parent = ref.parent;
var selected = ref.selected;
return [_c('v-chip',_vm._b({class:{ 'disabled': _vm.disabled },attrs:{"input-value":selected,"label":"","small":""}},'v-chip',attrs,false),[_c('v-icon',{attrs:{"small":"","left":""}},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzergruppen .filter(function (o) { return o.id == item; })[0].icon)+" ")]),_c('span',{staticClass:"pr-2"},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzergruppen .filter(function (o) { return o.id == item; })[0].name)+" ")]),_c('v-icon',{attrs:{"small":""},on:{"click":function($event){return parent.selectItem(item)}},nativeOn:{"click":function($event){$event.stopPropagation();}}},[_vm._v(" $delete ")])],1)]}},{key:"item",fn:function(ref){
var item = ref.item;
return [_c('v-icon',{attrs:{"left":""}},[_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzergruppen .filter(function (o) { return o.id == item; })[0].icon)+" ")]),_vm._v(" "+_vm._s(_vm.$store.state.main.save.nutzergruppen .filter(function (o) { return o.id == item; })[0].name)+" ")]}},{key:"no-data",fn:function(){return [_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Keine weiteren Gruppen. ")])],1)],1)]},proxy:true}]),model:{value:(_vm.gruppen),callback:function ($$v) {_vm.gruppen=$$v},expression:"gruppen"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }