<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    scrollable
    transition="dialog-bottom-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        small color="success darken-2"
        class="me-2"
        v-bind="attrs"
        v-on="on"
      >
        <v-icon>mdi-finance</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="success darken-2 white--text" style="font-size:100%;">
        <v-container class="d-flex justify-space-between align-center pa-0">
          <v-toolbar-title>
            <v-icon dark left>mdi-finance</v-icon>
            Umsatzübersicht {{ jahr }}
          </v-toolbar-title>
          <v-btn
            icon
            dark
            @click="showDialog = false"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-container>
      </v-card-title>
      <v-card-text class="pa-0" style="overflow-x:hidden;">
        <v-tabs
          fixed-tabs
          v-model="tabs_vertraege"
          v-if="$vuetify.breakpoint.lgAndDown"
          class="mb-2"
        >
          <v-tab>Umsätze Gesamt</v-tab>
          <v-tab>Verträge</v-tab>
        </v-tabs>
        <v-container class="pa-0">
          <v-row>
            <v-col cols="12" xl="6"
              v-if="!$vuetify.breakpoint.lgAndDown || tabs_vertraege === 0"
            >
              <v-dialog
                v-model="dialog_settings"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                  >
                    <v-icon>mdi-cogs</v-icon>
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Einstellungen
                  </v-card-title>

                  <v-card-text>
                    <p
                      v-if="filterKostenstellen.length <= 0"
                      class="my-2"
                    >
                      Es werden alle Kostenstellen in den Fixkosten berücksichtigt.<br>
                      Es können auch nur bestimmte Kostenstellen berücksichtigt werden:
                    </p>
                    <p
                      v-if="filterKostenstellen.length > 0"
                      class="my-2"
                    >
                      Es werden nur bestimmte Kostenstellen in den Fixkosten berücksichtigt.<br>
                      um alle Kostenstellen zu berücksichtigen, alle entfernen:
                    </p>
                    <v-select
                      multiple
                      label="Nur bestimme Kostenstellen berücksichtigen:"
                      v-model="filterKostenstellen"
                      :items="prop_settings.kostenstellen"
                      item-text="name"
                      item-value="nummer"
                      class="my-4 mt-0"
                    >
                      <template v-slot:selection="data">
                        <span
                          v-if="data.index === 0"
                        >
                          <span
                            class="grey--text text-caption"
                            v-for="(k, idx) in filterKostenstellen"
                            :key="idx"
                          >
                            <v-chip small
                              v-if="
                                prop_settings.kostenstellen.filter((o) => o.nummer === k).length > 0
                              "
                              close
                              :input-value="k"
                              @click:close="
                                removeAngezeigteKostenstelle(k);
                              "
                            >{{ prop_settings.kostenstellen
                              .filter((o) => o.nummer === k)[0].name }}</v-chip>
                          </span>
                        </span>
                      </template>
                    </v-select>
                    <div class="mb-4 d-flex justify-space-around"
                      style="transform:translateY(-30px);"
                    >
                      <v-btn x-small
                        @click="filterKlassenAlle();"
                        :disabled="
                          filterKostenstellen.length === prop_settings.kostenstellen.length
                        "
                      >Alle auswählen</v-btn>
                      <v-btn x-small
                        @click="filterKlassenKeine();"
                        :disabled="filterKostenstellen.length <= 0"
                      >Alle abwählen</v-btn>
                    </div>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="primary"
                      text
                      @click="dialog_settings = false"
                    >
                      OK
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialog_EueR"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                  >
                    <v-icon left>mdi-download</v-icon>
                    EÜR
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    EÜR
                  </v-card-title>

                  <v-card-text>
                    <v-text-field
                      label="Jahr"
                      v-model="eUeRJahr"
                      type="number"
                    />
                    <v-container class="d-flex justify-space-around">
                      <v-btn @click="(eUeRJahr -= 1)" text color="primary darken-2">
                        <v-icon>mdi-minus-box</v-icon>
                      </v-btn>
                      <v-btn @click="(eUeRJahr += 1)" text color="primary darken-2">
                        <v-icon>mdi-plus-box</v-icon>
                      </v-btn>
                    </v-container>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      text
                      @click="dialog_EueR = false"
                    >
                      schließen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <v-btn
                      small color="primary"
                      class="mx-1"
                      @click="trySaveNeuDokument('EueR');"
                      :loading="loading_createDokumentEueR"
                    >
                      <v-icon left>mdi-download</v-icon>
                      EÜR {{ eUeRJahr }}
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-dialog
                v-model="dialog_monatsbelege"
                width="500"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                    small color="primary"
                    v-bind="attrs"
                    v-on="on"
                    class="mx-1"
                  >
                    <v-icon left>mdi-download</v-icon>
                    Monatsbelege
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Monatsbelege
                  </v-card-title>

                  <v-card-text>
                    <v-select
                      label="Monat"
                      :items="monate"
                      item-text="name"
                      item-value="id"
                      v-model="monatsbelegeMonat"
                    />
                    <v-text-field
                      label="Jahr"
                      v-model="eUeRJahr"
                      type="number"
                    />
                    <v-container class="d-flex justify-space-around">
                      <v-btn @click="(eUeRJahr -= 1)" text color="primary darken-2">
                        <v-icon>mdi-minus-box</v-icon>
                      </v-btn>
                      <v-btn @click="(eUeRJahr += 1)" text color="primary darken-2">
                        <v-icon>mdi-plus-box</v-icon>
                      </v-btn>
                    </v-container>
                    <p>
                      Wähle einen Monat und alle Belege dieses Monats werden
                      heruntergeladen.
                    </p>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-btn
                      text
                      @click="dialog_monatsbelege = false"
                    >
                      schließen
                    </v-btn>
                    <v-spacer></v-spacer>
                    <!-- <v-btn
                      small color="primary"
                      class="mx-1"
                      @click="trySaveNeuDokument('EueR');"
                      :loading="loading_createDokumentEueR"
                    >
                      <v-icon left>mdi-download</v-icon>
                      EÜR {{ eUeRJahr }}
                    </v-btn> -->
                  </v-card-actions>
                </v-card>
              </v-dialog>
              <v-row class="pa-4 mb-4" style="">
                <v-col align="center" class="success--text text--darken-2">
                  <h1 class="ma-2">Umsatz</h1>
                  {{
                    gesamt.umsatzNetto
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                  <br>
                  + {{
                    (gesamt.umsatzBrutto - gesamt.umsatzNetto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }} USt.
                  <hr class="success" width="120px">
                  <b>{{
                    (gesamt.umsatzBrutto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}</b>
                </v-col>
                <v-col align="center" class="error--text text--darken-2">
                  <h1 class="ma-2">Kosten</h1>
                  {{
                    gesamt.kostenBrutto
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                  (-{{
                    (gesamt.kostenBrutto - gesamt.kostenNetto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                  <br>
                  + {{
                    summeEigeneAusgabenBrutto
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                  (-{{
                    (summeEigeneAusgabenBrutto - summeEigeneAusgabenNetto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                  <hr class="error" width="120px">
                  <b>{{
                    (gesamt.kostenBrutto + summeEigeneAusgabenBrutto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}</b>
                  (-{{
                    (
                      (gesamt.kostenBrutto - gesamt.kostenNetto)
                      +
                      (summeEigeneAusgabenBrutto - summeEigeneAusgabenNetto)
                    ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                </v-col>
                <v-col align="center" class="warning--text text--darken-1">
                  <h1 class="ma-2">Gewinn</h1>
                  {{
                    (gesamt.umsatzBrutto - gesamt.kostenBrutto - summeEigeneAusgabenBrutto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                  (+{{
                    (
                      (gesamt.kostenBrutto - gesamt.kostenNetto)
                      +
                      (summeEigeneAusgabenBrutto - summeEigeneAusgabenNetto)
                    ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                  <br>
                  - {{
                    (gesamt.umsatzBrutto - gesamt.umsatzNetto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }} USt.
                  <hr class="warning" width="120px">
                  <b>{{
                    (gesamt.umsatzBrutto - gesamt.kostenBrutto - summeEigeneAusgabenBrutto
                    - (gesamt.umsatzBrutto - gesamt.umsatzNetto))
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}</b>
                  (+{{
                    (
                      (gesamt.kostenBrutto - gesamt.kostenNetto)
                      +
                      (summeEigeneAusgabenBrutto - summeEigeneAusgabenNetto)
                    ).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                </v-col>
              </v-row>
              <v-row class="pa-4 my-4">
                <h2>Umsätze nach Einnahmestelle</h2>
              </v-row>
              <v-data-table
                :headers="datatable1_headers"
                :items="auftragsleistungen"
                :items-per-page="10"
                :header-props="headerProps"
                :footer-props="footerProps"
                no-data-text="Keine Umsätze gefunden"
                no-results-text="Keine Umsätze gefunden"
                loading-text="Lade Daten... Bitte warten"
                class="elevation-1"
              >
                <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
                </template>
                <template v-slot:item.einnahmestelle="{ item }">
                  <b>{{ item.einnahmestelle }}</b>
                </template>
                <template v-slot:item.wertBrutto="{ item }">
                  {{
                    item.wertBrutto.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                </template>
                <template v-slot:item.kostenBrutto="{ item }">
                  {{
                    item.kostenBrutto
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                  ({{
                    (item.kostenBrutto - item.kostenNetto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }})
                </template>
                <template v-slot:item.gewinnBrutto="{ item }">
                  {{
                    (item.gewinnBrutto)
                      .toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                </template>
              </v-data-table>
              <v-row class="pa-4 my-4">
                <h2>Ausgestellte Rechnungen</h2>
              </v-row>
              <v-data-table
                :headers="datatable2_headers"
                :items="ausgestellteRechnungen"
                :items-per-page="10"
                :header-props="headerProps"
                :footer-props="footerProps"
                no-data-text="Keine Rechnungen gefunden"
                no-results-text="Keine Rechnungen gefunden"
                loading-text="Lade Daten... Bitte warten"
                class="elevation-1"
              >
                <template v-slot:footer.page-text="{ pageStart, pageStop, itemsLength }">
                  {{ pageStart }} - {{ pageStop }} von {{ itemsLength }}
                </template>
                <template v-slot:item.datum="{ item }">
                  {{
                    item.datum.toLocaleString('de-DE', {
                      year: 'numeric', month: '2-digit', day: '2-digit'
                    })
                  }}
                </template>
                <template v-slot:item.name="{ item }">
                  <v-btn
                    color="primary"
                    text
                    @click="tryDownloadDokument(item.pfad, `${item.name}.pdf`)"
                  >
                    {{ item.name }}
                  </v-btn>
                </template>
                <template v-slot:item.wert="{ item }">
                  {{
                    item.wert.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
                  }}
                </template>
              </v-data-table>
            </v-col>
            <v-col cols="12" xl="6"
              v-if="!$vuetify.breakpoint.lgAndDown || tabs_vertraege === 1"
            >
              <AuftraegeUebersichtVertraege
                :vertraege="aktiveVertraege"
              />
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </v-dialog>
</template>

<script>
import $ from 'jquery';
import AuftraegeUebersichtVertraege from '@/components/Funktionen/400/410-Kunden/AuftraegeUebersichtVertraege.vue';
// import BarChart from '@/components/Charts/BarChart.vue';

export default {
  components: {
    AuftraegeUebersichtVertraege,
    // BarChart,
  },
  props: {
    prop_auftraege: {
      type: Array,
      default() { return []; },
    },
    prop_kosten: {
      type: Array,
      default() { return []; },
    },
    prop_settings: {
      type: Object,
      default() { return {}; },
    },
  },
  data: () => ({
    showDialog: false,
    dialog_settings: false,
    dialog_EueR: false,
    dialog_monatsbelege: false,
    eUeRJahr: null,
    monatsbelegeMonat: 1,
    tabs_vertraege: null,
    loading_createDokumentEueR: false,
    jahr: '',
    gesamt: {
      umsatzNetto: 0,
      umsatzBrutto: 0,
      kostenNetto: 0,
      kostenBrutto: 0,
    },
    filterKostenstellen: [],
    summeEigeneAusgabenBrutto: 0,
    summeEigeneAusgabenNetto: 0,
    ausgestellteRechnungen: [],
    auftragsleistungen: [],
    aktiveVertraege: [],
    monate: [
      { id: 1, name: 'Januar' },
      { id: 2, name: 'Februar' },
      { id: 3, name: 'März' },
      { id: 4, name: 'April' },
      { id: 5, name: 'Mai' },
      { id: 6, name: 'Juni' },
      { id: 7, name: 'Juli' },
      { id: 8, name: 'August' },
      { id: 9, name: 'September' },
      { id: 10, name: 'Oktober' },
      { id: 11, name: 'November' },
      { id: 12, name: 'Dezember' },
    ],
    datatable1_headers: [
      {
        text: 'Einnahmestelle',
        align: 'start',
        sortable: true,
        value: 'einnahmestelle',
      },
      {
        text: 'Umsatz',
        align: 'start',
        sortable: true,
        value: 'wertBrutto',
      },
      {
        text: 'Kosten (MwSt)',
        align: 'start',
        sortable: true,
        value: 'kostenBrutto',
      },
      {
        text: 'Gewinn',
        align: 'start',
        sortable: true,
        value: 'gewinnBrutto',
      },
    ],
    datatable2_headers: [
      {
        text: 'Datum',
        align: 'start',
        sortable: true,
        value: 'datum',
      },
      {
        text: 'Name',
        align: 'start',
        sortable: true,
        value: 'name',
      },
      {
        text: 'Umsatz',
        align: 'start',
        sortable: true,
        value: 'wert',
      },
    ],
    headerProps: {
      sortByText: 'Sortiere nach',
    },
    footerProps: {
      itemsPerPageText: 'Elemente pro Seite:',
      itemsPerPageOptions: [5, 10, 20, 50, -1],
      itemsPerPageAllText: 'Alle',
    },
  }),
  computed: {
  },
  watch: {
    showDialog(neu) {
      if (neu) {
        this.filterKostenstellen = JSON.parse(localStorage.getItem('f400-410-filterKst'));
        this.createData();
      }
    },
    filterKostenstellen(neu) {
      if (this.showDialog) {
        localStorage.setItem('f400-410-filterKst', JSON.stringify(neu));
        this.createData();
      }
    },
  },
  methods: {
    filterKlassenAlle() {
      this.filterKostenstellen = [];
      this.prop_settings.kostenstellen.forEach((k) => {
        this.filterKostenstellen.push(k.nummer);
      });
    },
    filterKlassenKeine() {
      this.filterKostenstellen = [];
    },
    removeAngezeigteKostenstelle(nummer) {
      this.filterKostenstellen
        .splice(
          this.filterKostenstellen.indexOf(nummer),
          1,
        );
    },
    createData() {
      const date = new Date();
      this.jahr = date.getYear() + 1900;
      this.eUeRJahr = this.jahr;
      this.monatsbelegeMonat = date.getMonth + 1;
      const gesamt = {
        umsatzNetto: 0,
        umsatzBrutto: 0,
        kostenNetto: 0,
        kostenBrutto: 0,
      };
      const projektkosten = [];
      let leistungen = [];
      let rechnungen = [];
      let vertraege = [];
      this.prop_auftraege.filter((o) => o.typ === 'Auftrag' || o.typ === 'Vertrag').forEach((a) => {
        /*
          Durchsuche alle Aufträge und filtere nach deren Rechnungen,
          die in diesem Jahr ausgestellt wurden.
        */
        a.dokumente.filter((o) => {
          if (
            o.dokumentart === '1' // Rechnung
            && !o.geplanteRechnung
            && o.dateUpload2 >= `${this.jahr}-01-01`
            && o.dateUpload2 <= `${this.jahr}-12-31`
          ) {
            return o;
          }
          return null;
        }).forEach((r) => {
          const summeLeistungen = {
            umsatzNetto: 0,
            kostenBrutto: 0,
          };
          // Leistungen:
          let reLeistungen;
          if (r.json_leistungen !== '') {
            reLeistungen = JSON.parse(r.json_leistungen);
          } else {
            reLeistungen = a.leistungen;
          }
          reLeistungen.forEach((l) => {
            if (
              leistungen.filter(
                (o) => {
                  if (
                    o.id_einnahmestelle === l.id_einnahmestelle
                  ) {
                    return o;
                  }
                  return null;
                },
              ).length <= 0
            ) {
              let einnahmestelle = '';
              if (
                this.prop_settings.einnahmestellen
                  .filter((o) => o.id === l.id_einnahmestelle).length > 0
              ) {
                einnahmestelle = this.prop_settings.einnahmestellen
                  .filter((o) => o.id === l.id_einnahmestelle)[0].name;
                leistungen.push({
                  id_einnahmestelle: l.id_einnahmestelle,
                  einnahmestelle,
                  wertBrutto: 0,
                  wertNetto: 0,
                  kostenBrutto: 0,
                  kostenNetto: 0,
                  gewinnBrutto: 0,
                  gewinnNetto: 0,
                });
              } else {
                // console.log(`Leistung: Einnahmestelle ${l.id_einnahmestelle} nicht gefunden`);
                console.log(`${r.name} - Einnahmestelle nicht gefunden.`, l);
                console.log(r);
              }
            }
            leistungen.filter(
              (o) => {
                if (
                  o.id_einnahmestelle === l.id_einnahmestelle
                ) {
                  return o;
                }
                return null;
              },
            ).forEach((o) => {
              o.wertBrutto += parseFloat(l.wertBrutto, 10) * parseFloat(l.anzahl, 10);
              gesamt.umsatzBrutto += parseFloat(l.wertBrutto, 10) * parseFloat(l.anzahl, 10);
              o.wertNetto += parseFloat(l.wert, 10) * parseFloat(l.anzahl, 10);
              gesamt.umsatzNetto += parseFloat(l.wert, 10) * parseFloat(l.anzahl, 10);
              summeLeistungen.umsatzNetto += parseFloat(l.wert, 10) * parseFloat(l.anzahl, 10);
              let kostenBrutto = l.kosten;
              if (this.prop_kosten.filter((ob) => ob.id === l.id_kosten).length > 0) {
                if (projektkosten.filter((ob) => ob.id === l.id_kosten).length <= 0) {
                  projektkosten.push(this.prop_kosten.filter((ob) => ob.id === l.id_kosten)[0]);
                }
                kostenBrutto = this.prop_kosten.filter((ob) => ob.id === l.id_kosten)[0].betrag;
              }
              o.kostenBrutto += parseFloat(kostenBrutto, 10);
              gesamt.kostenBrutto += parseFloat(kostenBrutto, 10);
              summeLeistungen.kostenBrutto += parseFloat(kostenBrutto, 10);
              let kostenNetto = l.kosten;
              if (this.prop_kosten.filter((ob) => ob.id === l.id_kosten).length > 0) {
                kostenNetto = this.prop_kosten
                  .filter((ob) => ob.id === l.id_kosten)[0].betrag / (1 + this.prop_kosten
                  .filter((ob) => ob.id === l.id_kosten)[0].mwst / 100);
              }
              o.kostenNetto += parseFloat(kostenNetto, 10);
              gesamt.kostenNetto += parseFloat(kostenNetto, 10);
              o.gewinnBrutto += (
                parseFloat(l.wert, 10) - parseFloat(kostenBrutto, 10)
              ) * parseFloat(l.anzahl, 10);
              o.gewinnNetto += (
                parseFloat(l.wert, 10) - parseFloat(kostenNetto, 10)
              ) * parseFloat(l.anzahl, 10);
            });
          });
          // --
          // Rechnungen:
          const datum = new Date(r.dateUpload2);
          rechnungen.push({
            auftrag: a,
            dokument: r,
            wert: parseFloat(r.wert, 10),
            name: r.name,
            datum,
            pfad: r.pfad,
            summeLeistungen,
          });
          if (
            summeLeistungen.umsatzNetto.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
            !== parseFloat(r.wert, 10).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })
          ) {
            console.log(`${r.name} - Leistungen und Gesamtwert decken sich nicht:
            Leistungen: ${summeLeistungen.umsatzNetto.toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}
            Gesamtwert: ${parseFloat(r.wert, 10).toLocaleString('de-DE', { style: 'currency', currency: 'EUR' })}`, a);
          }
          // --
        });
        // Verträge:
        if (
          a.typ === 'Vertrag'
          && a.status >= 4
          && a.status <= 7
        ) {
          let kuendigungBis = new Date(a.vertrag_ende);
          kuendigungBis = kuendigungBis.setDate(kuendigungBis.getDate() - 4 * 7);
          kuendigungBis = new Date(kuendigungBis);
          const dd = String(kuendigungBis.getDate()).padStart(2, '0');
          const mm = String(kuendigungBis.getMonth() + 1).padStart(2, '0'); // January is 0!
          const yyyy = kuendigungBis.getFullYear();
          kuendigungBis = `${yyyy}-${mm}-${dd}`;
          vertraege.push({
            id: a.id,
            titel: a.titel,
            beschreibung: a.beschreibung,
            auftragsnummer: a.auftragsnummer,
            kunde: {
              kundennummer: a.kundennummer,
            },
            beginn: a.vertrag_beginn,
            ende: a.vertrag_ende,
            kuendigungBis,
            vertrag_nummer: a.vertrag_nummer,
            zahlungsintervall: a.vertrag_zahlungJaehrlich,
            umsatz: a.umsatz,
            kosten: a.kosten,
            status: this.prop_settings.vertragsstatus
              .filter((o) => o.nummer === a.status)[0],
            zeitBisVerlaengerung:
              new Date(new Date(a.vertrag_ende) - new Date()).getTime(),
            zeitBisEndeKuendigungsfrist:
              new Date(new Date(kuendigungBis) - new Date()).getTime(),
          });
        }
        // --
      });
      this.gesamt = gesamt;
      leistungen = leistungen.sort((a, b) => {
        if (a.gewinnBrutto > b.gewinnBrutto) {
          return -1;
        }
        if (a.gewinnBrutto < b.gewinnBrutto) {
          return 1;
        }
        return 0;
      });
      this.auftragsleistungen = leistungen;
      rechnungen = rechnungen.sort((a, b) => {
        if (a.name > b.name) {
          return -1;
        }
        if (a.name < b.name) {
          return 1;
        }
        return 0;
      });
      this.ausgestellteRechnungen = rechnungen;
      vertraege = vertraege.sort((a, b) => {
        if (a.zeitBisVerlaengerung > b.zeitBisVerlaengerung) {
          return 1;
        }
        if (a.zeitBisVerlaengerung < b.zeitBisVerlaengerung) {
          return -1;
        }
        return 0;
      });
      this.aktiveVertraege = vertraege;
      // Eigene Ausgaben
      this.summeEigeneAusgabenBrutto = 0;
      this.summeEigeneAusgabenNetto = 0;
      this.prop_kosten.filter((o) => {
        if (
          o.datum >= `${this.jahr}-01-01`
          && o.datum <= `${this.jahr}-12-31`
          && projektkosten.filter((ob) => ob.id === o.id).length <= 0
          && (
            this.filterKostenstellen.length <= 0
            || this.filterKostenstellen.includes(o.kostenstelle)
          )
        ) {
          return o;
        }
        return null;
      }).forEach((k) => {
        this.summeEigeneAusgabenBrutto += parseFloat(k.betrag, 10);
        this.summeEigeneAusgabenNetto += parseFloat(k.betrag, 10) / (1 + k.mwst / 100);
      });
      // --
    },
    async trySaveNeuDokument(dokumentart) {
      let { jahr } = this;
      if (dokumentart === 'EueR') {
        jahr = this.eUeRJahr;
      }
      this.$store.commit('main/switchIsSaving', true);
      this.loading_createDokumentEueR = true;
      const neuDokument = {
        dokumentart,
        jahr,
        filterKostenstellen: localStorage.getItem('f400-410-filterKst'),
      };
      console.log(neuDokument);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/400/410/neuDokument/`,
        type: 'post',
        data: {
          code: this.$store.state.login.session.code,
          dokument: JSON.stringify(neuDokument),
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else if (dokumentart === 'EueR') {
            // console.log(response);
            // this.tryDownloadDokument(`f400-410/EueR/${this.jahr}.pdf`, `${this.jahr}.pdf`);
            this.tryDownloadDokument(`f400-410/EueR/${this.jahr}.zip`, `${this.jahr}.zip`);
          }
        },
        error: (err) => {
          console.log(err);
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Die Einstellungen konnten nicht gespeichert werden.
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
          this.$store.commit('main/switchIsSaving', false);
          this.loading_createDokumentEueR = false;
        },
        complete: () => {
        },
      });
    },
    async tryDownloadDokument(path, name) {
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/data/download.php`,
        method: 'POST',
        data: {
          code: this.$store.state.login.session.code,
          file: path,
        },
        xhrFields: {
          responseType: 'blob',
        },
        success: (data) => {
          const a = document.createElement('a');
          const url = window.URL.createObjectURL(data);
          a.href = url;
          a.download = name;
          document.body.append(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        },
        error: () => {
          this.$store.commit('main/alert', {
            typ: 'error',
            text: 'Die angeforderte Datei konnte nicht heruntergeladen werden. Bitte die Netzwerkverbindung prüfen.',
          }, { root: true });
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
          this.loading_createDokumentEueR = false;
        },
      });
    },
  },
  created() {
  },
};
</script>
