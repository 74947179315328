<template>
    <div>
      <v-tabs
        v-model="tabs_tab"
        show-arrows hide-slider
        background-color="secondary"
        next-icon="mdi-arrow-right-bold-box-outline"
        prev-icon="mdi-arrow-left-bold-box-outline"
        style="position:fixed;z-index:10;transform:translateY(-2px);"
        class="d-none d-lg-block"
      >
        <v-tabs-slider></v-tabs-slider>
        <v-tab
          v-for="tab in tabs"
          :key="tab.title"
          :to="tab.route"
          :class="{'primary--text': tab.route === tabs_tab && !$vuetify.theme.isDark}"
        >
          <v-icon small left>{{ tab.icon }}</v-icon>
          <span>{{ tab.title }}</span>
        </v-tab>
      </v-tabs>
      <div style="height:50px;" class="d-none d-lg-block"></div>
    </div>
</template>

<script>

export default {
  props: {
    tabs: {
      type: Array,
      default() { return []; },
    },
  },
  data: () => ({
    tabs_tab: null,
  }),
  created() {
    // this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
  },
};
</script>
