<template>
  <div class="view view_Login"
    style="padding:0px;background-size:cover;background-position:50% 50%;"
    ref="login_bg"
  >
    <v-container class="d-flex flex-row align-center justify-center"
      style="height:100vh;"
    >
      <div style="margin: auto;">
        <v-row>
          <v-col align="center">
            <div ref="login_welcome"></div>
          </v-col>
        </v-row>
        <v-row>
          <v-col align="center">
            <v-card class="pa-8" style="max-width:400px;">
              <div ref="login_welcome" class="mb-12"></div>
              <v-form>
                <v-text-field
                  label="Nutzername"
                  v-model="nn"
                  @keyup.enter="submit();"
                ></v-text-field>
                <v-text-field
                  type="password"
                  label="Passwort"
                  v-model="pw"
                  @keyup.enter="submit();"
                ></v-text-field>
                <v-btn
                block
                :loading="$store.state.login.loginLoading"
                @click="submit();"
                >
                  Einloggen
                </v-btn>
              </v-form>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="pt-4">
          <v-col style="width:800px;max-width:100vw;">
            <v-expansion-panels v-model="expansionPanel01">
              <v-expansion-panel v-for="info, key in infos" :key="key">
                <v-expansion-panel-header>
                  <b>{{ info.titel }}</b>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <div v-html="info.text"></div>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  name: 'Login',
  data: () => ({
    expansionPanel01: 0,
    nn: '',
    pw: '',
    infos: [
      /* {
        titel: 'Allgemeine Nutzungsbedingungen',
        text: `Mit der Nutzung des angebotenen Systems erklären Sie sich mit den geltenden
        <a href="https://webulous-echo.com/datenschutz.php" target="_blank">Daten­schutz­bestim­mungen</a>
        einverstanden.<br />
        <div align="right"><a href="https://webulous-echo.com/impressum.php" target="_blank">Impressum</a></div>`,
      },
      {
        titel: 'Probleme beim Einloggen?',
        text: `Wenn Sie noch über keine Login­daten verfügen,
        sprechen Sie bitte den zuständigen Adminis­trator zur Vergabe an.<br />
        <br />
        Bei Login­problemen kontaktieren Sie uns bitte über:<br />
        <a href="mailto:support@ogsmyadmin.com">support@ogsmyadmin.com</a>`,
      }, */
    ],
  }),
  components: {
  },
  methods: {
    toDataURL(url, callback) {
      const xhr = new XMLHttpRequest();
      xhr.onload = () => {
        const reader = new FileReader();
        reader.onloadend = () => {
          callback(reader.result);
        };
        reader.readAsDataURL(xhr.response);
      };
      xhr.open('GET', url);
      xhr.responseType = 'blob';
      xhr.send();
    },
    tryGetLoginscreen() {
      if (localStorage.getItem('flogin_loginscreen')) {
        this.drawLoginscreen(JSON.parse(localStorage.getItem('flogin_loginscreen')));
      }
      this.$store.commit('main/switchIsSaving', true);
      $.ajax({
        url: `${process.env.VUE_APP_API_HOST}/login/get.php?loginscreen`,
        type: 'post',
        data: {
        },
        success: (response) => {
          if (response.status === 'accessError') {
            this.$store.commit('login/backendErrorSession');
          } else {
            const help = JSON.parse(JSON.stringify(response.data));
            // create base64:
            /* const canvas = document.createElement('canvas');
            const img = new Image();
            img.src = help.loginscreen_bg_img;
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext('2d');
            ctx.drawImage(img, 0, 0);
            const dataURL = canvas.toDataURL('image/png');
            dataURL = dataURL.replace(/^data:image\/(png|jpg);base64,/, ''); */
            this.toDataURL(help.loginscreen_bg_img, (dataUrl) => {
              // console.log('RESULT:', dataUrl);
              help.loginscreen_bg_img = dataUrl;
              localStorage.setItem('flogin_loginscreen', JSON.stringify(help));
              // this.drawLoginscreen(help);
              this.drawLoginscreen(JSON.parse(localStorage.getItem('flogin_loginscreen')));
            });
          }
        },
        error: (err) => {
          console.log(err);
          // Einstellungen konnten nicht geladen werden
          this.$store.commit('main/alert', {
            typ: 'error',
            text: `Netzwerkfehler!
            Bitte die Netzwerkverbindung prüfen.`,
          }, { root: true });
          if (localStorage.getItem('flogin_loginscreen')) {
            this.drawLoginscreen(JSON.parse(localStorage.getItem('flogin_loginscreen')));
          }
        },
        complete: () => {
          this.$store.commit('main/switchIsSaving', false);
        },
      });
    },
    drawLoginscreen(data) {
      $(this.$refs.login_bg).css('background-color', data.loginscreen_bg_color);
      // console.log(data.loginscreen_bg_img);
      $(this.$refs.login_bg).css('background-image', `url('${data.loginscreen_bg_img}')`);
      $(this.$refs.login_welcome).html(data.loginscreen_welcomeText);
      this.infos = [];
      const infos = data.loginscreen_infos;
      if (infos.length > 0) {
        infos.forEach((i) => {
          this.infos.push(i);
        });
      }
    },
    submit() {
      this.$store.dispatch('login/tryLogin', {
        session: { code: null, user: { nn: this.nn, pw: this.pw } },
        router: this.$router,
      });
    },
  },
  created() {
    this.tryGetLoginscreen();
    let currentPos;

    // Parallax BG:
    setTimeout(() => {
      $(this.$refs.login_bg).scroll(() => {
        currentPos = $(this.$refs.login_bg).css('background-position').split(' ');
        currentPos = parseInt(currentPos[0].split('%').join(''), 10) + $(this.$refs.login_bg).scrollTop() / 30;
        $(this.$refs.login_bg).css('background-position', `50% ${currentPos}%`);
      });
    }, 1);
  },
};
</script>

<style>
  .view_Login {
    position:fixed;
    top:0px;left:0px;
  }
</style>
