<template>
    <div>
      <v-container>
        <v-row justify="center" class="my-0">
          <v-form
            v-model="valid"
            style="width:100%;"
          >
            <v-expansion-panels
            v-model="expansionPanelStatus"
            accordion
            >
              <v-expansion-panel
              v-for="(grp, idx) in $store.state.main.save.nutzergruppen"
              :key="idx"
              class="grey lighten-5"
              >
                <v-expansion-panel-header>
                  <v-icon left style="flex:none;">{{ grp.icon }}</v-icon>
                  {{ grp.name }}
                </v-expansion-panel-header>
                <v-expansion-panel-content class="white">
                  <Edit :gruppe="grp" @grpsChanged="grpsChanged = true" />
                  <v-row class="my-0" justify="center">
                    <v-dialog
                      v-model="dialog_delGruppe"
                      width="500"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                        small
                        color="error"
                        v-bind="attrs"
                        v-on="on"
                        >
                          <v-icon left>mdi-delete</v-icon>
                          "{{ grp.name }}" Löschen
                        </v-btn>
                      </template>

                      <v-card>
                        <v-card-title class="text-h5 grey lighten-2">
                          Sicher?
                        </v-card-title>

                        <v-card-text class="pt-4">
                          Es werden sämtliche mit dieser Gruppe zusammenhängende Daten
                          und Informationen gelöscht.
                        </v-card-text>

                        <v-divider></v-divider>

                        <v-card-actions>
                          <v-btn
                            color="primary"
                            text
                            @click="dialog_delGruppe = false;"
                          >
                            Nichts tun
                          </v-btn>
                          <v-spacer></v-spacer>
                          <v-btn
                            color="primary"
                            text
                            @click="
                              $store.commit('main/nutzergruppen_delNutzergrp', { idx });
                              grpsChanged = true;
                              dialog_delGruppe = false;
                              expansionPanelStatus = null;
                              "
                          >
                            Ja, löschen
                          </v-btn>
                        </v-card-actions>
                      </v-card>
                    </v-dialog>
                  </v-row>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </v-form>
        </v-row>
        <v-row justify="center" class="mb-2">
          <v-btn
          small
          @click="
            $store.commit('main/nutzergruppen_addNutzergruppe');
            grpsChanged = true;
            expansionPanelStatus = $store.state.main.save.nutzergruppen.length - 1;
          "
          >
            <v-icon
            left
            >mdi-plus-box</v-icon>
            Gruppe
          </v-btn>
          <v-btn
          small
          color="success"
          class="ml-2"
          :loading="isSaving"
          v-if="grpsChanged && valid"
          @click="
            $store.dispatch('main/trySaveSettings', { query: 'Nutzergruppen' });
            expansionPanelStatus = null;
          "
          >
            <v-icon
            left
            >mdi-content-save</v-icon>
            Änderungen Speichern
          </v-btn>
        </v-row>
      </v-container>
    </div>
</template>

<script>
import Edit from '@/components/Funktionen/Benutzerverwaltung/Nutzergruppen/Edit.vue';

export default {
  components: {
    Edit,
  },
  props: {
  },
  data: () => ({
    grpsChanged: false,
    valid: true,
    dialog_delGruppe: false,
    expansionPanelStatus: null,
  }),
  computed: {
    isSaving: { // Databinding mit vuex state
      get() {
        return this.$store.state.main.isSaving;
      },
      set(value) {
        this.$store.commit('main/switchIsSaving', value);
      },
    },
  },
  watch: {
    isSaving(neu) {
      if (neu === false) {
        this.grpsChanged = false;
      }
    },
    grpsChanged(neu) {
      this.$store.commit('main/saveIsChanged', neu);
    },
  },
};
</script>
